import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import TandFLogo from 'assets/images/tandf-logo.svg';
import { useAppDispatch } from 'domain/store';
import { signOutAction } from 'domain/auth/actions';
import AccessControlHide from 'components/base/AccessControlHide';

const mainNavigation = [
  {
    label: 'Dashboard',
    icon: 'icon1',
    link: '/',
    allowedPermissions: [],
    end: true,
  },
  {
    label: 'Admins',
    icon: 'icon1',
    link: '/admins',
    allowedPermissions: ['read:admins'],
  },
  {
    label: 'Demo',
    icon: 'icon1',
    link: '/demo',
    allowedPermissions: ['read:demo'],
  },
  {
    label: 'Invoices',
    icon: 'icon1',
    link: '/invoices',
    allowedPermissions: ['read:invoices'],
  },
  {
    label: 'Companies',
    icon: 'icon1',
    link: '/companies',
    allowedPermissions: ['read:companies'],
  },
  {
    label: 'Jobs',
    icon: 'icon1',
    link: '/jobs',
    allowedPermissions: ['read:jobs'],
  },
  {
    label: 'Talents',
    icon: 'icon1',
    link: '/talents',
    allowedPermissions: ['read:talents'],
  },
  {
    label: 'Talent Pool',
    icon: 'icon1',
    link: '/talent-pools',
    allowedPermissions: ['read:talents'],
  },
  {
    label: 'Benefits',
    icon: 'icon1',
    link: '/benefits',
    allowedPermissions: ['read:benefits'],
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const secNavigation = [
    {
      label: 'Settings',
      icon: 'icon1',
      link: '/settings',
    },

    {
      label: 'Logout',
      onClick: () => {
        dispatch(signOutAction())
          .unwrap()
          .then(() => {
            navigate('/login');
          });
      },
    },
  ];
  return (
    <Container>
      <EachSection>
        <LogoContainer onClick={() => navigate('/')}>
          <Image src={TandFLogo} />
        </LogoContainer>
        {mainNavigation.map((eachNav) => (
          <AccessControlHide key={eachNav.label} allowedPermissions={eachNav.allowedPermissions}>
            <NavigationLink to={eachNav.link} end={eachNav.end}>
              {eachNav.label}
            </NavigationLink>
          </AccessControlHide>
        ))}
      </EachSection>
      <EachSection>
        {secNavigation.map((eachNav) => (
          <div key={eachNav.label}>
            {eachNav.link && <NavigationLink to={eachNav.link}>{eachNav.label}</NavigationLink>}
            {eachNav.onClick && <NavigationButton onClick={eachNav.onClick}>{eachNav.label}</NavigationButton>}
          </div>
        ))}
      </EachSection>
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  width: 250px;
  background-color: ${theme.colors.mintGreen};
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EachSection = styled.div``;

const NavigationLink = styled(NavLink)`
  display: block;
  padding: 0.75rem 1rem;
  color: ${theme.colors.secondary};
  text-decoration: none;
  font-size: 1rem;
  &.active {
    font-weight: 500;
    background-color: #90f548;
  }
`;

const LogoContainer = styled.div`
  padding-left: 1rem;
  margin: 1rem 0rem;
  cursor: pointer;
`;

const Image = styled.img``;

const NavigationButton = styled.div`
  display: block;
  padding: 0.75rem 1rem;
  color: ${theme.colors.secondary};
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;

  &.active {
    font-weight: 500;
    background-color: #90f548;
  }
`;

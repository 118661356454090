import styled from 'styled-components';

const Title1 = styled.h1`
  font-size: 2.5rem;
  margin: 0;
`;

const Title2 = styled.h2`
  font-size: 2rem;
  margin: 0;
`;

const Title3 = styled.h3`
  font-size: 1.75rem;
  margin: 0;
`;

const Text = styled.p`
  font-size: 1rem;
  color: #222222;
`;

const SecText = styled.span`
  font-size: 0.85rem;
  color: #777;
`;

export { Title1, Title2, Title3, Text, SecText };

import React from 'react';
import { useAppDispatch } from 'domain/store';
import { createTalentCommentAction } from 'domain/talents/actions';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { TalentCommentPayload, TalentType } from 'types/talent';
import { Avatar } from '@mui/material';
import { formatDate } from 'helpers/date';
import { FlexBox, SubmitButton } from 'components/base';
import { theme } from 'theme';

interface OwnProps {
  talent: TalentType;
  refetch?: () => void;
}

const TalentComments: React.FC<OwnProps> = ({ talent, refetch }) => {
  const dispatch = useAppDispatch();
  const { comments } = talent;

  const commentForm = useForm<TalentCommentPayload>();

  const onFormSubmit = async (data: TalentCommentPayload) => {
    const action = createTalentCommentAction({ params: { id: talent.id }, ...data });
    dispatch(action)
      .unwrap()
      .then(() => {
        toast.success('comment created');
        refetch?.();
      })
      .catch(() => toast.error('failed to create comment'));
  };

  return (
    <Container>
      {comments?.map((comment, index) => (
        <EachComment key={index}>
          <CommentMeta gap={'.5rem'} align={'center'}>
            <Avatar src={comment.admin.avatar?.url} />
            <MetaDetails direction="column" gap={'.2rem'}>
              <h4>{comment.admin ? `${comment.admin.fullName}` : 'Talents & Friends'}</h4>
              <div>
                <span>{formatDate(comment.createdAt, 'DD MMM, HH:mm')}</span>
              </div>
            </MetaDetails>
          </CommentMeta>
          {comment.text}
        </EachComment>
      ))}
      <FormProvider {...commentForm}>
        <Form onSubmit={commentForm.handleSubmit(onFormSubmit)}>
          <TextArea required placeholder="Enter your comment" {...commentForm.register('text')}></TextArea>
          <ButtonContainer align="center" gap={'1rem'}>
            <SubmitButton>Save Comment</SubmitButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default TalentComments;

const Container = styled.div`
  padding: 1rem;
`;

const EachComment = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${theme.colors.stroke};
  color: ${theme.colors.dark};
  font-size: 1.125rem;

  &.internal {
    background-color: ${theme.colors.lightYellow};
  }
`;

const Form = styled.form`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonContainer = styled(FlexBox)`
  margin-top: 0.5rem;
`;

const TextArea = styled.textarea`
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.colors.stroke};
  background-color: ${theme.colors.lightGray};
  min-height: 100px;

  &:focus,
  &:active {
    border-radius: 30px;
    outline: none;
  }
`;

const CommentMeta = styled(FlexBox)`
  color: ${theme.colors.dark};
  margin-bottom: 0.5rem;
`;

const MetaDetails = styled(FlexBox)`
  h4 {
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
  }
  > div {
    font-size: 0.75rem;
  }
`;

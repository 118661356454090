import { uploadFile } from 'helpers/uploadFile';
import { theme } from 'theme';
import { FileType } from 'types/common';
import { CompanyPayload, CompanyType } from 'types/company';

export interface MediaFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadMedia = async (logoObj?: MediaFileObj, defaultMedia?: FileType | null) => {
  const { fileObj } = logoObj || {};
  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, logoObj?.name);
        const response = await uploadFile(formData);

        const resolveValue: FileType = response as FileType;

        resolve(resolveValue);
      })
    : defaultMedia;
};

export const uploadGalleryImages = async (jobIntroVideos: MediaFileObj[]) => {
  //
  return await Promise.all(
    (jobIntroVideos || []).map(async ({ fileObj }) => {
      return fileObj
        ? new Promise<FileType>(async (resolve) => {
            const formData = new FormData();
            formData.append('file', fileObj, fileObj.name);
            const response = (await uploadFile(formData)) as FileType;

            resolve(response);
          })
        : undefined;
    }),
  );
};

export const industriesOptions = [
  'Aerospace industry',
  'Agricultural industry',
  'Automotive industry',
  'Basic metal industry',
  'Chemical industry',
  'Computer industry',
  'Construction industry',
  'Creative industry',
  'Cultural industry',
  'Defense industry',
  'Education industry',
  'Electric power industry',
  'Electronics industry',
  'Energy industry',
  'Engineering industry',
  'Entertainment industry',
  'Farming industry',
  'Fashion industry',
  'Film industry',
  'Financial services industry',
  'Fishing industry',
  'Food industry',
  'Forestry industry',
  'Gambling industry',
  'Gas industry',
  'Green industry',
  'Health services industry ',
  'Hospitality industry',
  'Hotels industry',
  'Industrial robot industry',
  'Information industry',
  'Information technology industry',
  'Infrastructure industry',
  'Insurance industry ',
  'Leisure industry',
  'Low technology industry',
  'Manufacturing industry',
  'Meat industry',
  'Media industry',
  'Merchandising industry',
  'Mining industry ',
  'Music industry',
  'News media industry ',
  'Oil and gas industry',
  'Pharmaceutical industry',
  'Professional industry',
  'Publishing industry',
  'Pulp and paper industry',
  'Railway industry ',
  'Real estate industry ',
  'Retail industry ',
  'Scientific industry',
  'Services industry',
  'Sex industry',
  'Software industry',
  'Space industry',
  'Sport industry',
  'Steel industry',
  'Technology industry',
  'Telecommunications industry',
  'Textile industry',
  'Tobacco industry',
  'Transport industry',
  'Utility industry ',
  'Video game industry',
  'Water industry',
  'Wholesale industry',
  'Wood industry',
].map((industry) => ({ label: industry, value: industry }));

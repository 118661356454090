import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { FilterFormValues, SortConfigType } from 'types/common';

const uSortConfigs = {
  messageSendAtAsc: {
    field: 'sendAt',
    order: 'ASC',
  },
  messageSendAtDesc: {
    field: 'sendAt',
    order: 'DESC',
  },
  messageNameAsc: {
    field: 'name',
    order: 'ASC',
  },
  messageNameDesc: {
    field: 'name',
    order: 'DESC',
  },
  messageEmailAsc: {
    field: 'email',
    order: 'ASC',
  },
  messageEmailDesc: {
    field: 'email',
    order: 'DESC',
  },
  messageStatusAsc: {
    field: 'status',
    order: 'ASC',
  },
  messageStatusDesc: {
    field: 'status',
    order: 'DESC',
  },
  messageReviewedAtAsc: {
    field: 'reviewedAt',
    order: 'ASC',
  },
  messageReviewedAtDesc: {
    field: 'reviewedAt',
    order: 'DESC',
  },
};

export const MessagesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type MessageFiltersFormValues = FilterFormValues<keyof typeof MessagesSortConfigs>;

export const MessageSortWithLabels = () => {
  const { formatMessage } = useIntl();

  return [
    {
      label: formatMessage({ id: IntlKeys.messagesSendAtAsc }),
      value: 'messageSendAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesSendAtDesc }),
      value: 'messageSendAtDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesNameAsc }),
      value: 'messageNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesNameDesc }),
      value: 'messageNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesEmailAsc }),
      value: 'messageEmailAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesEmailDesc }),
      value: 'messageEmailDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesStatusAsc }),
      value: 'messageStatusAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesSendAtDesc }),
      value: 'messageStatusDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesReviewedAtAsc }),
      value: 'messageReviewedAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesReviewedAtDesc }),
      value: 'messageReviewedAtDesc',
    },
  ];
};

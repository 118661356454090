import React, { forwardRef } from 'react';
// components
import Webcam, { WebcamProps } from 'react-webcam';
// types
import { RecordingConstraints } from './useVideoRecording';
// styles
import styled from 'styled-components';
import { theme } from 'theme';

type OwnProps = Partial<WebcamProps> & {
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  constraints: RecordingConstraints;
  width?: string | number;
  height?: string | number;
};

const VideoRecording = forwardRef<Webcam, OwnProps>(
  ({ isVideoEnabled, isAudioEnabled, constraints, width = '606px', height = '516px', ...webcamProps }, ref) => {
    return (
      <>
        {isVideoEnabled ? (
          <Webcam
            audio={isAudioEnabled}
            muted={true}
            mirrored={true}
            ref={ref}
            height={height}
            width={width}
            videoConstraints={constraints.video}
            audioConstraints={constraints.audio}
            {...webcamProps}
          />
        ) : (
          <DisabledVideo />
        )}
      </>
    );
  },
);

const DisabledVideo = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.primary};
`;

export default VideoRecording;

import { uploadFile } from 'helpers/uploadFile';
import { FileType } from 'types/common';

export interface MediaFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadMedia = async (logoObj?: MediaFileObj, defaultMedia?: FileType | null) => {
  const { fileObj } = logoObj || {};
  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, logoObj?.name);
        const response = await uploadFile(formData);

        const resolveValue: FileType = response as FileType;

        resolve(resolveValue);
      })
    : defaultMedia;
};

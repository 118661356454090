import { PaginationType } from 'helpers/http';
import { AdminType } from 'types/admins';
import { FileType } from 'types/common';
import { JobType } from 'types/job';
import { UserType } from 'types/user';

export type TalentType = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email?: string;
  phone?: string;
  position?: string;
  timezone: string;
  companyName?: string;
  avatar?: FileType | null;
  comments?: TalentCommentType[];
  status: TalentStatusEnum;
  isContacted?: boolean | null;
  createdAt: string;
  updatedAt: string;
  socials: Record<string, string>;
};

export interface TalentList extends PaginationType {
  data: TalentType[];
}

export type TalentPoolType = {
  name: string;
  candidateCount: number;
  createdAt: string;
  updatedAt: string;
  id: number;
  author: UserType;
};

export interface TalentPoolPayload {
  name: string;
}
export type TalentCommentType = {
  text: string;
  admin: AdminType;
  job: JobType;
  createdAt: string;
  updatedAt: string;
};
export interface TalentCommentPayload {
  text: string;
}

export interface TalentStatusPayload {
  status: TalentStatusEnum;
}

export enum TalentStatusEnum {
  Applicant = 'applicant',
  Applied = 'applied',
  Contacted = 'contacted',
  Interested = 'interested',
  Lead = 'lead',
  NotInterested = 'not_interested',
  Working = 'working',
}

export interface TalentPoolList extends PaginationType {
  data: TalentPoolType[];
}

export interface ConnectTalentPoolsJobsPayload {
  poolIds?: number[];
  candidateIds?: number[];
  jobIds?: number[];
}

export interface ConnectSinglePoolPayload {
  candidateIds?: number[];
  jobIds?: number[];
}

export interface TalentPayload {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  position?: string;
  avatar?: FileType | null;
  socials: Record<string, string>;
}

import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import BillTo from './BillTo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';
import { InvoiceType } from 'types/invoices';
import InvoiceNumber from './InvoiceNumber';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
});
interface OwnProps {
  invoice: InvoiceType;
}

const Invoice: React.FC<OwnProps> = ({ invoice }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle title="Rechnung" />
        <BillTo invoice={invoice} />
        <InvoiceNumber invoice={invoice} />
        <InvoiceItemsTable invoice={invoice} />
        <InvoiceThankYouMsg />
      </Page>
      {invoice.cancelInvoice && (
        <Page size="A4" style={styles.page}>
          <InvoiceTitle title="Storno" />
          <BillTo invoice={invoice} />
          <InvoiceNumber invoice={invoice} isStorno />
          <InvoiceItemsTable invoice={invoice} />
          <InvoiceThankYouMsg isStorno />
        </Page>
      )}
    </Document>
  );
};

export default Invoice;

import * as React from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
//
import Sidebar from 'components/Sidebar';

interface OwnProps {}

const WithSidebar = () => {
  return (
    <Container>
      <Sidebar></Sidebar>
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default WithSidebar;

const Container = styled.section`
  display: flex;
  width: 100vw;
`;

const Content = styled.div`
  flex: 1;
  max-height: 100vh;
  overflow: auto;
`;

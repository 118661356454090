import { Checkbox } from '@mui/material';
import { ConfirmDialog, FlexBox, Text } from 'components/base';
import { Wrapper } from 'components/Layout';
import { getCompanyDetailsAction } from 'domain/companies/actions';
import { companiesResourceSelector } from 'domain/companies/selectors';
import { creditResourceSelector } from 'domain/credits/selectors';
import { updateJobDetailsAction, updateJobStatusAction } from 'domain/jobs/actions';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { useAppDispatch } from 'domain/store';
import { formatCurrency } from 'helpers/number';
import useJobCreation from 'hooks/useJobCreation';
import { IntlKeys } from 'localization';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FieldValues, FormProvider, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { ChargeCreditPayload, CreditTransactionReason } from 'types/credits';
import { JobPublishPayload } from 'types/job';
import FormFooter from '../FormFooter';
import CalculationSection from './CalculationSection';
import Summary from './Summary';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { chargeCreditsAction } from 'domain/credits/actions';

const Step4 = () => {
  const jobDetails = useSelector(jobsResourceSelector);
  const companyDetails = useSelector(companiesResourceSelector);
  const { form } = useJobCreation({
    step: 'summary',
    defaultValues: { maxCandidates: jobDetails?.maxCandidates || 15, isPriority: jobDetails?.priority } || {},
  });
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const maxCandidates = useWatch({ name: 'maxCandidates', control: form.control });
  const isPriority = useWatch({ name: 'isPriority', control: form.control });
  const credits = useSelector(creditResourceSelector);
  const [creditsNeeded, setCreditsNeeded] = useState({ total: maxCandidates || 0, toBePurchased: 0 });
  const [isPurchaseMoreCreditsVisible, setIsPurchaseMoreCreditsVisible] = useState(false);
  const [selectedBox, setSelectedBox] = useState(0);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  useEffect(() => {
    if (jobDetails?.companyId) {
      dispatch(getCompanyDetailsAction({ params: { id: jobDetails.companyId } }));
    }
  }, [dispatch, jobDetails]);

  useEffect(() => {
    if (jobDetails) {
      form.reset({ maxCandidates: jobDetails?.maxCandidates || 15, isPriority: jobDetails?.priority });
    }
  }, [form, jobDetails]);

  useEffect(() => {
    if (jobDetails?.totalFactor && credits) {
      setCreditsNeeded({
        total: maxCandidates * jobDetails.totalFactor * (isPriority ? 2 : 1),
        toBePurchased: Math.ceil(
          maxCandidates * jobDetails.totalFactor * (isPriority ? 2 : 1) - Number(credits?.currentAmount),
        ),
      });
    }
  }, [maxCandidates, jobDetails?.totalFactor, credits, isPriority]);

  const handleUpdateJobStatus = (data: FieldValues) => {
    if (data.status === 'draft') {
      dispatch(
        updateJobDetailsAction({
          params: { id: jobId! },
          ...(data as JobPublishPayload),
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('Successfully updated the job');
          navigate('/jobs?status=draft');
          setIsPurchaseMoreCreditsVisible(false);
        })
        .catch(() => {
          toast.error('Failed to update the job');
        });
    } else {
      dispatch(
        updateJobStatusAction({
          params: { id: jobId! },
          ...(data as JobPublishPayload),
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('Successfully published the job');
          navigate('/jobs?status=published');
          setIsPurchaseMoreCreditsVisible(false);
        })
        .catch(() => {
          toast.error('Failed to publish the job');
        });
    }
  };

  const onSubmit = useCallback(
    (data: FieldValues) => {
      if (maxCandidates < 5) {
        toast.error(formatMessage({ id: 'IntlKeys.minimumCandidatesRequired' }));
        return;
      }

      if (creditsNeeded.toBePurchased > 0) {
        setIsPurchaseMoreCreditsVisible(true);
        return;
      }

      handleUpdateJobStatus(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxCandidates, creditsNeeded.toBePurchased, formatMessage],
  );

  const showPreview = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      window.open(`${process.env.REACT_APP_CLIENT_PUBLIC_URL}jobs/${jobId}/apply`, '_blank');
    },
    [jobId],
  );

  const creditBoxes = [
    creditsNeeded.toBePurchased,
    creditsNeeded.toBePurchased - (creditsNeeded.toBePurchased % 10) + 20,
    creditsNeeded.toBePurchased - (creditsNeeded.toBePurchased % 10) + 50,
  ];

  const purchaseAndConfirm = () => {
    if (!isTermsAccepted) {
      setShowTermsError(true);
      return;
    }
    if (!jobDetails?.companyId) return;
    const payload: ChargeCreditPayload = {
      companyId: jobDetails?.companyId as number,
      amount: creditBoxes[selectedBox],
      reason: CreditTransactionReason.PURCHASE_CREDITS,
      comment: '',
    };
    dispatch(chargeCreditsAction(payload))
      .unwrap()
      .then(() => {
        const payload = {
          maxCandidates: maxCandidates,
          status: 'published',
        };
        handleUpdateJobStatus(payload);
      });
  };

  const seelctPurchaseCreditBox = (index: number) => {
    setSelectedBox(index);
  };

  const acceptOrRejectTerms = () => {
    setIsTermsAccepted((isAccepted) => !isAccepted);
  };

  const statusOptions = [
    {
      label: 'Save as Draft',
      value: 'draft',
    },
    {
      label: 'Publish Job',
      value: 'published',
    },
  ];

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <FormContainer>
            <Summary job={jobDetails} />
            <CalculationSection
              maxCandidates={maxCandidates}
              job={jobDetails}
              creditPerCandidate={{
                rareFactor: jobDetails?.rareFactor || 0,
                seniorityFactor: jobDetails?.seniorityFactor || 0,
                priorityFactor: isPriority ? 1 : 0,
              }}
              creditsNeeded={creditsNeeded}
            />
            <FormAutoComplete
              freeSolo
              name="status"
              placeholder={'Select status'}
              options={statusOptions}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </FormContainer>
          <FormFooter showPreview={showPreview} />
        </Form>
      </FormProvider>
      <ConfirmDialog
        isOpen={isPurchaseMoreCreditsVisible}
        onConfirm={purchaseAndConfirm}
        onCancel={() => {
          setIsPurchaseMoreCreditsVisible(false);
        }}
        title="One click away to start recruiting!"
        description={`You currently have only ${credits?.currentAmount} credits. You may need ${creditsNeeded.toBePurchased} more to publish the job`}
      >
        <ConfirmModal>
          <Text>
            Please select a credit basket from the following and we will send you and invoice. Recruitement will start
            ASAP and you can make the payment within next 7 days.
          </Text>
          <CreditBoxes>
            {creditBoxes.map((credit, index) => (
              <EachBox key={credit} onClick={() => seelctPurchaseCreditBox(index)} isSelected={selectedBox === index}>
                <EachRow>
                  <Label>Credits:</Label> <Value>{credit}</Value>
                </EachRow>
                <EachRow>
                  <Label>Amount per credit:</Label> <Value>{formatCurrency(499)}</Value>
                </EachRow>
                <EachRow>
                  <Label>Total:</Label> <Value>{formatCurrency(credit * 499)}</Value>
                </EachRow>
              </EachBox>
            ))}
          </CreditBoxes>
          <Checkbox checked={isTermsAccepted} onChange={acceptOrRejectTerms} /> I am accepting the Terms and Conditions
          {showTermsError && !isTermsAccepted && (
            <ErrorText>Please accept the Terms and conditions to purchase the credits and start recruiting</ErrorText>
          )}
        </ConfirmModal>
      </ConfirmDialog>
    </>
  );
};

export default Step4;
const Form = styled.form`
  width: 100%;
`;

const FormContainer = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;

  > p {
    border-left: 4px solid ${theme.colors.disabledGray};
    padding-left: 0.75rem;
    font-size: 0.875rem;
    color: ${theme.colors.gray};
  }
`;

const CreditBoxes = styled(FlexBox)`
  display: flex;
  max-width: 800px;
  margin: auto;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;

const EachBox = styled.div`
  padding: 0.5rem 1rem;
  border: ${(props: { isSelected: boolean }) =>
    props.isSelected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`};
  border-radius: 10px;
  font-size: 1.25rem;
  color: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.white : theme.colors.secondary)};
  background: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.primary : 'transparent')};
  cursor: pointer;
  flex: 1;
  width: 100%;
`;

const ConfirmModal = styled.div`
  max-width: 768px;
  width: 100%;
  padding: 1rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 1rem 0rem;
  }
`;

const EachRow = styled(FlexBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const Label = styled.span`
  text-align: left;
  font-size: 0.75rem;
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 1rem;
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
`;

import { useState, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
// redux
import { signInAction, signUpAction, resetPasswordAction, forgotPasswordAction } from 'domain/auth/actions';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LoginValidationSchema,
  SignUpValidationSchema,
  ResetPasswordValidationSchema,
  ForgotPasswordValidationSchema,
} from '../pages/auth/validation';
import { useAppDispatch } from 'domain/store';
// types
import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';
import { Params } from 'helpers/http';

type JobCreationStep = 'jobDetails' | 'customization' | 'qualificationQnA' | 'summary';

type NetworkErrorType = {
  data: AxiosError;
  requestPayload: Params;
};

type OwnProps = {
  step: JobCreationStep;
  defaultValues?: FieldValues;
};

const extractFormSchema = (step: JobCreationStep) => {
  switch (step) {
    case 'jobDetails':
      return {};
    case 'customization':
      return {};
    case 'qualificationQnA':
      return ForgotPasswordValidationSchema;
    case 'summary':
      return ResetPasswordValidationSchema;
  }
};

const useJobCreation = ({ step, defaultValues }: OwnProps) => {
  const [fulfilledMessageId, setFulfilledMessageId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageId, setErrorMessageId] = useState('');
  const form = useForm({
    defaultValues,
  });
  const companyId = useWatch({ control: form.control, name: 'companyId' });
  const dispatch = useAppDispatch();

  const extractSubmitAction = useCallback(
    (step: JobCreationStep, payload: FieldValues) => {
      switch (step) {
        case 'jobDetails':
          return dispatch(signInAction(payload));
        case 'customization':
          return dispatch(signUpAction(payload));
        case 'qualificationQnA':
          return dispatch(forgotPasswordAction(payload));
        case 'summary':
          return dispatch(resetPasswordAction(payload));
      }
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    (payload: FieldValues) => {
      setIsLoading(true);
      setErrorMessageId('');
      setFulfilledMessageId('');

      extractSubmitAction(step, payload)
        .unwrap()
        .then(() => {
          setFulfilledMessageId(`${step}Fulfilled`);
          setIsLoading(false);
        })
        .catch((e: NetworkErrorType) => {
          const response = e?.data?.response;
          const errorMessageId = response ? `${step}Error${response.status}` : 'serverNotAvailableError';

          setErrorMessageId(errorMessageId);
          setIsLoading(false);
        });
    },
    [step, extractSubmitAction, history],
  );

  return { form, companyId, onSubmit, isLoading, fulfilledMessageId, errorMessageId };
};

export default useJobCreation;

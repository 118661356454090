import { Button } from '@mui/material';
import { FlexBox } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { fetchTransactionsWorker } from 'domain/credit-transactions/actions';
import { formatDate } from 'helpers/date';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { CompanyType } from 'types/company';
import { CreditTransactionType, TransactionReason } from 'types/credit-transactions';
import { CreditTransactionsFiltersFormValues, CreditTransactionsSortConfigs } from 'types/credit-transactions/entities';
import ChargeCreditModal from '../components/ChargeCreditModal';

interface OwnProps {
  company: CompanyType;
}

const CompanyCreditHistory: React.FC<OwnProps> = ({ company }) => {
  const { formatMessage } = useIntl();
  const [chargeCreditModal, setChargeCreditModal] = React.useState(false);
  const filterConfig: CreditTransactionsFiltersFormValues = {
    sort: 'createdAtDesc',
    filters: { companyId: company?.id || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    refetch,
    resources: transactions,
    fetchNextPage,
    //
    pagination,
  } = useInfiniteData({
    queryKey: 'transactionList',
    //
    fetchResources: fetchTransactionsWorker,
    initialSort: CreditTransactionsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  const columns = useMemo(() => {
    const mapReasonToText = (reason: TransactionReason) => {
      switch (reason) {
        case TransactionReason.SUBSCRIPTION:
          return formatMessage({ id: IntlKeys.creditHistoryReasonSubscription });
        case TransactionReason.PURCHASE_CREDITS:
          return formatMessage({ id: IntlKeys.creditHistoryReasonPurchaseCredits });
        case TransactionReason.CREDITS_GIFTED:
          return formatMessage({ id: IntlKeys.creditHistoryReasonGiftedCredits });
        case TransactionReason.CANDIDATE:
          return formatMessage({ id: IntlKeys.creditHistoryReasonCanditate });
        case TransactionReason.INTERVIEW:
          return formatMessage({ id: IntlKeys.creditHistoryReasonInterview });
        case TransactionReason.WITHDRAWL:
          return formatMessage({ id: IntlKeys.creditHistoryReasonWithdrawl });
        case TransactionReason.REFERENCES:
          return formatMessage({ id: IntlKeys.creditHistoryReasonReferences });
        case TransactionReason.ASSESMENT:
          return formatMessage({ id: IntlKeys.creditHistoryReasonAssessment });
        case TransactionReason.ATS:
          return formatMessage({ id: IntlKeys.creditHistoryReasonAts });
        default:
          return formatMessage({ id: IntlKeys.creditHistoryReasonPurchaseCredits });
      }
    };
    return [
      {
        Header: formatMessage({ id: IntlKeys.amount }),
        accessor: (row) => (
          <Credit isPositive={row.amount}>
            {formatMessage({ id: IntlKeys.creditsAmount }, { count: row.amount })}
          </Credit>
        ),
        id: 'amount',
      },
      {
        Header: formatMessage({ id: IntlKeys.creditHistoryReason }),
        accessor: (row) => mapReasonToText(row.reason),
        id: 'reason',
      },
      {
        Header: formatMessage({ id: IntlKeys.comment }),
        accessor: (row) => row.comment || '-',
        id: 'comment',
      },
      {
        Header: formatMessage({ id: IntlKeys.candidate }),
        accessor: (row) => (row.candidate ? `${row.candidate.firstName} ${row.candidate.lastName}` : '-'),
        id: 'candidate',
      },
      {
        Header: formatMessage({ id: 'Date' }),
        accessor: (row) => formatDate(row.createdAt),
        id: 'createdAt',
      },
    ] as InfiniteColumnsType<CreditTransactionType>;
  }, [formatMessage]) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <Container>
      <FlexBox justify="space-between" align="center">
        <h2>{formatMessage({ id: 'Credit History' })}</h2>
        <Button variant="contained" onClick={() => setChargeCreditModal(true)}>
          {formatMessage({ id: 'Charge Credit' })}
        </Button>
      </FlexBox>
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={transactions}
        columns={columns}
      />
      <ModalDialog isOpen={chargeCreditModal} handleClose={() => setChargeCreditModal(false)}>
        <ChargeCreditModal closeModal={() => setChargeCreditModal(false)} companyId={company.id} refetch={refetch} />
      </ModalDialog>
    </Container>
  );
};

export default CompanyCreditHistory;

const Container = styled.div``;

const Credit = styled.span`
  color: ${(props: { isPositive: number }) => (props.isPositive > 0 ? theme.colors.success : theme.colors.error)};
`;

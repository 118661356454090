import { Checkbox } from '@mui/material';
import Button, { SubmitButton } from 'components/base/Button';
import FormTextField from 'components/hook-form/fields/FormTextField';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { Wrapper } from 'components/Layout';
import { fetchJobsWorker } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import { connectCandidateToPoolAndJobsAction, connectPoolToCandidateAndJobsAction } from 'domain/talents/actions';

import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobStatus, JobType } from 'types/job';
import { JobFiltersFormValues, JobsSortConfigs } from 'types/job/entities';
import { ImRocket } from 'react-icons/im';
import { CompaniesSortConfigs, CompanyFiltersFormValues } from 'types/company/entities';
import { fetchCompaniesWorker } from 'domain/companies/actions';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { CompanyType } from 'types/company';
import { getUniqueListBy } from 'helpers/object';

interface OwnProps {
  candidateIds: number[];
  refetchPools: () => void;
  handleClose: () => void;
}

type CompaniesOption = {
  value: number | string;
  label: string;
};

const AttachJobsToTalents: React.FC<OwnProps> = ({ handleClose, candidateIds, refetchPools }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedRows, setCheckedRows] = useState<Array<number>>([]);
  const [isCheckedAllTalents, setIsCheckedAllTalents] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [companySelectionOptions, setCompanySelectionOptions] = useState<CompaniesOption[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<null | number>(null);
  const [companySearchValue, setCompanySearchValue] = useState('');

  const filterConfig: JobFiltersFormValues = {
    sort: 'jobCreatedAtAsc',
    filters: { search: '', companyId: '' },
  };

  const companyfilterConfig: CompanyFiltersFormValues = {
    sort: 'companyNameAsc',
    filters: { companySearch: '' },
  };

  const form = useForm({
    defaultValues: { search: filterConfig.filters.search, companySearch: companyfilterConfig.filters.companySearch },
  });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: jobs,
    fetchNextPage,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'jobsList',
    //
    fetchResources: fetchJobsWorker,
    initialSort: JobsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: JobFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(JobsSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const {
    resources: companies,
    fetchNextPage: fetchCompanyNextPage,
    //
    pagination: companyPagination,
    setSort: companySetSort,
    setFilters: companySetFilters,
  } = useInfiniteData({
    queryKey: 'companyList',
    //
    fetchResources: fetchCompaniesWorker,
    initialSort: CompaniesSortConfigs[companyfilterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCompanyFiltersChange = useCallback(
    debounce((config: CompanyFiltersFormValues) => {
      const { sort, filters } = config;
      companySetSort(CompaniesSortConfigs[sort!]);
      companySetFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange();
    }
  }, [searchValue]);

  const handleSearchChange = () => {
    filterConfig.filters.search = searchValue;

    handleFiltersChange(filterConfig);
  };

  useEffect(() => {
    if (companySearchValue?.length >= 3 || companySearchValue?.length == 0) {
      handleCompanySearchChange();
    }
  }, [companySearchValue]);

  const handleCompanySearchChange = () => {
    companyfilterConfig.filters.search = companySearchValue;

    handleCompanyFiltersChange(companyfilterConfig);
  };

  const handleCheckTalent = useCallback(
    (value: number) => {
      if (checkedRows.includes(value)) {
        setCheckedRows(checkedRows.filter((i) => i !== value));
        setIsCheckedAllTalents(false);
      } else {
        setCheckedRows([...checkedRows, value]);
        if (checkedRows.length + 1 === jobs.length) {
          setIsCheckedAllTalents(true);
        }
      }
    },
    [checkedRows, jobs.length],
  );

  const confirmAttachTalentsToJobs = useCallback(() => {
    const payLoad = {
      candidateIds,
      jobIds: checkedRows,
    };
    dispatch(connectCandidateToPoolAndJobsAction({ ...payLoad }))
      .unwrap()
      .then(() => {
        toast.success('Succeffully attached jobs to the selected pools');
        refetchPools?.();
        handleClose();
      })
      .catch((err) => {
        toast.error(err?.data?.response?.data?.message || 'Something went wrong! Please try again');
      });
    // TODO
  }, [checkedRows]);

  const convertCompanyListToOptions = useCallback((companies: CompanyType[]) => {
    const companyOptions: CompaniesOption[] = companies.map((company) => ({ value: company.id, label: company.name }));
    const isSelectedCompanyPresent = companyOptions.find((company) => company.value == selectedCompanyId);
    const filteredCompanyOptions = companyOptions.filter((company) => company.value != isSelectedCompanyPresent?.value);
    const updatedOptions = getUniqueListBy(
      [{ ...isSelectedCompanyPresent }, ...filteredCompanyOptions],
      'value',
    ) as CompaniesOption[];

    setCompanySelectionOptions(updatedOptions);
  }, []);

  useEffect(() => {
    if (companies) {
      convertCompanyListToOptions(companies);
    }
  }, [convertCompanyListToOptions, companies]);

  const handleSearchCompanyChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, value: string, reason: string) => {
      if (reason === 'input' || reason === 'clear') {
        setCompanySearchValue(value);
      }
    },
    [],
  );

  const handleCompanyIdChange = useCallback(
    (value: CompaniesOption) => {
      setSelectedCompanyId(value?.value as number);
      handleFiltersChange({
        ...filterConfig,
        filters: { ...filterConfig.filters, companyId: value?.value ? String(value?.value) : '' },
      });
    },
    [handleCompanyFiltersChange],
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <Checkbox
            style={{ marginTop: '-10px' }}
            checked={isCheckedAllTalents}
            onChange={() => {
              setIsCheckedAllTalents(!isCheckedAllTalents);
              isCheckedAllTalents ? setCheckedRows([]) : setCheckedRows(jobs.map((talent) => talent.id));
            }}
          />
        ),
        accessor: (row) => row.id,
        Cell: ({ value }) => (
          <Checkbox checked={checkedRows.includes(value)} onChange={() => handleCheckTalent(value)} />
        ),
        style: {
          maxWidth: 42,
          display: 'flex',
          alignItems: 'center',
        },
        id: 'select',
      },
      {
        Header: formatMessage({ id: IntlKeys.name }),
        accessor: (row) => row.title,
        id: 'name',
      },
      {
        Header: formatMessage({ id: 'IntlKeys.status' }),
        accessor: (row: JobType) => <Status staus={row.status}>{row.status}</Status>,
        id: 'status',
      },
      {
        Header: formatMessage({ id: 'IntlKeys.isPriority' }),
        id: 'isPriority',
        accessor: (row) => (row.priority ? <ImRocket /> : 'Normal'),
      },
      {
        Header: formatMessage({ id: 'IntlKeys.maxCandidates' }),
        accessor: (row) => row.maxCandidates,
        id: 'maxCandidates',
      },
      {
        Header: formatMessage({ id: 'IntlKeys.totalApplications' }),
        accessor: (row) => row.applicationCount,
        id: 'applicationCount',
      },
    ],
    [checkedRows, formatMessage, handleCheckTalent, jobs, isCheckedAllTalents],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <ListContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FilterContainer>
            <SearchBox>
              <FormTextField name="search" placeholder="Search for a job" />
            </SearchBox>
            <FormAutoComplete
              freeSolo
              name="companySearchValue"
              options={companySelectionOptions}
              onChange={(_, value) => handleCompanyIdChange(value as CompaniesOption)}
              loading={isLoading}
              placeholder="Search for a company!"
              onInputChange={handleSearchCompanyChange}
              // isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </FilterContainer>
        </Form>
      </FormProvider>
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={jobs as unknown as Record<string, unknown>[]}
        columns={columns}
      />
      <ActionContainer>
        <SubmitButton disabled={!checkedRows.length} onClick={confirmAttachTalentsToJobs}>
          Attach Selected jobs
        </SubmitButton>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
      </ActionContainer>
    </ListContainer>
  );
};

export default AttachJobsToTalents;

const ListContainer = styled(Wrapper)`
  max-width: 1000px;
  padding-bottom: 4.5rem;
`;

const ActionContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background-color: ${theme.colors.white};
`;

const Form = styled.form`
  width: 100%;
`;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    height: 42px;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-root {
    height: 42px !important;
  }
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

const Status = styled.div`
  background-color: ${(props: { staus: JobStatus }) =>
    props.staus === 'draft'
      ? theme.colors.warning
      : props.staus === 'published'
      ? theme.colors.success
      : theme.colors.secondary};
  margin: 0rem 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  color: white;
  display: inline-block;
  font-weight: 500;
`;

import * as React from 'react';
import styled from 'styled-components';
import { DropzoneProps, useDropzone } from 'react-dropzone';

//
import uploadIcon from 'assets/icons/cloud-add.svg';
import { FlexBox, Text } from 'components/base';
import { allMimeTypesArray } from './entities';
import { theme } from 'theme';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import ModalDialog from 'components/base/ModalDialog';
import RecordVideoModal from 'components/video-recording/RecordVideoModal';

export interface CustomDropzoneProps extends Omit<DropzoneProps, 'children'> {
  width?: string | number;
  height?: string | number;
  useRecord?: boolean;
}

const Dropzone: React.FC<CustomDropzoneProps> = ({ accept = allMimeTypesArray, useRecord, ...dropZoneProps }) => {
  const { getRootProps, getInputProps } = useDropzone({ accept, ...dropZoneProps });
  const [openRecordMediaModal, setOpenRecordMediaModal] = React.useState(false);
  const { formatMessage } = useIntl();

  const onVideoRecordSubmit = (url: Blob) => {
    const file = new File([url], 'video-file', { type: 'video/mp4' });
    console.log('file', file);

    dropZoneProps.onDrop!([file], [], new Event('record'));
  };

  return (
    <>
      <DropzoneContainer {...getRootProps()} direction="column" align="center" justify="center">
        <input {...getInputProps()} />
        <Image src={uploadIcon} />
        <Text>{formatMessage({ id: IntlKeys.dragNDrop })}</Text>

        {useRecord && (
          <RecordButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenRecordMediaModal(true);
            }}
          >
            Record
          </RecordButton>
        )}
      </DropzoneContainer>

      <ModalDialog
        isOpen={openRecordMediaModal}
        title="Record Video"
        handleClose={() => setOpenRecordMediaModal(false)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className={'record-modal'}
        classes={{ topBar: 'record-modal-head', content: 'record-modal-content' }}
      >
        <RecordVideoModal
          onRecordSubmit={onVideoRecordSubmit}
          handleClose={() => setOpenRecordMediaModal(false)}
          isOpen={openRecordMediaModal}
        />
      </ModalDialog>
    </>
  );
};

export default Dropzone;

const DropzoneContainer = styled(FlexBox)`
  padding: 2rem 1rem;
  width: ${(props: Partial<CustomDropzoneProps>) => props.width || '100%'};
  height: ${(props: Partial<CustomDropzoneProps>) => props.height || '100%'};
  background-color: ${theme.colors.lightGray};
  border-radius: 4px;

  p {
    max-width: 100%;
    overflow: hidden;
  }

  &:hover {
    border-radius: 30px;
    cursor: pointer;
  }
`;

const Image = styled.img``;

const RecordButton = styled.button``;

import ModalDialog from 'components/base/ModalDialog';
import { fetchTalentsWorker } from 'domain/talents/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { debounce } from 'lodash';
import CreateOrEditTalent from 'pages/Talents/components/CreateOrEditTalent';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { JobType } from 'types/job';
import { TalentType } from 'types/talent';
import { TalentFiltersFormValues, TalentSortConfigs } from 'types/talent/entities';
import AttachTalentsToJob from '../talents/AttachTalentsToJob';
import TalentsFilters from '../talents/TalentsFilter';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'domain/store';
import { createApplicationAction } from 'domain/applications/actions';
import { useSearchParams } from 'react-router-dom';
import TalentCard from '../talents/TalentCard';
import InfiniteList from 'components/InfiniteList';

interface OwnProps {
  job: JobType;
}

const ConnectedTalentsList: React.FC<OwnProps> = ({ job }) => {
  const [, setSearchParams] = useSearchParams();
  const { tokens } = job;
  const [isEditTalentOpen, setIsEditTalentOpen] = useState(false);
  const [currentlyEdittingTalent, setCurrentlyEdittingTalent] = useState<null | number>(null);
  const [isAttachTalentModalOpen, setIsAttachTalentModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const filterConfig: TalentFiltersFormValues = {
    sort: 'nameAsc',
    filters: { jobId: job?.id || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talents,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'talentsList',
    //
    fetchResources: fetchTalentsWorker,
    initialSort: TalentSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: TalentFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(TalentSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const closeEditTalentModal = useCallback(() => {
    setCurrentlyEdittingTalent(null);
    setIsEditTalentOpen(false);
  }, []);

  const openEditTalentModal = useCallback((talentId: number) => {
    setCurrentlyEdittingTalent(talentId);
    setIsEditTalentOpen(true);
  }, []);

  const handelAttachTalentsModelOpen = useCallback(() => {
    setIsAttachTalentModalOpen(true);
  }, []);

  const handelAttachTalentsModelClose = useCallback(() => {
    setIsAttachTalentModalOpen(false);
  }, []);

  const applyForJob = (talent: TalentType) => {
    dispatch(createApplicationAction({ ...talent, jobId: job.id })).then(() => {
      toast.success('succesfully applied');
      setSearchParams({ tab: 'applications' });
    });
  };

  return (
    <Container>
      <TalentsFilters
        config={filterConfig}
        onChange={handleFiltersChange}
        refetch={refetch}
        openAttachTalentsModal={handelAttachTalentsModelOpen}
        openCreateTalentsModal={() => setIsEditTalentOpen(true)}
      />
      <InfiniteList
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        list={talents}
        ListItem={TalentCard}
        componentProps={{
          refetchTalents: refetch,
          jobId: job.id,
          additionalInfo: tokens?.map(({ token, additionalInfo }) => {
            return { token, ...additionalInfo };
          }),
          openEditTalentModal,
          applyForJob,
        }}
      />

      <ModalDialog isOpen={isEditTalentOpen} handleClose={closeEditTalentModal}>
        <CreateOrEditTalent
          refetch={refetch}
          closeModal={closeEditTalentModal}
          talentId={currentlyEdittingTalent}
          jobId={job.id}
        />
      </ModalDialog>

      <ModalDialog isOpen={isAttachTalentModalOpen} handleClose={handelAttachTalentsModelClose}>
        <AttachTalentsToJob jobIds={[job?.id]} refetchTalents={refetch} handleClose={handelAttachTalentsModelClose} />
      </ModalDialog>
    </Container>
  );
};

export default ConnectedTalentsList;

const Container = styled.div``;

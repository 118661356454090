import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { ColorResult, SketchPicker } from 'react-color';
//
import { FlexBox, Text } from 'components/base';
//
import { theme } from 'theme';
//

import { useFieldErrors } from 'helpers/forms/extractErrorProps';

interface OwnProps {
  name: string;
}

const ColorPicker: React.FC<OwnProps> = ({ name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { control } = useFormContext();
  const fieldErrorProps = useFieldErrors(name);
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const onSelect = useCallback(
    (color: ColorResult) => {
      onChange(color.hex);
    },
    [onChange],
  );

  const handleOpenPicker = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClosePicker = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Container>
      <Trigger onClick={handleOpenPicker} gap={'.5rem'} align="center">
        <TriggerBlock color={value} />
        <Text>{value?.split('#')[1]}</Text>
      </Trigger>
      {isOpen && (
        <PopOver>
          <Overlay onClick={handleClosePicker} />
          <SketchPicker color={value} onChange={onSelect} />
        </PopOver>
      )}
    </Container>
  );
};

export default ColorPicker;

const Container = styled.div`
  position: relative;
`;

const Trigger = styled(FlexBox)`
  cursor: pointer;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.disabledGray};

  &:hover {
    border-radius: 30px;
  }

  & p {
    font-weight: 500;
    color: ${theme.colors.secondary};
  }
`;

const TriggerBlock = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  ${(props: { color: string }) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`;

import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
//
import TandFLogo from 'assets/images/tandf-logo.svg';
import { theme } from 'theme';
import { ConfirmDialog, FlexBox } from 'components/base';
import { useNavigate } from 'react-router';

interface OwnProps {
  stepsCount: number;
  currentStep?: number;
}

const HeaderWithProgress: React.FC<OwnProps> = () => {
  const [isGobackModalOpen, setIsGobackModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = useCallback(() => {
    setIsGobackModalOpen(true);
  }, [setIsGobackModalOpen]);

  const cancelAbortion = useCallback(() => {
    setIsGobackModalOpen(false);
  }, [setIsGobackModalOpen]);

  const handleAbort = useCallback(() => {
    navigate('/jobs');
  }, [navigate]);

  return (
    <Container>
      <HeaderWrapper justify="space-between">
        <LogoContainer>
          <Image src={TandFLogo} />
        </LogoContainer>
        <ExtraMenu>
          <GrClose onClick={handleClose} />
        </ExtraMenu>
      </HeaderWrapper>
      <Divider />
      <ConfirmDialog
        title="Are you sure to abort the recruiting?"
        isOpen={isGobackModalOpen}
        onConfirm={handleAbort}
        onCancel={cancelAbortion}
        confirmBtnText={'Yes, Leave'}
        cancelButtonText={'No, Stay'}
        description={'You have unsaved changes that will be lost if you leave.'}
      />
    </Container>
  );
};

export default HeaderWithProgress;

const Container = styled.section`
  background-color: ${theme.colors.mintGreen};
`;

const HeaderWrapper = styled(FlexBox)`
  height: 64px;
  padding: 0.875rem;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: auto;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.stroke};
`;

const LogoContainer = styled.div`
  width: 10rem;
  display: flex;
`;

const Image = styled.img``;

const ExtraMenu = styled.div`
  cursor: pointer;
`;

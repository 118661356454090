import ModalDialog from 'components/base/ModalDialog';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { deleteUserAction, fetchUsersWorker, updateUserRoleAction, updateUserStatusAction } from 'domain/users/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { CompanyType } from 'types/company';
import { UserRoles, UserStatusEnum, UserType } from 'types/user';
import { UsersFiltersFormValues, UsersSortConfigs } from 'types/user/entities';
import UsersFilters from '../components/UsersFilters';
import { ConfirmDialog, FlexBox } from 'components/base';
import { Button, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import CreateUserModal from '../components/CreateUserModal';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'domain/store';

interface OwnProps {
  company: CompanyType;
}

const CompanyUsers: React.FC<OwnProps> = ({ company }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [roleAnchorEl, setRoleAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const filterConfig: UsersFiltersFormValues = {
    sort: 'nameAsc',
    filters: { companyId: company?.id || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talents,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'userList',
    //
    fetchResources: fetchUsersWorker,
    initialSort: UsersSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: UsersFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(UsersSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const closeCreateUserModal = useCallback(() => {
    setNewUserModalOpen(false);
    setCurrentUserId(null);
  }, []);

  const openEditUserModal = useCallback((userId: number) => {
    setCurrentUserId(userId);
    setNewUserModalOpen(true);
  }, []);

  const handleDeleteConfirmModalOpen = useCallback((userId: number) => {
    setCurrentUserId(userId);
    setIsDeleteConfirmModalOpen(true);
  }, []);

  const handleDeleteConfirmModalClose = useCallback(() => {
    setCurrentUserId(null);
    setIsDeleteConfirmModalOpen(false);
  }, []);

  const handleDeleteUser = useCallback(() => {
    if (currentUserId) {
      setIsDeleteInProgress(true);
      dispatch(deleteUserAction({ params: { id: currentUserId } }))
        .unwrap()
        .then(() => {
          toast.success('User deleted');
          setIsDeleteConfirmModalOpen(false);
          setIsDeleteInProgress(false);
          setCurrentUserId(null);
          refetch();
        })
        .catch((err) => {
          toast.error(err.message);
          setIsDeleteConfirmModalOpen(false);
        });
    }
  }, [currentUserId, dispatch, refetch]);

  const handleOpenStatusModal = (e: React.MouseEvent<HTMLDivElement>, userId: number) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
    setCurrentUserId(userId);

    setStatusAnchorEl(e.currentTarget);
  };

  const handleCloseStatusModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
    setCurrentUserId(null);

    setStatusAnchorEl(null);
  };

  const onStatusChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, status: UserStatusEnum) => {
      if (currentUserId) {
        dispatch(updateUserStatusAction({ params: { id: currentUserId }, status }))
          .unwrap()
          .then(() => {
            refetch?.();
          });
      }
      handleCloseStatusModal(e as React.MouseEvent<HTMLDivElement>);
    },
    [currentUserId, dispatch, refetch],
  );

  const handleOpenRoleModal = (e: React.MouseEvent<HTMLDivElement>, userId: number) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
    setCurrentUserId(userId);

    setRoleAnchorEl(e.currentTarget);
  };

  const handleCloseRoleModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();
    setCurrentUserId(null);

    setRoleAnchorEl(null);
  };

  const onRoleChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, role: UserRoles) => {
      if (currentUserId) {
        dispatch(updateUserRoleAction({ params: { id: currentUserId }, role }))
          .unwrap()
          .then(() => {
            refetch?.();
          });
      }
      handleCloseRoleModal(e as React.MouseEvent<HTMLDivElement>);
    },
    [currentUserId, dispatch, refetch],
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => `${row.firstName} ${row.lastName}`,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.email }),
          accessor: (row) => row.email,
          id: 'email',
        },
        {
          Header: formatMessage({ id: 'Role' }),
          accessor: (row) => (
            <StatusLabel status={'active'} onClick={(e) => handleOpenRoleModal(e, row.id)}>
              {row.role}
            </StatusLabel>
          ),
          id: 'role',
        },
        {
          Header: formatMessage({ id: 'Status' }),
          accessor: (row) => (
            <StatusLabel status={row.status} onClick={(e) => handleOpenStatusModal(e, row.id)}>
              {row.status}
            </StatusLabel>
          ),
          id: 'status',
        },
        {
          Header: '',
          accessor: (row) => (
            <Actions>
              <MdEdit onClick={() => openEditUserModal(row.id)} />
              <MdDelete onClick={() => handleDeleteConfirmModalOpen(row.id)} />
            </Actions>
          ),
          id: 'actions',
        },
      ] as InfiniteColumnsType<UserType>,
    [formatMessage, handleDeleteConfirmModalOpen, openEditUserModal],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <Container>
      <FlexBox justify="space-between" align="center">
        <UsersFilters config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
        <Button variant="contained" onClick={() => setNewUserModalOpen(true)}>
          {formatMessage({ id: IntlKeys.usersCreate })}
        </Button>
      </FlexBox>
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={talents}
        columns={columns}
      />
      <Popover
        open={Boolean(statusAnchorEl)}
        anchorEl={statusAnchorEl}
        onClose={handleCloseStatusModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <StatusList>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onStatusChange(e, UserStatusEnum.UnderReview)}>
              <ListItemText primary="Under Review" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onStatusChange(e, UserStatusEnum.Active)}>
              <ListItemText primary="Active" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onStatusChange(e, UserStatusEnum.Inactive)}>
              <ListItemText primary="Inactive" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onStatusChange(e, UserStatusEnum.Archived)}>
              <ListItemText primary="Archived" />
            </ListItemButton>
          </ListItem>
        </StatusList>
      </Popover>
      <Popover
        open={Boolean(roleAnchorEl)}
        anchorEl={roleAnchorEl}
        onClose={handleCloseRoleModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <StatusList>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onRoleChange(e, UserRoles.ADMIN)}>
              <ListItemText primary="Admin" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onRoleChange(e, UserRoles.USER)}>
              <ListItemText primary="User" />
            </ListItemButton>
          </ListItem>
        </StatusList>
      </Popover>
      <ModalDialog isOpen={newUserModalOpen} onClose={closeCreateUserModal}>
        <CreateUserModal
          refetch={refetch}
          closeModal={closeCreateUserModal}
          companyId={company.id}
          userId={currentUserId}
        />
      </ModalDialog>

      <ConfirmDialog
        isOpen={isDeleteConfirmModalOpen}
        onConfirm={handleDeleteUser}
        onCancel={handleDeleteConfirmModalClose}
        isLoading={isDeleteInProgress}
        title={`Are you sure wanted to delete the selected user?`}
        description="Deleting users is a permanant action. This can not be undone."
      ></ConfirmDialog>
    </Container>
  );
};

export default CompanyUsers;

const Container = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;

  svg {
    cursor: pointer;
    fill: ${theme.colors.red};
  }
  button {
    padding: 0.25rem 1rem;
    box-sizing: border-box;
    font-size: 0.85rem;
  }
`;

const StatusLabel = styled.div`
  background-color: ${(props: { status: string }) =>
    props.status === 'active' ? theme.colors.primary : theme.colors.warning};
  padding: 0.3rem 1rem;
  margin: 0 1rem 0 0;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 5px;
  color: ${theme.colors.white};
`;

const StatusList = styled(List)`
  width: 200px;
  padding: 0;
`;

// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  nameAsc: {
    field: 'name',
    order: 'ASC',
  },
  nameDesc: {
    field: 'name',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const UsersSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type UsersFiltersFormValues = FilterFormValues<keyof typeof UsersSortConfigs>;

export const UsersSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'nameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'nameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtDesc',
    },
  ];
};

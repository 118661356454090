import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import {
  cancelInvoiceStatusAction,
  fetchInvoicesWorker,
  sendInvoiceReminderAction,
  updateInvoiceStatusAction,
} from 'domain/invoices/actions';
import { useAppDispatch } from 'domain/store';
import { formatDate } from 'helpers/date';
import { formatCurrency } from 'helpers/number';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { InvoiceType } from 'types/invoices';
import { InvoicesFiltersFormValues, InvoicesSortConfigs } from 'types/invoices/entities';
import { MdDownload, MdSend } from 'react-icons/md';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from 'pages/CompanyDetails/invoice/Invoice';
import { Button, List, ListItem, Tab, Tabs, Tooltip } from '@mui/material';
import ModalDialog from 'components/base/ModalDialog';
import { Wrapper } from 'components/Layout';

const Invoices = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceType | null>(null);
  const [selectedTab, setselectedTab] = useState<'paid' | 'unpaid'>('unpaid');
  const filterConfig: InvoicesFiltersFormValues = {
    sort: 'createdAtDesc',
    filters: { isPaid: 'false' },
  };

  const handleOpenStatusModal = (invoice: InvoiceType) => {
    if (!invoice.cancelInvoice) setSelectedInvoice(invoice);
  };

  const handleCloseStatusModal = () => {
    setSelectedInvoice(null);
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    refetch,
    resources: invoices,
    fetchNextPage,
    setFilters,
    //
    pagination,
  } = useInfiniteData({
    queryKey: 'invoiceList',
    //
    fetchResources: fetchInvoicesWorker,
    initialSort: InvoicesSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });
  const handleChange = (event: React.SyntheticEvent, newValue: 'paid' | 'unpaid') => {
    setselectedTab(newValue);
    setFilters({ isPaid: newValue === 'paid' ? 'true' : 'false' });
    refetch();
  };
  const sendReminder = (id: number) => {
    dispatch(sendInvoiceReminderAction({ params: { id } }))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.invoiceReminderSend }));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const updateInvoiceStatus = (id: number) => {
    dispatch(updateInvoiceStatusAction({ params: { id } }))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.invoiceMarkedAsPaidSuccessfully }));
        refetch();
      })
      .catch((error) => {
        toast.error(error.message);
      });
    handleCloseStatusModal();
  };

  const cancelInvoice = (id: number) => {
    dispatch(cancelInvoiceStatusAction({ params: { id } }))
      .unwrap()
      .then(() => {
        toast.success(formatMessage({ id: IntlKeys.invoiceMarkedAsPaidSuccessfully }));
        refetch();
      })
      .catch((error) => {
        toast.error(error.message);
      });
    handleCloseStatusModal();
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.invoicesDate }),
          accessor: (row) => formatDate(row.createdAt),
          id: 'createdAt',
        },
        {
          Header: formatMessage({ id: IntlKeys.company }),
          accessor: (row) => row.company?.name || row.fallbackCompany?.name,
          id: 'company',
        },
        {
          Header: formatMessage({ id: IntlKeys.invoicesItem }),
          accessor: (row) => row.item,
          id: 'item',
        },
        {
          Header: formatMessage({ id: IntlKeys.amount }),
          accessor: (row) => formatMessage({ id: IntlKeys.creditsAmount }, { count: row.quantity }),
          id: 'quantity',
        },
        {
          Header: formatMessage({ id: IntlKeys.invoicesTotal }),
          accessor: (row) => formatCurrency(row.perItemPrice * row.quantity),
          id: 'total',
        },
        {
          Header: formatMessage({ id: IntlKeys.invoicesStatus }),
          accessor: (row) => (
            <>
              <Tooltip title="Click to change status" onClick={() => handleOpenStatusModal(row)}>
                <Status isPaid={row.isPaid} isStorno={Boolean(row.cancelInvoice)}>
                  {formatMessage({
                    id: row.cancelInvoice
                      ? IntlKeys.invoiceCanceled
                      : row.isPaid
                      ? IntlKeys.invoicePaid
                      : IntlKeys.invoiceUnpaid,
                  })}
                </Status>
              </Tooltip>
            </>
          ),
          id: 'status',
        },
        {
          Header: '',
          accessor: (row) => (
            <Actions>
              <PDFDownloadLink document={<Invoice invoice={row} />}>
                <MdDownload />
              </PDFDownloadLink>
              {!row.isPaid && !row.cancelInvoice && (
                <Tooltip title="Send reminder email">
                  <Button onClick={() => sendReminder(row.id)}>
                    <MdSend />
                  </Button>
                </Tooltip>
              )}
            </Actions>
          ),
          id: 'action',
        },
      ] as InfiniteColumnsType<InvoiceType>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <TabsContainer>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Job details tabs">
        <Tab value="paid" label={formatMessage({ id: IntlKeys.invoicePaid })} />
        <Tab value="unpaid" label={formatMessage({ id: IntlKeys.invoiceUnpaid })} />
      </Tabs>
      <ListConainer>
        <InfiniteTable
          isLoading={isLoading || isFetchingNextPage}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.perPage}
          fetchNext={fetchNextPage}
          data={invoices}
          columns={columns}
        />
      </ListConainer>
      <ModalDialog
        title={formatMessage({ id: IntlKeys.invoicesStatus })}
        isOpen={selectedInvoice !== null}
        handleClose={handleCloseStatusModal}
      >
        <ModalBody>
          <StatusList>
            {selectedInvoice && !selectedInvoice.cancelInvoice && (
              <ListItem disablePadding>
                <MarkButton onClick={() => updateInvoiceStatus(selectedInvoice.id)}>
                  {formatMessage({
                    id: selectedInvoice.isPaid ? IntlKeys.invoiceMarkAsUnpaid : IntlKeys.invoiceMarkAsPaid,
                  })}
                </MarkButton>
              </ListItem>
            )}
            {selectedInvoice && !selectedInvoice.cancelInvoice && (
              <ListItem disablePadding>
                <MarkButton onClick={() => cancelInvoice(selectedInvoice.id)}>
                  {formatMessage({ id: IntlKeys.invoiceMarkAsStorno })}
                </MarkButton>
              </ListItem>
            )}
          </StatusList>
        </ModalBody>
      </ModalDialog>
    </TabsContainer>
  );
};

export default Invoices;

const TabsContainer = styled.section`
  margin: 1rem;
`;

const ListConainer = styled.div`
  margin: 1rem 0;
`;

const ModalBody = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const StatusList = styled(List)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const Status = styled.div`
  background-color: ${(props: { isPaid: boolean; isStorno: boolean }) =>
    props.isStorno ? 'transparent' : props.isPaid ? theme.colors.success : theme.colors.warning};
  color: ${(props: { isPaid: boolean; isStorno: boolean }) => (props.isStorno ? theme.colors.red : theme.colors.white)};
  border: ${(props: { isPaid: boolean; isStorno: boolean }) =>
    props.isStorno ? '1px solid ' + theme.colors.red : 'none'};
  border-radius: 4px;
  padding: 0.25rem 1rem;
  width: fit-content;
  font-weight: 500;
  font-size: 0.875rem;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`;
const Actions = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
`;
const MarkButton = styled.div`
  border-radius: 4px;
  border-style: solid;
  border-color: ${theme.colors.link};
  border-width: 1px;
  box-sizing: border-box;
  color: ${theme.colors.link};
  padding: 0.25rem 1rem;
  width: fit-content;
  text-align: center;
  min-width: 80%;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  margin: 0.5rem auto;

  :hover {
    background-color: ${theme.colors.link};
    color: white;
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`;

import { Button, Card, ConfirmDialog, FlexBox, PageContainer } from 'components/base';
import { Loader } from 'components/loader';
import { jobsMetaSelector, jobsResourceSelector } from 'domain/jobs/selectors';
import { deleteJobAction, getJobDetailsAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { theme } from 'theme';
import JobDetailsTabs from './components/JobDetailsTabs';
import { formatDate } from 'helpers/date';
import { formatCurrency } from 'helpers/number';
import { AiFillDelete, AiFillEdit, AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const JobDetails = () => {
  const { jobId } = useParams();
  const job = useSelector(jobsResourceSelector);
  const { isLoading } = useSelector(jobsMetaSelector);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetailsAction({ params: { id: jobId } }));
    }
  }, [dispatch, jobId]);

  const confirmDeleteSelectedPools = useCallback(async () => {
    setIsDeleteInProgress(true);
    dispatch(deleteJobAction({ params: { id: jobId! } }))
      .unwrap()
      .then(() => {
        toast.success('Successfully deleted the job');
        setIsDeleteInProgress(false);
      })
      .catch(() => {
        toast.error('Failed to delete the job. Please check with the Engineering team');
        setIsDeleteInProgress(false);
      });
  }, [jobId, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  if (!job) {
    return <div>No job detailed fetched</div>;
  }

  const handleOpenDeleteModal = () => {
    setIsDeleteConfirmModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteConfirmModalOpen(false);
  };

  const { company } = job;

  return (
    <PageContainer>
      <CompanyName onClick={() => navigate(`/companies/${company.id}`)}>{company.name}</CompanyName>
      <MetaDetails>
        <Card bgColor={theme.colors.primary}>
          <EachDetails>
            Lookign for: <strong>{job?.title}</strong>
          </EachDetails>
          <EachDetails>Seniority: {job?.seniority}</EachDetails>
          <EachDetails>
            <strong>
              {job?.applicationCount || 0}/{job?.maxCandidates || 0}
            </strong>{' '}
            Candidates provided
          </EachDetails>
          <EachDetails>Published on: {formatDate(job?.createdAt as string)}</EachDetails>
          <EachDetails>
            Salary: {formatCurrency(job?.minSalary || 0)} - {formatCurrency(job?.maxSalary || 0)}
          </EachDetails>
        </Card>
        <ActionCard withBorder>
          <CardHead>Quick Actions</CardHead>
          <ButtonContainer direction="column" gap={'1rem'}>
            <Link to={`/jobs/edit?jobId=${job.id}`} target={'_blank'}>
              <AiFillEdit /> Edit
            </Link>
            <a
              href={`${process.env.REACT_APP_CLIENT_PUBLIC_URL}jobs/${job.id}/apply`}
              target={'_blank'}
              rel="noreferrer"
            >
              <AiFillEye /> Preview
            </a>
            <Button bgColor={theme.colors.red} onClick={handleOpenDeleteModal}>
              <AiFillDelete /> Delete
            </Button>
          </ButtonContainer>
        </ActionCard>
        <RequirementCard bgColor={theme.colors.mintGreen}>
          <CardHead>Requirements</CardHead>
          <Requirements dangerouslySetInnerHTML={{ __html: job?.requirements || '' }} />
        </RequirementCard>
      </MetaDetails>
      <JobDetailsTabs job={job} />
      <ConfirmDialog
        isOpen={isDeleteConfirmModalOpen}
        onConfirm={confirmDeleteSelectedPools}
        onCancel={handleCloseDeleteModal}
        isLoading={isDeleteInProgress}
        title={`Are you sure wanted to delete the job?`}
        description="Deleting job is a permanant action. This can not be undone."
      ></ConfirmDialog>
    </PageContainer>
  );
};

export default JobDetails;

const CompanyName = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-weight: 500;
  color: ${theme.colors.secondary};
  display: inline-block;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const MetaDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const EachDetails = styled.div`
  color: ${theme.colors.white};
  padding: 0.5rem 0;
`;

const RequirementCard = styled(Card)`
  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    grid-column: 1/3;
  }
`;

const Requirements = styled.div`
  max-height: 200px;
  overflow: auto;

  * {
    background-color: transparent !important;
  }
  ul {
    padding-left: 0;
  }
`;

const CardHead = styled.h3`
  font-size: 1.25rem;
  margin: 0 0 1rem;
`;

const ActionCard = styled(Card)`
  padding-right: 3rem;
`;

const ButtonContainer = styled(FlexBox)`
  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  a {
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border: 1px solid ${theme.colors.stroke};
    text-decoration: none;
    color: ${theme.colors.secondary};
    font-weight: 400;

    &:visited {
      color: ${theme.colors.secondary};
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

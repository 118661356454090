import { SortConfigType, FilterFormValues } from 'types/common';

const iSortConfigs = {
  updatedAtAsc: {
    field: 'updatedAt',
    order: 'ASC',
  },
  updatedAtDesc: {
    field: 'updatedAt',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const InvoicesSortConfigs = iSortConfigs as Record<keyof typeof iSortConfigs, SortConfigType>;
export type InvoicesFiltersFormValues = FilterFormValues<keyof typeof iSortConfigs>;

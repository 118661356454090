import * as React from 'react';
import styled from 'styled-components';

interface OwnProps {
  text?: string;
}

const EmptyJobs: React.FC<OwnProps> = ({ text = 'No jobs added yet' }) => {
  return <EmptyContainer>{text}</EmptyContainer>;
};

export default EmptyJobs;

const EmptyContainer = styled.div`
  margin-top: 2rem;
`;

import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Wrapper } from 'components/Layout';
import React, { useCallback, useState, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import Button, { SubmitButton } from 'components/base/Button';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { CompanyPayload } from 'types/company';
import { createCompanyAction, getCompanyDetailsAction, updateCompanyAction } from 'domain/companies/actions';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import TextEditor from 'components/base/TextEditor';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { industriesOptions, MediaFileObj, uploadMedia } from '../helpers';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import { CountryEnum } from 'helpers/countryEnum';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import FormCheckBox from 'components/hook-form/fields/FormCheckBox';

interface OwnProps {
  refetch?: () => void;
  closeModal: () => void;
  companyId?: number | null;
  type?: 'create' | 'edit';
}

const headCountOptions = ['1 - 10', '10 - 100', '100 - 1000', '1000+', '20,000+'];

const CreateEditCompanyForm: React.FC<OwnProps> = ({ closeModal, refetch, type = 'create', companyId }) => {
  const dispatch = useAppDispatch();
  const companyForm = useForm<
    CompanyPayload & {
      logoObj?: MediaFileObj;
      descriptionLanguage: 'de-DE' | 'en-US';
      descriptionTextDe: string;
      descriptionTextEn: string;
      useAdressForBilling: boolean;
    }
  >({ defaultValues: { useAdressForBilling: type === 'create' } });
  const { formatMessage } = useIntl();
  const logoObj = useWatch({ name: 'logoObj', control: companyForm.control });
  const uploadedLogo = useWatch({ name: 'logo', control: companyForm.control });
  const descriptionLanguage = useWatch({ name: 'descriptionLanguage', control: companyForm.control });
  const useAdressForBilling = useWatch({ name: 'useAdressForBilling', control: companyForm.control });
  const address = useWatch({ name: 'address', control: companyForm.control });
  const billingAddress = useWatch({ name: 'billingAddress', control: companyForm.control });
  const [isSubitting, setIsSubitting] = useState(false);

  const countryOptions = Object.keys(CountryEnum).map((key) => ({
    label: key,
    value: CountryEnum[key as keyof typeof CountryEnum],
  }));

  useEffect(() => {
    if (companyId && type === 'edit') {
      dispatch(getCompanyDetailsAction({ params: { id: companyId } }))
        .unwrap()
        .then(({ description, ...response }: CompanyPayload) => {
          const descriptionTextDe = description ? description['de-DE'] : '';
          const descriptionTextEn = description ? description['en-US'] : '';
          companyForm.reset({
            ...response,
            description,
            descriptionLanguage: 'de-DE',
            descriptionTextDe,
            descriptionTextEn,
          });
        });
    }
  }, [companyForm, companyId, dispatch, type]);

  const onCompanyCreationFormSubmit = useCallback(
    async (
      data: CompanyPayload & {
        logoObj?: MediaFileObj;
        descriptionLanguage: 'de-DE' | 'en-US';
        descriptionTextDe: string;
        descriptionTextEn: string;
      },
    ) => {
      setIsSubitting(true);
      const { descriptionTextDe, descriptionTextEn, description, logoObj, ...formValues } = data;
      const [logo] = await Promise.all([uploadMedia(logoObj, data.logo)]);
      const newDescription = {
        'de-DE': descriptionTextDe,
        'en-US': descriptionTextEn,
      };
      const action =
        type === 'edit'
          ? updateCompanyAction({
              params: { id: companyId! },
              ...formValues,
              logo,
              description: newDescription,
              billingAddress: useAdressForBilling ? address : billingAddress,
            })
          : createCompanyAction({
              ...formValues,
              logo,
              description: newDescription,
              billingAddress: useAdressForBilling ? address : billingAddress,
            });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('Company created successfully');
          closeModal?.();
          refetch?.();
          setIsSubitting(false);
        })
        .catch((err) => {
          setIsSubitting(false);
          toast.error(err?.data?.response?.data?.message || 'Failed to create company! please try again later');
        });
    },
    [address, billingAddress, closeModal, companyId, dispatch, refetch, type, useAdressForBilling],
  );

  const changeDescriptionLanguage = (value: string) => {
    console.log(`change Language to ${value}`);
    const thisDescription = companyForm.getValues('description') || {};
    if (value === 'de-DE') {
      thisDescription['en-US'] = companyForm.getValues('descriptionTextEn');
      companyForm.setValue('descriptionLanguage', 'de-DE');
    } else {
      thisDescription['de-DE'] = companyForm.getValues('descriptionTextDe');
      companyForm.setValue('descriptionLanguage', 'en-US');
    }
    companyForm.setValue('description', thisDescription);
    console.log(thisDescription);
  };

  const handleLogoUpload = useCallback(
    (logoObj: MediaFileObj) => {
      companyForm.setValue('logoObj', logoObj);
    },
    [companyForm],
  );

  const removeLogo = () => {
    companyForm.setValue('logoObj', undefined);
    companyForm.setValue('logo', undefined);
  };

  const languagesOptions = [
    {
      value: 'de-DE',
      label: '🇩🇪 Deutsch',
    },
    {
      value: 'en-US',
      label: '🇬🇧 English',
    },
  ];

  return (
    <CreateCompanyForm>
      <FormProvider {...companyForm}>
        <Form onSubmit={companyForm.handleSubmit(onCompanyCreationFormSubmit)}>
          <EachInput label={formatMessage({ id: IntlKeys.companyLogo })}>
            <ImageDropzone
              onDrop={(acceptedFiles) =>
                handleLogoUpload({
                  fileObj: acceptedFiles[0],
                  src: URL.createObjectURL(acceptedFiles[0]),
                  name: acceptedFiles[0].name,
                })
              }
              maxFiles={1}
              height={200}
              src={uploadedLogo?.url || logoObj?.src}
              multiple={false}
              previewClassName={'comapny-logo-preview'}
              onRemove={removeLogo}
              cropConfig={{ viewMode: 0 }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.companyName })}>
            <FormTextField
              name={'name'}
              placeholder={formatMessage({ id: IntlKeys.companyNamePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.companyDescriptionLangugage })}>
            <FormRadioSwitch
              noBullet
              name="descriptionLanguage"
              options={languagesOptions}
              onChangeExternal={changeDescriptionLanguage}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.companyDescription })}>
            {descriptionLanguage === 'de-DE' && <TextEditor name="descriptionTextDe" />}
            {descriptionLanguage === 'en-US' && <TextEditor name="descriptionTextEn" />}
          </EachInput>

          <EachInput label={formatMessage({ id: IntlKeys.companyIndustry })}>
            <FormAutoComplete
              freeSolo
              name="industry"
              placeholder={formatMessage({ id: IntlKeys.companyIndustryPH })}
              options={industriesOptions}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.companyEmployeeCount })}>
            <FormAutoComplete
              freeSolo
              name="employeeCount"
              placeholder={formatMessage({ id: IntlKeys.selectSeniority })}
              options={headCountOptions.map((option) => ({ label: option, value: option }))}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.linkedin })}>
            <FormTextField name={'linkedin'} placeholder={formatMessage({ id: IntlKeys.linkedinPlaceholder })} />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.xing })}>
            <FormTextField name={'xing'} placeholder={formatMessage({ id: IntlKeys.xingPlaceholder })} />
          </EachInput>
          <h3>Address</h3>
          <EachInput label={formatMessage({ id: IntlKeys.street })}>
            <FormTextField
              name={'address[street]'}
              placeholder={formatMessage({ id: IntlKeys.streetPlaceholder })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.houseno })}>
            <FormTextField
              name={'address[housenumber]'}
              placeholder={formatMessage({ id: IntlKeys.housenoPlaceholder })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.postcode })}>
            <FormTextField
              name={'address[postcode]'}
              placeholder={formatMessage({ id: IntlKeys.postcodePlaceholder })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.city })}>
            <FormTextField
              name={'address[city]'}
              placeholder={formatMessage({ id: IntlKeys.cityPlaceholder })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.country })}>
            <FormAutoComplete
              options={countryOptions}
              name={'address.country'}
              freeSolo
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <h3>Billing Address</h3>
          <CheckBoxContainer>
            <FormCheckBox name={'useAdressForBilling'} defaultChecked={useAdressForBilling} />
            {formatMessage({ id: IntlKeys.useAdressForBilling })}
          </CheckBoxContainer>
          {!useAdressForBilling && (
            <>
              <EachInput label={formatMessage({ id: IntlKeys.street })}>
                <FormTextField
                  name={'billingAddress[street]'}
                  placeholder={formatMessage({ id: IntlKeys.streetPlaceholder })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.houseno })}>
                <FormTextField
                  name={'billingAddress[housenumber]'}
                  placeholder={formatMessage({ id: IntlKeys.housenoPlaceholder })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.postcode })}>
                <FormTextField
                  name={'billingAddress[postcode]'}
                  placeholder={formatMessage({ id: IntlKeys.postcodePlaceholder })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.city })}>
                <FormTextField
                  name={'billingAddress[city]'}
                  placeholder={formatMessage({ id: IntlKeys.cityPlaceholder })}
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
              <EachInput label={formatMessage({ id: IntlKeys.country })}>
                <FormAutoComplete
                  options={countryOptions}
                  name={'billingAddress.country'}
                  freeSolo
                  rules={{
                    required: formatMessage({ id: IntlKeys.requiredItem }),
                  }}
                />
              </EachInput>
            </>
          )}
          <FormFooter>
            <SubmitButton isLoading={isSubitting} disabled={isSubitting}>
              {formatMessage({ id: IntlKeys.submit })}
            </SubmitButton>
            <Button variant="outline" onClick={closeModal}>
              Cancel
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </CreateCompanyForm>
  );
};

export default CreateEditCompanyForm;
const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0px 4px 0px 0px;
  }
`;

const CreateCompanyForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

import React, { useCallback, useMemo } from 'react';
import { fetchBenefitsWorker } from 'domain/benefits/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { BenefitType } from 'types/benefit';
import { BenefitFiltersFormValues, BenefitsSortConfics } from 'types/benefit/entities';
import BenefitsFilter from './components/BenefitsFilter';
import { debounce } from 'lodash';
import { PageContainer } from 'components/base';

const Benefits = () => {
  const { formatMessage } = useIntl();

  const filterConfig: BenefitFiltersFormValues = {
    sort: 'benefitNameAsc',
    filters: { globalOnly: 'true' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: benefits,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchBenefitsWorker,
    initialSort: BenefitsSortConfics[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => `${row.icon} ${row.name}`,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.language }),
          accessor: (row) => `${row.language}`,
          id: 'language',
        },
        {
          Header: formatMessage({ id: IntlKeys.company }),
          accessor: (row) => row.company?.name,
          id: 'company',
        },
      ] as InfiniteColumnsType<BenefitType>,
    [formatMessage],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: BenefitFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(BenefitsSortConfics[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  return (
    <PageContainer>
      <BenefitsFilter config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
      <InfiniteTable
        columns={columns}
        data={benefits}
        fetchNext={fetchNextPage}
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
      />
    </PageContainer>
  );
};

export default Benefits;

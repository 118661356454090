import { createSlice } from '@reduxjs/toolkit';

import { getCompanyDetailsAction, getCompaniesAction } from './actions';

import { CompanyType } from 'types/company';
import { CompaniesSortConfigs } from 'types/company/entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: CompaniesSortConfigs.companyNameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as CompanyType | null,
  resources: [] as CompanyType[],
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getCompaniesAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getCompaniesAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getCompaniesAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getCompanyDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getCompanyDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getCompanyDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      });
  },
});

export const companiesReducer = companiesSlice.reducer;

import { ConfirmDialog, PageContainer } from 'components/base';
import { deleteMessageAction, fetchMessagesWorker, updateMessageStatusAction } from 'domain/messages/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { MessageFiltersFormValues, MessagesSortConfigs } from 'types/messages/entitties';
import MessagesFilter from './components/MessagesFilter';
import { debounce } from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { MdDelete, MdRestoreFromTrash, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { theme } from 'theme';
import styled from 'styled-components';
import { IntlKeys } from 'localization';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { formatDate } from 'helpers/date';

const Home = () => {
  const { formatMessage } = useIntl();
  const [currentStatus, setCurrentStatus] = useState('new');
  const [currentMessage, setCurrentMessage] = useState<number | null>(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const dispatch = useAppDispatch();

  const filterConfig: MessageFiltersFormValues = {
    sort: 'messageSendAtDesc',
    filters: { status: currentStatus },
  };

  const {
    resources: messages,
    setSort,
    setFilters,
    refetch,
  } = useInfiniteData({
    queryKey: 'messagesList',
    fetchResources: fetchMessagesWorker,
    initialSort: MessagesSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  const updateMessageStatus = useCallback(
    (id: number) => {
      const newStatus = currentStatus == 'reviewed' ? 'new' : 'reviewed';
      const action = updateMessageStatusAction({ params: { id }, status: newStatus });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('status updated');
          refetch();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    [currentStatus, dispatch, refetch],
  );

  const deleteMessage = useCallback(
    (id: number) => {
      if (currentStatus === 'archived') {
        setCurrentMessage(id);
        setIsDeleteConfirmModalOpen(true);
      } else {
        const action = updateMessageStatusAction({ params: { id }, status: 'archived' });
        dispatch(action)
          .unwrap()
          .then(() => {
            toast.success('message archived');
            refetch();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    },
    [currentStatus, dispatch, refetch],
  );

  const handleCloseDeleteModal = () => {
    setCurrentMessage(null);
    setIsDeleteConfirmModalOpen(false);
  };

  const confirmDeleteMessage = useCallback(async () => {
    if (currentMessage) {
      setIsDeleteInProgress(true);
      const action = deleteMessageAction({ params: { id: currentMessage } });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('message deleted');
          refetch();
          handleCloseDeleteModal();
          setIsDeleteInProgress(false);
        })
        .catch(() => {
          toast.error('failed to delete message');
          handleCloseDeleteModal();
          setIsDeleteInProgress(false);
        });
    }
  }, [currentMessage, dispatch, refetch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: MessageFiltersFormValues) => {
      const { sort, filters } = config;
      setCurrentStatus(filters.status as string);
      setSort(MessagesSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  return (
    <PageContainer>
      <MessagesFilter onChange={handleFiltersChange} config={filterConfig} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>E-Mail</TableCell>
            <TableCell>{formatMessage({ id: IntlKeys.phone })}</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Viewed By</TableCell>
            <TableCell>Viewed At</TableCell>
            <TableCell>Send At</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((message) => (
            <TableRow key={message.id}>
              <TableCell>{message.name}</TableCell>
              <TableCell>{message.email}</TableCell>
              <TableCell>{message.phone}</TableCell>
              <TableCell>{message.message}</TableCell>
              <TableCell>
                {message.reviewer ? `${message.reviewer.firstName} ${message.reviewer.lastName}` : ''}
              </TableCell>
              <TableCell>{message.reviewedAt && formatDate(message.reviewedAt, 'DD.MM.YYYY, HH:MM')}</TableCell>
              <TableCell>{formatDate(message.sendAt, 'DD.MM.YYYY, HH:MM')}</TableCell>
              <TableCell>
                <Actions>
                  {currentStatus === 'new' && (
                    <Tooltip title={formatMessage({ id: IntlKeys.messagesMarkReviewed })}>
                      <MdVisibility color={theme.colors.primary} onClick={() => updateMessageStatus(message.id)} />
                    </Tooltip>
                  )}
                  {currentStatus === 'reviewed' && (
                    <Tooltip title={formatMessage({ id: IntlKeys.messagesUnmarkReviewed })}>
                      <MdVisibilityOff color={theme.colors.primary} onClick={() => updateMessageStatus(message.id)} />
                    </Tooltip>
                  )}
                  {currentStatus === 'archived' && (
                    <Tooltip title={formatMessage({ id: IntlKeys.messagesUnmarkReviewed })}>
                      <MdRestoreFromTrash
                        color={theme.colors.primary}
                        onClick={() => updateMessageStatus(message.id)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title={formatMessage({ id: IntlKeys.delete })}>
                    <MdDelete color={theme.colors.red} onClick={() => deleteMessage(message.id)} />
                  </Tooltip>
                </Actions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ConfirmDialog
        isOpen={isDeleteConfirmModalOpen}
        onConfirm={confirmDeleteMessage}
        onCancel={handleCloseDeleteModal}
        isLoading={isDeleteInProgress}
        title={`Are you sure wanted to delete the message?`}
        description="Deleting Message is a permanant action. This can not be undone."
      ></ConfirmDialog>
    </PageContainer>
  );
};

export default Home;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  justify-content: end;

  svg {
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.6;
  }
  button {
    padding: 0.25rem 1rem;
    box-sizing: border-box;
    font-size: 0.85rem;
  }
`;

import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
// import { LinearProgress } from '@mui/material';
//
import DefaultCardSkeleton from 'components/skeletons/DefaultCardSkeleton';
//
import styled from 'styled-components';
import { theme } from 'theme';

export interface InfiniteGridProps {
  list: unknown[];
  //
  isLoading: boolean;
  isLoaded?: boolean;
  isStale?: boolean;
  //
  page: number;
  pageCount: number;
  perPage?: number;
  itemCount?: number;
  //
  fetchNext: VoidFunction;
  //
  emptyState?: React.ReactNode;
  createCard?: React.ReactNode;
  //
  ListCard: React.ElementType<{ data: unknown }>;
  CardSkeleton?: React.ElementType;
  minWidth?: number;
  componentProps?: Record<string, unknown>;
}

const VerticalInfiniteGrid: React.FC<InfiniteGridProps> = ({
  list = [],
  //
  isLoading,
  isLoaded = false,
  isStale = false,
  //
  page,
  perPage = 10,
  pageCount,
  itemCount = 0,
  //
  fetchNext,
  //
  emptyState,
  createCard,
  //
  ListCard,
  minWidth = 300,
  componentProps = {},
  CardSkeleton = DefaultCardSkeleton,
}) => {
  //
  const resources = isStale ? [] : list;
  //
  const morePagesAvailable = isLoaded && pageCount > page;
  const shouldReload = !isLoaded && page === 0 && pageCount === 0;
  //
  // add one addition skeleton card on initial loading if create card present
  const createCardPresent = Boolean(createCard);
  const conditionalPerPage = isLoaded ? perPage : perPage + (createCardPresent ? 1 : 0);

  const itemsLeft = itemCount !== 0 ? itemCount - resources.length : conditionalPerPage;
  const itemsLoading = Math.min(itemsLeft, conditionalPerPage);
  const hasMore = !isLoading && (morePagesAvailable || shouldReload || isStale);

  //
  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchNext,
  });

  console.log('resources', resources);

  return (
    <Wrapper>
      {!isLoading && isLoaded && list.length === 0 ? (
        <NoDataContainer>{emptyState}</NoDataContainer>
      ) : (
        <Container minWidth={minWidth}>
          {createCard && isLoaded && <Card>{createCard}</Card>}

          {resources?.map((data, ix) => (
            <Card key={(data as { id?: string })?.id || ix}>
              <ListCard data={data} {...componentProps} />
            </Card>
          ))}

          {isLoading &&
            new Array(itemsLoading).fill(0).map((_, ix) => (
              <Card key={ix}>
                <CardSkeleton />
              </Card>
            ))}
        </Container>
      )}

      {/* ///////////////////////// SENTRY ///////////////////////// */}
      {hasMore && <SentryComponent ref={sentryRef} />}

      {/* {isLoading && <CustomLinearProgress />} */}
    </Wrapper>
  );
};

interface ContainerProps {
  minWidth?: number;
}

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props: ContainerProps) =>
    `repeat(auto-fill, minmax(min(${props.minWidth || 300}px, 100%), 1fr))`};
  margin: 1em auto;
  width: 100%;
  gap: 1rem;
`;

const NoDataContainer = styled.div`
  height: 100%;
`;

// const CustomLinearProgress = styled(LinearProgress)`
//   margin-top: 1rem;
//   margin-bottom: 1rem;
// `;

const Card = styled.div`
  padding: 0 !important;
  margin: 0;
  width: 100%;

  @media (max-width: ${theme.breakpoints.md}px) {
    margin: 0 auto;
  }
`;

////////////////////////////////////////////////////////////////////////////////
// useInfiniteScroll uses IntersectionObserver under the hood
// when sentry element becomes visible - load
const SentryComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200;
  visibility: hidden;
`;

export default React.memo(VerticalInfiniteGrid);

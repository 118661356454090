import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.add]: 'Hinzufügen',
  [IntlKeys.image]: 'Bild',
  [IntlKeys.video]: 'Video',
  [IntlKeys.addLinkToIntroVideo]: 'Link zu Video hibzufügen',
  [IntlKeys.amount]: 'Anzahl',
  [IntlKeys.avatar]: 'Avatar',
  [IntlKeys.avatarPlaceholder]: 'Avatar auswählen',
  [IntlKeys.comment]: 'Kommentar',
  [IntlKeys.helloWorld]: 'Hello World',
  [IntlKeys.email]: 'Email',
  [IntlKeys.password]: 'Password',
  [IntlKeys.enterPassword]: 'Passwort eingeben',
  [IntlKeys.passwordPlaceholder]: 'Geben Sie Ihr Passwort ein',
  [IntlKeys.retypePassword]: 'Gebe dein Passwort erneut ein',
  [IntlKeys.newPassword]: 'Neues Passwort',
  [IntlKeys.retypeNewPassword]: 'Neues Passwort widerholen',
  [IntlKeys.confirm]: 'Confirm',
  [IntlKeys.cancel]: 'Cancel',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePH]: 'Enter your name',
  [IntlKeys.firstName]: 'Vorname',
  [IntlKeys.firstNamePH]: 'Vornamen eingeben',
  [IntlKeys.jobTitle]: 'Job title',
  [IntlKeys.jobTitlePlaceholder]: 'Enter your job title',
  [IntlKeys.yes]: 'Ja',
  [IntlKeys.no]: 'Nein',
  [IntlKeys.subscription]: 'Abonnement',
  [IntlKeys.submit]: 'Absenden',
  [IntlKeys.company]: 'Unternehmen',
  [IntlKeys.delete]: 'Löschen',
  [IntlKeys.language]: 'Sprache',
  [IntlKeys.dragNDrop]: 'Datei hier hinziehen oder klicken um Datei hochzuladen',
  [IntlKeys.edit]: 'Bearbeiten',
  [IntlKeys.emoji]: 'Emoji',
  [IntlKeys.emojiSelect]: 'Emoji auswählen',
  [IntlKeys.linkedin]: 'LinkedIn',
  [IntlKeys.linkedinPlaceholder]: 'LinkedIn eingeben',
  [IntlKeys.xing]: 'Xing',
  [IntlKeys.xingPlaceholder]: 'Xing eingeben',
  [IntlKeys.phone]: 'Telefonnummer',
  [IntlKeys.position]: 'Position',
  [IntlKeys.socials]: 'Soziale Netzwerke',
  [IntlKeys.talentStatus]: 'Talentstatus',
  [IntlKeys.contacted]: 'Kontaktiert',
  [IntlKeys.useAdressForBilling]: 'Adresse verwenden',
  [IntlKeys.hideSalary]: 'zeige Gehalt dem Talent',
  [IntlKeys.hideSalaryTrue]: 'Zeige es nicht',
  [IntlKeys.hideSalaryFalse]: 'Zeige es',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Willkommen zurück bei Talents&Friends!',
  [IntlKeys.loginSubtitle]: 'Bitte logge dich in deinen Account ein um fortzufahren',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Du hast noch keinen Account?',
  [IntlKeys.signInError401]: 'Falsche E-Mail oder Passwort',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Passwort vergessen',
  [IntlKeys.forgotPasswordQuestion]: 'Passwort vergessen?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Gib die E-Mail Adresse ein welche mit deinem Account verknüpft ist',
  [IntlKeys.forgotPasswordSendLink]: 'Link zum Zurücksetzen senden',
  [IntlKeys.forgotPasswordBackToLogin]: 'Zurück zum Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Link der an deine E-Mail gesendet wurde',
  [IntlKeys.forgotPasswordError404]: 'Diese E-Mail Adresse existiert nicht',

  //RESET PASSWORD PAGE
  [IntlKeys.resetPassword]: 'Passwort zurücksetzen',
  [IntlKeys.resetPasswordSubtitle]: 'Bitte gib dein neues Passwort ein',
  [IntlKeys.resetPasswordChangePassword]: 'Passwort ändern',
  [IntlKeys.resetPasswordHaveAccount]: 'Hast du schon einen Account?',
  [IntlKeys.resetPasswordFulfilled]: 'Passwort erfolgreich geändert',
  [IntlKeys.resetPasswordError404]: 'Dieser Link ist ungültig',
  [IntlKeys.resetPasswordError422]: 'Server kann die Anfrage nicht verarbeiten',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Anmelden',
  [IntlKeys.signUpTitle]: 'Talents&Friends beitreten',
  [IntlKeys.signUpSubTitle]: 'Erstelle einen Account und starte dein Training!',
  [IntlKeys.signUpHaveAccount]: 'Du hast einen Account?',
  [IntlKeys.signUpFulfilled]: 'Benutzer erfolgreich erstellt',
  [IntlKeys.signUpError404]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError422]: 'Dieser Link ist ungültig',
  [IntlKeys.signUpError500]: 'Diese E-Mail Adresse existiert bereits',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'Dieses Feld wird benötigt',
  [IntlKeys.formTimeExceedsTimer]: 'Zeit überschreitet deinen gewählten Timer',

  // Auth validations
  [IntlKeys.passwordsMustMatch]: 'Passwords must match',
  [IntlKeys.eightCharacterAtLeast]: 'Must be 8 characters at least',
  [IntlKeys.incorrectEmail]: 'Incorrect email',
  [IntlKeys.requiredItem]: 'This is required',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Password must be no longer than 20 characters',
  [IntlKeys.enterValidString]: 'Please enter valid string',

  // Address
  [IntlKeys.street]: 'Straße',
  [IntlKeys.streetPlaceholder]: 'Straße eingeben',
  [IntlKeys.houseno]: 'Hausnummer',
  [IntlKeys.housenoPlaceholder]: 'Hausnummer eingeben',
  [IntlKeys.postcode]: 'Postleitzahl',
  [IntlKeys.postcodePlaceholder]: 'PLZ eingeben',
  [IntlKeys.city]: 'Stadt',
  [IntlKeys.cityPlaceholder]: 'Stadt eingeben',
  [IntlKeys.country]: 'Land',
  [IntlKeys.countryPlaceholder]: 'Land eingeben',

  // Jobs
  [IntlKeys.jobs]: 'Jobs',
  [IntlKeys.jobPreview]: 'Vorschau',
  [IntlKeys.jobProceed]: 'Speichern & Weiter',
  [IntlKeys.jobDescription]:
    'Eine <b>{contractType, select, fullTime {Vollzeit} partTime {Teilzeit} other {Andere}}</b> Position mit einem Gehalt zwischen <b>{minSalary}</b> und <b>{maxSalary}</b> pro Jahr in <b>{location}</b>. Es werden <b>{maxCandidates}</b> Talente gesucht bis zum <b>{endDate}</b>',
  [IntlKeys.jobName]: 'Jobtitel',
  [IntlKeys.enterJobTitle]: 'Gebe einen namen für den Auftrag ein',
  [IntlKeys.workLocation]: 'Arbeitsort',
  [IntlKeys.contractType]: 'Vertragstyp',
  [IntlKeys.minSalary]: 'Jahresgehalt Minimum',
  [IntlKeys.maxSalary]: 'Jahresgehalt Maximum',
  [IntlKeys.selectCity]: 'Stadt auswählen',
  [IntlKeys.tasks]: 'Aufgaben',
  [IntlKeys.seniority]: 'Erfahrung',
  [IntlKeys.selectSeniority]: 'Wähle Erfahrung',
  [IntlKeys.selectBenefits]: 'Vorteile auswählen',
  [IntlKeys.benefits]: 'Vorteile',
  [IntlKeys.addBenefits]: 'Vorteile hinzufügen',
  [IntlKeys.createBenefits]: 'Vorteil erstellen',
  [IntlKeys.requirements]: 'Benötigte Fähigkeiten',
  [IntlKeys.jobCover]: 'Coverbild',
  [IntlKeys.introVideo]: 'Einführungsvideo',
  [IntlKeys.gallery]: 'Bildergallerie',
  [IntlKeys.customColor]: 'Eigene Schriftfarbe',
  [IntlKeys.customBackgroundColor]: 'Eigene Hintergrundfarbe',
  [IntlKeys.jobDetails]: 'Details',
  [IntlKeys.customization]: 'Personalisierung',
  [IntlKeys.qualifyingQuestion]: 'Qualifizierungsfragen',
  [IntlKeys.summary]: 'Zusammenfassung',
  [IntlKeys.disqualifyTooltip]:
    'Kandidaten die diese Frage nicht beantworten können, werden automatisch disqualifiziert',
  [IntlKeys.disqualify]: 'Disqualifizierende Frage',
  [IntlKeys.typeYourQuestion]: 'Gebe deine Frage ein',
  [IntlKeys.addNewOption]: 'Füge eine Option hinzu',
  [IntlKeys.activeJobs]: 'Aktive Jobs',
  [IntlKeys.archivedJobs]: 'Archivierte Jobs',
  [IntlKeys.draftJobs]: 'Entwürfe',
  [IntlKeys.pastJobs]: 'Vergangene Jobs',

  // Companies
  [IntlKeys.companyAll]: 'alle Unternehmen',
  [IntlKeys.companySelect]: 'Unternehmen auswählen',
  [IntlKeys.companyListJobs]: 'Jobs (Draft | Public | Done)',
  [IntlKeys.companyListCredits]: 'Credits ( Reserved | Available | Total)',
  [IntlKeys.companyLogo]: 'Unternehmenslogo',
  [IntlKeys.companyName]: 'Name des Unternehmen',
  [IntlKeys.companyNamePH]: 'Namen eingeben',
  [IntlKeys.companyDescription]: 'Über uns',
  [IntlKeys.companyDescriptionPH]: 'Beschreibung des Unternehmens eingeben',
  [IntlKeys.companyDescriptionLangugage]: 'Sprache der Unternehmensbeschreibung',
  [IntlKeys.companyIndustry]: 'Industrie',
  [IntlKeys.companyIndustryPH]: 'Industrie auswählen',
  [IntlKeys.companyEmployeeCount]: 'Mitarbeiteranzahl',

  // Applications
  [IntlKeys.userAcceptedMessage]: '<b>{name}</b> is a match for the <b>{job}</b> position.',
  [IntlKeys.userDeclinedMessage]: '<b>{name}</b> is not a match for the <b>{job}</b> position.',
  // Candidates
  [IntlKeys.candidate]: 'Kandidat',
  // Credits
  [IntlKeys.creditsAmount]: '{count, plural, =0 {keine Credits} one {# Credit} other {# Credits}}',
  // Credit History
  [IntlKeys.creditHistory]: 'Credit Historie',
  [IntlKeys.creditHistoryReason]: 'Ausgegeben für',
  [IntlKeys.creditHistoryReasonSubscription]: 'Subscription Plan',
  [IntlKeys.creditHistoryReasonPurchaseCredits]: 'Erwerb von Credits',
  [IntlKeys.creditHistoryReasonGiftedCredits]: 'Credits verschenkt',
  [IntlKeys.creditHistoryReasonCanditate]: 'Candidate erhalten',
  [IntlKeys.creditHistoryReasonInterview]: 'Interview-Managemant',
  [IntlKeys.creditHistoryReasonWithdrawl]: 'Absage-Managment',
  [IntlKeys.creditHistoryReasonReferences]: 'Referenzen-Check',
  [IntlKeys.creditHistoryReasonAssessment]: 'Assessment-Center',
  [IntlKeys.creditHistoryReasonAts]: 'ATS-Managment',
  // Invoices
  [IntlKeys.invoices]: 'Rechnungen',
  [IntlKeys.invoicesNotAvailable]: 'keine Rechnungen verfügbar',
  [IntlKeys.invoicesDate]: 'Ausstellungsdatum',
  [IntlKeys.invoicesItem]: 'Item',
  [IntlKeys.invoicesTotal]: 'Total',
  [IntlKeys.invoicesStatus]: 'Status',
  [IntlKeys.invoicePaid]: 'Bezahlt',
  [IntlKeys.invoiceUnpaid]: 'Unbezahlt',
  [IntlKeys.invoiceCanceled]: 'Storniert',
  [IntlKeys.invoiceCancel]: 'Storno Rechnung',
  [IntlKeys.invoiceMarkAsPaid]: 'als bezahlt markieren',
  [IntlKeys.invoiceMarkAsUnpaid]: 'als unbezahlt markieren',
  [IntlKeys.invoiceMarkAsStorno]: 'Rechnung stornieren',
  [IntlKeys.invoiceMarkedAsPaidSuccessfully]: 'Rechnung erfolgreich als bezahlt markiert',
  [IntlKeys.invoiceReminderSend]: 'Errinerung gesendet',
  // Users
  [IntlKeys.users]: 'Nutzer',
  [IntlKeys.usersCreate]: 'Nutzer erstellen',
  [IntlKeys.usersCreateSuccessfully]: 'Nutzer anglegt',
  [IntlKeys.usersCreateFailure]: 'Konnte Nutzer nicht anlegen',
  [IntlKeys.usersSearch]: 'Nutzer Suchen',
  [IntlKeys.usersRole]: 'Nutzerrolle',
  [IntlKeys.usersRolePlaceholder]: 'Wähle Rolle',
  [IntlKeys.usersRoleAdmin]: 'Admin',
  [IntlKeys.usersRoleUser]: 'User',
  // Messages
  [IntlKeys.messagesSendAtAsc]: 'Gesendet am ⬆️',
  [IntlKeys.messagesSendAtDesc]: 'Gesendet am ⬇️',
  [IntlKeys.messagesNameAsc]: 'Name ⬆️',
  [IntlKeys.messagesNameDesc]: 'Name ⬇️',
  [IntlKeys.messagesEmailAsc]: 'E-Mail ⬆️',
  [IntlKeys.messagesEmailDesc]: 'E-Mail ⬇️',
  [IntlKeys.messagesStatusAsc]: 'Status ⬆️',
  [IntlKeys.messagesStatusDesc]: 'Status ⬇️',
  [IntlKeys.messagesReviewedAtAsc]: 'Gesehen am ⬆️',
  [IntlKeys.messagesReviewedAtDesc]: 'Gesehen am ⬇️',
  [IntlKeys.messagesStatusNew]: 'Neue Nachrichten',
  [IntlKeys.messagesStatusRead]: 'Gelesene Nachrichten',
  [IntlKeys.messagesStatusArchived]: 'Archivierte Nachrichten',
  [IntlKeys.messagesMarkReviewed]: 'Als gelesen markieren',
  [IntlKeys.messagesUnmarkReviewed]: 'Als ungelesen markieren',
};
export default translations;

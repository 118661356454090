import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// components
import { PageContainer } from 'components/base';
import JobCard from 'components/JobCard';
import EmptyJobs from './components/EmptyJobs';
import JobsFilter from './components/JobsFilter';
//
import { fetchJobsWorker, updateJobWorkflowAction } from 'domain/jobs/actions';
//
import { useInfiniteData } from 'hooks/useInfiniteData';
import { JobsSortConfigs, JobFiltersFormValues } from 'types/job/entities';
import { theme } from 'theme';
import JobBoardColumn from './components/JobBoardColumn';
import { JobWorkflow } from 'types/job';
import { convertJobBasedOnStatus, DraggableCardInfo } from './helper';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';

interface OwnProps {
  companyId?: number;
}

const JobsList: React.FC<OwnProps> = ({ companyId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCompany, setSelectedCompany] = useState<number | null>(companyId || null);
  const dispatch = useAppDispatch();

  const filterConfig: JobFiltersFormValues = companyId
    ? {
        sort: 'jobCreatedAtDesc',
        filters: {
          status: searchParams.get('status') || 'published',
          companyId,
        },
      }
    : {
        sort: 'jobCreatedAtDesc',
        filters: {
          status: searchParams.get('status') || 'published',
        },
      };

  const {
    isLoading,
    isFetched,
    resources: jobResources,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchJobsWorker,
    initialSort: JobsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  const [data, setData] = useState(convertJobBasedOnStatus(jobResources));

  const setParams = (status: string) => {
    if (companyId) {
      setSearchParams({ tab: 'jobs', status });
    } else {
      setSearchParams({ status });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: JobFiltersFormValues) => {
      setSelectedCompany(companyId || null);
      const { sort, filters } = config;
      setSort(JobsSortConfigs[sort!]);
      if (filters.companyId) setSelectedCompany(companyId || (filters.companyId as number));

      setParams((filters.status as string) || '');
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [companyId],
  );

  useEffect(() => {
    if (jobResources) {
      setData(convertJobBasedOnStatus(jobResources));
    }
  }, [jobResources]);

  useEffect(() => {
    if (searchParams.get('status') === null) {
      handleFiltersChange({ ...filterConfig, filters: { ...filterConfig.filters } });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const cardChangeHandler = (cardInfo: DraggableCardInfo, newStatus: JobWorkflow['status']) => {
    const { id, status: oldStatus } = cardInfo;

    if (oldStatus === newStatus) {
      return;
    }

    const dropCard = data[oldStatus].find((el) => el.id === id);

    const tempGaveList = data[oldStatus]
      .filter((item) => item.id !== id)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });

    const tempRecievedList = [
      ...data[newStatus],
      {
        ...dropCard,
      },
    ].map((item) => {
      return { ...item };
    });

    // At last, set state
    setData((d) => {
      return { ...d, [oldStatus]: tempGaveList, [newStatus]: tempRecievedList };
    });

    dispatch(updateJobWorkflowAction({ params: { id: id }, status: newStatus }))
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((err) => {
        toast.error(err?.data?.response?.data?.message || 'Failed to move the card');
      });
  };

  return (
    <JobsPage>
      <JobsFilter
        withCompanySelect={companyId ? false : true}
        onChange={handleFiltersChange}
        config={filterConfig}
        selectedCompany={selectedCompany}
      />
      <WorkflowContainer>
        <JobBoardColumn
          list={data.todo}
          //
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          itemCount={pagination.total}
          perPage={pagination.perPage}
          //
          fetchNext={fetchNextPage}
          emptyState={<EmptyJobs text={'No jobs in Todo'} />}
          ListCard={(data) => <JobCard {...data} workflowStatus={'todo'} refetch={refetch} />}
          onChange={cardChangeHandler}
          title="Todo"
          status="todo"
        />
        <JobBoardColumn
          list={data.inprogress}
          //
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          itemCount={pagination.total}
          perPage={pagination.perPage}
          //
          fetchNext={fetchNextPage}
          emptyState={<EmptyJobs text={'No jobs in progress'} />}
          ListCard={(data) => <JobCard {...data} workflowStatus={'inprogress'} refetch={refetch} />}
          onChange={cardChangeHandler}
          title="In Progress"
          status="inprogress"
        />
        <JobBoardColumn
          list={data.done}
          //
          isLoading={isLoading}
          isLoaded={isFetched}
          page={pagination.page}
          pageCount={pagination.pageCount}
          itemCount={pagination.total}
          perPage={pagination.perPage}
          //
          fetchNext={fetchNextPage}
          emptyState={<EmptyJobs text={'No jobs are in Done'} />}
          ListCard={(data) => <JobCard {...data} workflowStatus={'done'} refetch={refetch} />}
          onChange={cardChangeHandler}
          title="Done"
          status="done"
        />
      </WorkflowContainer>
    </JobsPage>
  );
};

export default JobsList;

const JobsPage = styled(PageContainer)`
  background-color: ${theme.colors.lightGray};
  overflow: auto;
  min-width: 1000px;
`;

const WorkflowContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  width: 100%;
  overflow: auto;
`;

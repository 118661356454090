import { Card, FlexBox, Title3 } from 'components/base';
import Button, { SubmitButton } from 'components/base/Button';
import TextEditor from 'components/base/TextEditor';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { changeApplicationStatusAction, updateApplicationAction } from 'domain/applications/actions';
import { updateTalentAction } from 'domain/talents/actions';
import { useAppDispatch } from 'domain/store';
import { formatDate } from 'helpers/date';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import React, { MouseEvent, useCallback, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { BsChevronDown, BsFillPatchCheckFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { Application, ApplicationFile, ApplicationStatusEnum, ApplicationUpdatePayload } from 'types/applications';
import QualificationAnswers from './QualificationAnswers';
import { uploadFiles } from '../helpers/file-upload';
import { isEqual } from 'lodash';
import DocumentDropzone from 'components/Dropzone/DocumentDropzone';
import { MdDeleteForever } from 'react-icons/md';
import { Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps {
  data: unknown;
  refetchApplications?: () => void;
}

const ApplicationCard: React.FC<OwnProps> = ({ data, refetchApplications }) => {
  const { formatMessage } = useIntl();
  const application = data as Application;
  const [, copy] = useCopyToClipboard();
  const [isCardOpen, setIsCardOpen] = useState(false);
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      remarks: application.remarks || '',
      files: application.files || [],
      candidate: application.candidate,
    } as FieldValues,
  });

  const applicationFileObjs = useWatch({ name: 'applicationFileObj', control: form.control });
  const applicationFiles: ApplicationFile[] = useWatch({ name: 'files', control: form.control });

  const toggleCardState = () => {
    setIsCardOpen((isOpen) => !isOpen);
  };

  const updateCandidateInfo = async ({ id, socials }: { id: number; socials: Record<string, string> }) => {
    dispatch(
      updateTalentAction({
        params: {
          id: id,
        },
        ...application.candidate,
        socials,
      }),
    );
  };

  const onRemarksSubmit = useCallback(
    async (data: FieldValues) => {
      console.log(`files to upload: ${data.applicationFileObj}`);
      const [files] = await Promise.all([uploadFiles(data.applicationFileObj)]);

      // Update the talents details with socials update
      try {
        if (!isEqual(data.candidate?.socials, application.candidate?.socials)) {
          await updateCandidateInfo({ id: application.candidate.id, socials: data.candidate?.socials });
        }
      } catch (ignore) {}

      const payload = {
        remarks: data.remarks,
        files: files?.map((file) => ({ ...file })),
      } as ApplicationUpdatePayload;
      setTimeout(() => {
        dispatch(
          updateApplicationAction({
            params: {
              id: application.id,
            },
            ...payload,
          }),
        )
          .unwrap()
          .then(() => {
            refetchApplications?.();
            toast.success('Successfully updated the remarks');
          })
          .catch((err) => {
            toast.error(err?.data?.response?.data?.message || 'Falied to update the remarks');
          });
      }, 500);
    },
    [application.id, dispatch, refetchApplications],
  );

  const handlePresentUser = useCallback(
    (e: MouseEvent) => {
      e?.stopPropagation();
      e?.preventDefault();
      dispatch(
        changeApplicationStatusAction({ params: { id: application.id }, status: ApplicationStatusEnum.Presented }),
      )
        .unwrap()
        .then(() => {
          toast.success('Sucessfully presented the user');
          refetchApplications?.();
        });
    },
    [application.id, dispatch, refetchApplications],
  );

  const removeFile = useCallback(
    (fileUrl?: string) => {
      if (!fileUrl) return;
      const updatedFiles = applicationFiles.filter((file) => file.url !== fileUrl) || [];
      const newFileObjs = applicationFileObjs?.filter((obj: { src: string }) => obj.src !== fileUrl);

      if (!isEqual(newFileObjs, applicationFileObjs)) {
        form.setValue('applicationFileObj', newFileObjs);
      }

      if (updatedFiles.length !== applicationFiles?.length) {
        form.setValue('files', updatedFiles);
      }
    },
    [applicationFileObjs, applicationFiles, form],
  );

  const handleFileUpdate = useCallback(
    (updatefiles: File[]) => {
      console.log(`updated files: ${updatefiles}`);
      const fileObjects = [
        ...(form.getValues('applicationFileObj') || []),
        ...updatefiles.map((file) => ({
          fileObj: file,
          src: URL.createObjectURL(file),
          name: file.name,
        })),
      ];
      form.setValue('applicationFileObj', fileObjects);
      form.setValue(
        'applicationFileUrls',
        fileObjects.map((file) => file.src),
      );
    },
    [form],
  );

  return (
    <CardWrapper withBorder>
      <CardHead onClick={toggleCardState}>
        <EachDetails>
          <Value>{`${application.candidate.firstName} ${application.candidate.lastName}`}</Value>
          <Label>Name</Label>
        </EachDetails>
        <EachDetails>
          <Value onClick={() => copy(application.candidate.phone as string)}>{application.candidate.phone}</Value>
          <Label>{formatMessage({ id: IntlKeys.phone })}</Label>
        </EachDetails>
        <EachDetails>
          <Value onClick={() => copy(application.candidate.email as string)}>{application.candidate.email}</Value>
          <Label>Email</Label>
        </EachDetails>
        <EachDetails>
          <Value>{formatDate(application.createdAt)}</Value>
          <Label>Applied on</Label>
        </EachDetails>
        <EachDetails>
          <Value>{application.qualificationStatus}</Value>
          <Label>Qualification Status</Label>
        </EachDetails>
        <EachDetails>
          <Value status={application.status} className={'status'}>
            {application.status}
          </Value>
          <Label>Status</Label>
        </EachDetails>
        <BsChevronDown className={isCardOpen ? 'open' : 'close'} />
      </CardHead>
      {isCardOpen && (
        <CardBody>
          <BodySection>
            <Title3>Reamarks</Title3>
            <FormProvider {...form}>
              <Form onSubmit={form.handleSubmit(onRemarksSubmit)}>
                <TextEditor name="remarks" />
                <EachInput label="Linkedin">
                  <FormTextField name={'candidate.socials.linkedin'} placeholder="Enter LinkedIn" />
                </EachInput>
                <EachInput label="Xing">
                  <FormTextField name={'candidate.socials.xing'} placeholder="Enter Xing" />
                </EachInput>
                <EachInput label="Files">
                  <FilesContainer>
                    {[
                      ...(applicationFiles
                        ?.map((file) => {
                          return { url: file?.url, name: file?.url?.substring(file?.url?.lastIndexOf('/') + 1) };
                        })
                        .filter((file) => file.url) || []),
                      ...(applicationFileObjs?.map((file: { src: string; name: string }) => {
                        return { url: file.src, name: file.name };
                      }) || []),
                    ].map(({ url, name }: { url: string; name: string }, index: number) => (
                      <FileEachItem key={`${url}-${index}`}>
                        <RemoveContainer onClick={() => removeFile(url)} className={'remove-file'}>
                          <MdDeleteForever />
                        </RemoveContainer>
                        <Link href={url} target="_blank">
                          {name}
                        </Link>
                      </FileEachItem>
                    ))}
                  </FilesContainer>
                  <DocumentDropzone
                    onDrop={(acceptedFiles) => handleFileUpdate(acceptedFiles)}
                    maxFiles={5}
                    height={200}
                    multiple={true}
                  />
                </EachInput>
                <ButtonContainer align="center" gap={'1rem'}>
                  <SubmitButton>Save</SubmitButton>
                  <Button variant="outline" onClick={handlePresentUser} className={'present-button'}>
                    <BsFillPatchCheckFill /> Present User
                  </Button>
                </ButtonContainer>
              </Form>
            </FormProvider>
          </BodySection>
          <BodySection>
            <QualificationQuestionsSection>
              <Card withBorder bgColor={theme.colors.mintGreen}>
                <Title3>{'Qualification Questions'}</Title3>
                <QualificationAnswers
                  refetchApplications={refetchApplications}
                  questions={application?.job?.questions}
                  applicationId={application.id}
                  answers={application?.answers}
                />
              </Card>
            </QualificationQuestionsSection>
          </BodySection>
        </CardBody>
      )}
    </CardWrapper>
  );
};

export default ApplicationCard;

const FilesContainer = styled.div`
  display: flex;
  max-width: 100%;
  gap: 0.5rem;
`;

const FileEachItem = styled.div`
  height: 150px;
  background-color: ${theme.colors.lightGray};
  border: 1px solid ${theme.colors.gray};
  border-radius: 20px;
  min-width: 150px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .remove-file {
    display: none;
  }

  &:hover {
    .remove-file {
      display: flex;
    }
  }
`;
const RemoveContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 36px;
  height: 36px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

const CardWrapper = styled(Card)`
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0;
`;

const CardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  background-color: ${theme.colors.lightGray};

  > svg {
    transition: all linear 0.25s;
    &.open {
      transform: rotate(-90deg);
    }

    &.close {
      transform: rotate(0deg);
    }
  }
`;

const EachDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Label = styled.span`
  color: ${theme.colors.gray};
`;

const Value = styled.p`
  margin: 0rem 0rem 0.5rem;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;

  &.status {
    padding: 0.125rem 0.5rem;
    border-radius: 4px;
    text-transform: capitalize;
    font-size: 0.875rem;
  }

  ${(props: { status?: ApplicationStatusEnum }) =>
    props.status === 'applied' &&
    css`
      background-color: ${theme.colors.warning};
    `}
  ${(props: { status?: ApplicationStatusEnum }) =>
    props.status === 'presented' &&
    css`
      background-color: ${theme.colors.maroon};
      color: white;
    `}
  ${(props: { status?: ApplicationStatusEnum }) =>
    props.status === 'accepted' &&
    css`
      background-color: ${theme.colors.success};
      color: white;
    `}
  ${(props: { status?: ApplicationStatusEnum }) =>
    props.status === 'declined' &&
    css`
      background-color: ${theme.colors.error};
      color: white;
    `}
`;

const CardBody = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid ${theme.colors.stroke};
`;

const BodySection = styled.div`
  flex: 1;

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`;

const QualificationQuestionsSection = styled.div`
  flex: 1;
  width: 100%;

  h3 {
    font-size: 1.25rem;
  }
`;

const ButtonContainer = styled(FlexBox)`
  margin: 1rem;

  button.present-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    svg {
      fill: ${theme.colors.green};
    }
  }
`;

const Form = styled.form`
  button {
    margin-top: 1rem;
  }
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

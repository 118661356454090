import { Button } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { fetchTalentPoolsWorker } from 'domain/talents/actions';
import { formatDate } from 'helpers/date';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobType } from 'types/job';
import { TalentPoolType } from 'types/talent';
import {
  TalentFiltersFormValues,
  TalentPoolFiltersFormValues,
  TalentPoolsortConfigs,
  TalentSortConfigs,
} from 'types/talent/entities';
import AttachJobsToPools from '../pools/AttachJobsToPools';

interface OwnProps {
  job: JobType;
}

const ConnectedTalentPools: React.FC<OwnProps> = ({ job }) => {
  const { formatMessage } = useIntl();
  const [isAttachPoolsModalOpen, setisAttachPoolsModalOpen] = useState(false);

  const filterConfig: TalentPoolFiltersFormValues = {
    sort: 'nameAsc',
    filters: { jobId: job?.id || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talentPools,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'talentsList',
    //
    fetchResources: fetchTalentPoolsWorker,
    initialSort: TalentPoolsortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  const handelAttachPoolsModelClose = useCallback(() => {
    setisAttachPoolsModalOpen(false);
  }, []);

  const handelAttachPoolsModelOpen = useCallback(() => {
    setisAttachPoolsModalOpen(true);
  }, []);

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => row.name,
          id: 'name',
        },
        {
          Header: formatMessage({ id: 'IntlKeys.candidateCount' }),
          accessor: (row) => row.candidateCount,
          id: 'candidateCount',
        },
        {
          Header: formatMessage({ id: 'IntlKeys.createdAt' }),
          id: 'createdAt',
          accessor: (row) => (row.createdAt ? formatDate(row.createdAt) : ''),
        },
        {
          Header: formatMessage({ id: 'IntlKeys.createdBy' }),
          accessor: (row) => row.author?.fullName,
          id: 'createdBy',
        },
      ] as InfiniteColumnsType<TalentPoolType>,
    [formatMessage, talentPools],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <Container>
      <FilterContainer>
        <Button onClick={handelAttachPoolsModelOpen}>Attach Pool</Button>
      </FilterContainer>
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={talentPools}
        columns={columns}
      />

      <ModalDialog isOpen={isAttachPoolsModalOpen} handleClose={handelAttachPoolsModelClose}>
        <AttachJobsToPools jobIds={[job?.id]} refetchPools={refetch} handleClose={handelAttachPoolsModelClose} />
      </ModalDialog>
    </Container>
  );
};

export default ConnectedTalentPools;

const Anchor = styled.a`
  margin: 0.5rem;
`;

const Container = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  svg {
    cursor: pointer;
    fill: ${theme.colors.red};
  }
  button {
    padding: 0.25rem 1rem;
    box-sizing: border-box;
    font-size: 0.85rem;
  }
`;

const FilterContainer = styled.div`
  padding: 1rem;
  justify-content: flex-end;
  width: 100%;
  display: flex;
`;

import { Checkbox, Popover, Tooltip } from '@mui/material';
import { PageContainer } from 'components/base';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { deleteTalentAction, fetchTalentsWorker, updateTalentStatusAction } from 'domain/talents/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { ImXing } from 'react-icons/im';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { TalentFiltersFormValues, TalentSortConfigs } from 'types/talent/entities';
import { TalentStatusEnum, TalentType } from 'types/talent';
import TalentPoolFilters from './components/TalentFilters';
import { BsGlobe, BsLinkedin } from 'react-icons/bs';
import styled from 'styled-components';
import ConfirmDialog from 'components/base/ConfirmDialog';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import ModalDialog from 'components/base/ModalDialog';
import { theme } from 'theme';
import AttachTalentsToPools from './components/AttachTalentsToPools';
import AttachJobsToTalents from './components/AttachJobsToTalents';
import { SubmitButton } from 'components/base/Button';
import PopoverMenu from './components/PopoverMenu';
import CreateOrEditTalent from './components/CreateOrEditTalent';

const Talents = () => {
  const [searchParams] = useSearchParams();
  const [checkedRows, setCheckedRows] = useState<Array<number>>([]);
  const [isCheckedAllTalents, setIsCheckedAllTalents] = useState<boolean>(false);
  const [isAttachTalentModalOpen, setIsAttachTalentModalOpen] = useState(false);
  const [isAttachJobModalOpen, setIsAttachJobModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState<'delete' | 'edit' | null>(null);
  const [currentTalent, setCurrentTalent] = useState<null | number>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const filterConfig: TalentFiltersFormValues = {
    sort: 'nameAsc',
    filters: { status: searchParams.get('status') || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talents,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'talentsList',
    //
    fetchResources: fetchTalentsWorker,
    initialSort: TalentSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: TalentFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(TalentSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const handleCheckTalent = useCallback(
    (value: number) => {
      if (checkedRows.includes(value)) {
        setCheckedRows(checkedRows.filter((i) => i !== value));
        setIsCheckedAllTalents(false);
      } else {
        setCheckedRows([...checkedRows, value]);
        if (checkedRows.length + 1 === talents.length) {
          setIsCheckedAllTalents(true);
        }
      }
    },
    [checkedRows, talents.length],
  );

  const handelAttachTalentsModelOpen = useCallback(() => {
    setIsAttachTalentModalOpen(true);
  }, []);

  const handelAttachTalentsModelClose = useCallback(() => {
    setIsAttachTalentModalOpen(false);
  }, []);

  const handelAttachJobsModelOpen = useCallback(() => {
    setIsAttachJobModalOpen(true);
  }, []);

  const handelAttachJobsModelClose = useCallback(() => {
    setIsAttachJobModalOpen(false);
  }, []);

  const handleDeleteConfirmModalOpen = useCallback(() => {
    setModalOpen('delete');
  }, []);

  const handleOpenStatusModal = useCallback((e: React.MouseEvent<HTMLDivElement>, talentId: number) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setAnchorEl(e.currentTarget);
    setCurrentTalent(talentId);
  }, []);

  const handelOpenEditModal = useCallback((talentId: number) => {
    setCurrentTalent(talentId);
    setModalOpen('edit');
  }, []);

  const closeModals = useCallback(() => {
    setModalOpen(null);
    setCurrentTalent(null);
    setAnchorEl(null);
  }, []);

  const confirmDeleteSelectedPools = useCallback(async () => {
    setIsDeleteInProgress(true);
    await Promise.all(checkedRows.map((talentId) => dispatch(deleteTalentAction({ params: { id: talentId } }))))
      .finally(() => {
        setIsDeleteInProgress(false);
      })
      .then(() => {
        setCheckedRows([]);
        closeModals();
        refetch?.();
        toast.success('Successfully deleted the selected talents!');
      });
  }, [checkedRows, closeModals, dispatch, refetch]);

  const onStatusChange = useCallback(
    (status: TalentStatusEnum) => {
      if (currentTalent) {
        const action = updateTalentStatusAction({ params: { id: currentTalent }, status });
        dispatch(action)
          .unwrap()
          .then(() => {
            toast.success('status updated');
            refetch?.();
          })
          .catch(() => toast.error('failed to update status'));
      }
    },
    [currentTalent, dispatch, refetch],
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <Checkbox
              style={{ marginTop: '-10px' }}
              checked={isCheckedAllTalents}
              onChange={() => {
                setIsCheckedAllTalents(!isCheckedAllTalents);
                isCheckedAllTalents ? setCheckedRows([]) : setCheckedRows(talents.map((talent) => talent.id));
              }}
            />
          ),
          accessor: (row) => row.id,
          Cell: ({ value }) => (
            <Checkbox checked={checkedRows.includes(value)} onChange={() => handleCheckTalent(value)} />
          ),
          style: {
            maxWidth: 42,
            display: 'flex',
            alignItems: 'center',
          },
          id: 'select',
        },
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => `${row.firstName} ${row.lastName}`,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.email }),
          accessor: (row) => row.email,
          id: 'email',
        },
        {
          Header: formatMessage({ id: IntlKeys.position }),
          id: 'position',
          accessor: (row) => row.position,
        },
        {
          Header: formatMessage({ id: IntlKeys.phone }),
          accessor: (row) => row.phone,
          id: 'phone',
        },
        {
          Header: formatMessage({ id: IntlKeys.socials }),
          accessor: (row) => (
            <>
              {row?.socials
                ? Object.entries(row?.socials).map(([key, value]) =>
                    value ? (
                      <Anchor key={key} href={value} target="_blank">
                        {key === 'linkedin' ? <BsLinkedin /> : key === 'xing' ? <ImXing /> : <BsGlobe />}
                      </Anchor>
                    ) : null,
                  )
                : null}
            </>
          ),
          id: 'socials',
        },
        {
          Header: formatMessage({ id: IntlKeys.talentStatus }),
          accessor: (row) => (
            <Tooltip title="Click to change status" onClick={(e) => handleOpenStatusModal(e, row.id)}>
              <Status status={row.status}>{row.status}</Status>
            </Tooltip>
          ),
        },
        {
          Header: '',
          accessor: (row) => <PopoverMenu talent={row} setCurrentlyEdittingTalent={handelOpenEditModal} />,
          id: 'popover-menu',
          width: 32,
        },
      ] as InfiniteColumnsType<TalentType>,
    [
      isCheckedAllTalents,
      formatMessage,
      talents,
      checkedRows,
      handleCheckTalent,
      handleOpenStatusModal,
      handelOpenEditModal,
    ],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  const statusOptions = [
    {
      label: 'Applicant',
      value: TalentStatusEnum.Applicant,
    },
    {
      label: 'Applied',
      value: TalentStatusEnum.Applied,
    },
    {
      label: 'Contacted',
      value: TalentStatusEnum.Contacted,
    },
    {
      label: 'Interested',
      value: TalentStatusEnum.Interested,
    },
    {
      label: 'Lead',
      value: TalentStatusEnum.Lead,
    },
    {
      label: 'Not interested',
      value: TalentStatusEnum.NotInterested,
    },
    {
      label: 'Hired',
      value: TalentStatusEnum.Working,
    },
  ];

  return (
    <PageContainer>
      <TalentPoolFilters config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={talents}
        columns={columns}
      />

      {!!checkedRows?.length && (
        <FloatingActions>
          <AttachTalentsButton onClick={handelAttachTalentsModelOpen}>Attach to Pools</AttachTalentsButton>
          <AttachJobButton onClick={handelAttachJobsModelOpen}>Attach to Job</AttachJobButton>
          <DeletePoolsButton onClick={handleDeleteConfirmModalOpen}>Delete Selected</DeletePoolsButton>
        </FloatingActions>
      )}

      <ConfirmDialog
        isOpen={modalOpen === 'delete'}
        onConfirm={confirmDeleteSelectedPools}
        onCancel={closeModals}
        isLoading={isDeleteInProgress}
        title={`Are you sure wanted to delete the selected pools?`}
        description="Deleting pools is a permanant action. This can not be undone. Deleting pools will not delete the talents and jobs associated with it."
      ></ConfirmDialog>
      <ModalDialog isOpen={isAttachTalentModalOpen} handleClose={handelAttachTalentsModelClose}>
        <AttachTalentsToPools
          candidatesIds={checkedRows}
          refetchTalents={refetch}
          handleClose={handelAttachTalentsModelClose}
        />
      </ModalDialog>

      <ModalDialog isOpen={isAttachJobModalOpen} handleClose={handelAttachJobsModelClose}>
        <AttachJobsToTalents
          candidateIds={checkedRows}
          refetchPools={refetch}
          handleClose={handelAttachJobsModelClose}
        />
      </ModalDialog>

      <ModalDialog isOpen={modalOpen === 'edit'} handleClose={closeModals}>
        <CreateOrEditTalent refetch={refetch} closeModal={closeModals} talentId={currentTalent} />
      </ModalDialog>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeModals}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {statusOptions.map((option) => (
          <ListItem key={option.value} onClick={() => onStatusChange(option.value)}>
            {option.label}
          </ListItem>
        ))}
      </Popover>
    </PageContainer>
  );
};

export default Talents;

const Anchor = styled.a`
  margin: 0.5rem;
`;

const FloatingActions = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-top: 1px solid ${theme.colors.stroke};
  background: ${theme.colors.mintGreen};
  gap: 1rem;
  z-index: 2;
`;

const ListAction = styled(SubmitButton)`
  border-radius: 5px;
  transition: all linear 0.25s;

  &:hover {
    border-radius: 50px;
  }
`;

const AttachTalentsButton = styled(ListAction)``;

const DeletePoolsButton = styled(ListAction)`
  background-color: ${theme.colors.red};
  border-color: ${theme.colors.red};
`;

const AttachJobButton = styled(ListAction)`
  background-color: ${theme.colors.warning};
  border-color: ${theme.colors.warning};
`;

const ListItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

const Status = styled.div`
  background-color: ${(props: { status: TalentStatusEnum }) =>
    props.status === TalentStatusEnum.NotInterested ? theme.colors.red : theme.colors.primary};
  color: ${theme.colors.white};
  border: ${(props: { status: TalentStatusEnum }) =>
    props.status === TalentStatusEnum.NotInterested ? '1px solid ' + theme.colors.red : 'none'};
  border-radius: 4px;
  padding: 0.25rem 1rem;
  width: fit-content;
  font-weight: 500;
  font-size: 0.875rem;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`;

import { AdminRolesEnum } from 'types/admins';

const readPermissions = [
  'read:admins',
  'read:benefits',
  'read:companies',
  'read:demo',
  'read:invoices',
  'read:jobs',
  'read:talents',
];
export const getAdminPermissions = (role: AdminRolesEnum): Array<string> => {
  switch (role) {
    case AdminRolesEnum.Admin:
      return [...readPermissions];
    case AdminRolesEnum.Recruiter:
      return ['read:benefits', 'read:companies', 'read:jobs', 'read:talents'];
    case AdminRolesEnum.Sales:
      return ['read:demo'];
    case AdminRolesEnum.User:
      return [...readPermissions];
    default:
      return [];
  }
};

export const checkPermissions = (allowedPermissions: Array<string>, role?: AdminRolesEnum): boolean => {
  if (allowedPermissions.length === 0) return true;
  if (!role) return false;
  const adminPermission = getAdminPermissions(role);
  return adminPermission.some((permission) => allowedPermissions.includes(permission));
};

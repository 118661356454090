export const colors = {
  primary: '#3B42F2',
  secondary: '#050622',
  link: 'rgb(29, 78, 216)',
  white: '#FFF',
  dark: '#000D40',
  lightBlue: '#F7F8FF',
  stroke: '#E9EEF2',
  gray: '#61616C',
  lightGray: '#f8f8f8',
  disabledGray: '#D1D6EA',
  red: '#EE1E5C',
  error: '#d32f2f',
  warning: '#FFB432',
  success: '#25d02e',
  black: '#000',
  lightYellow: '#FFF9EF',
  green: '#007070',
  mintGreen: '#B0FF77',
  maroon: '#700050',
  randomColor: '#' + (((1 << 24) * Math.random()) | 0).toString(16),
};

const fonts = {
  montreal: 'NeueMontreal',
  victorSerif: 'VictorSerifSmooth',
};

const breakpoints = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const theme = {
  breakpoints,
  colors,
  fonts,
  dimensions: {
    headerHeight: 80,
    filterBarHeight: 80,
    sidebarWidth: 180,
    //
    pagePadding: '32px 40px',
    pagePaddingX: 40,
    pagePaddingY: 32,
    //
  },
};

import React from 'react';
// components
import { Radio } from '@mui/material';
import ModalDialog from 'components/base/ModalDialog';
// assets
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import styled from 'styled-components';
import { theme } from 'theme';
import { BiMicrophone, BiVideo } from 'react-icons/bi';
import { IoSettingsOutline } from 'react-icons/io5';

type OwnProps = {
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  videoDevices: MediaDeviceInfo[];
  audioDevices: MediaDeviceInfo[];
  currentVideoDevice: MediaDeviceInfo | null;
  currentAudioDevice: MediaDeviceInfo | null;
  handleVideoEnable: () => void;
  handleAudioEnable: () => void;
  handleVideoDisable: () => void;
  handleAudioDisable: () => void;
  handleVideoDeviceChange: (device: MediaDeviceInfo) => void;
  handleAudioDeviceChange: (device: MediaDeviceInfo) => void;
};

const DevicesSettingsPanel = ({
  videoDevices,
  audioDevices,
  currentVideoDevice,
  currentAudioDevice,
  handleVideoDeviceChange,
  handleAudioDeviceChange,
}: OwnProps) => {
  const [isDevicesSettingsModalOpen, setIsDevicesSettingsModalOpen] = React.useState(false);
  const { formatMessage } = useIntl();

  return (
    <PanelWrapper>
      {/*
       * We can not complete the pitch if the user disables the camera or microphone
       * For that reason, it doesn't makes sense to add these buttons at the momenet
       */}
      {/* <div className={classes.subSettingsWrapper}>
        <div
          className={cx(classes.circleSettingItem, { [classes.disabledSettingsItem]: !isVideoEnabled })}
          onClick={isVideoEnabled ? handleVideoDisable : handleVideoEnable}
        >
          {isVideoEnabled ? <VideoIcon /> : <VideoDisabledIcon />}
        </div>

        <div
          className={cx(classes.circleSettingItem, { [classes.disabledSettingsItem]: !isAudioEnabled })}
          onClick={isAudioEnabled ? handleAudioDisable : handleAudioEnable}
        >
          {isAudioEnabled ? <MicrophoneIcon /> : <MicrophoneDisabledIcon />}
        </div>
      </div> */}

      <SubSettingsWrapper>
        <CircleSettingItem onClick={() => setIsDevicesSettingsModalOpen(true)}>
          <IoSettingsOutline />
        </CircleSettingItem>

        {isDevicesSettingsModalOpen && (
          <ModalDialog
            isOpen
            handleClose={() => setIsDevicesSettingsModalOpen(false)}
            title={formatMessage({ id: 'Select the device' })}
          >
            <DevicesModalContentContainer>
              <SubDevicesSectionWrapper>
                <SubDevicesSettingsTitleWrapper>
                  <BiVideo /> {formatMessage({ id: 'Select your camera' })}
                </SubDevicesSettingsTitleWrapper>

                {videoDevices.map((item) => {
                  return (
                    <DeviceWrapper key={item.deviceId} onClick={() => handleVideoDeviceChange(item)}>
                      <Radio value={item.label} checked={item.deviceId === currentVideoDevice?.deviceId} />
                      <span>{item.label}</span>
                    </DeviceWrapper>
                  );
                })}
              </SubDevicesSectionWrapper>

              <SubDevicesSectionWrapper>
                <SubDevicesSettingsTitleWrapper>
                  <BiMicrophone /> {formatMessage({ id: 'Select microphone' })}
                </SubDevicesSettingsTitleWrapper>

                {audioDevices.map((item) => {
                  return (
                    <DeviceWrapper key={item.deviceId} onClick={() => handleAudioDeviceChange(item)}>
                      <Radio value={item.label} checked={item.deviceId === currentAudioDevice?.deviceId} />
                      <span>{item.label}</span>
                    </DeviceWrapper>
                  );
                })}
              </SubDevicesSectionWrapper>
            </DevicesModalContentContainer>
          </ModalDialog>
        )}
      </SubSettingsWrapper>
    </PanelWrapper>
  );
};

const PanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CircleSettingItem = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.stroke};
  margin-left: 0.5rem;
  border-radius: 50%;
  z-index: 10;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    display: flex;
    justify-content: space-around;
  }
`;

const SubSettingsWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const DevicesModalContentContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const SubDevicesSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubDevicesSettingsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: ${theme.colors.primary};

  path {
    fill: ${theme.colors.primary};
  }
`;

const DeviceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${theme.colors.primary};
  cursor: pointer;
`;

export default DevicesSettingsPanel;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkErrorType, Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { BenefitList, BenefitPayload, BenefitsPayload, BenefitType } from 'types/benefit';

export const fetchBenefitsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<BenefitList>({
    api: restClient.getBenefits,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getBenefitsAction = createAsyncThunk<BenefitList, Params | undefined>(
  'benefits/getBenefitsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchBenefitsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBenefitDetailsAction = createAsyncThunk<BenefitType, Params>(
  'benefits/getBenefitDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BenefitType>({
      api: restClient.getBenefitDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createBenefitAction = createAsyncThunk<BenefitType, BenefitPayload>(
  'benefits/createBenefitAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BenefitType>({
      api: restClient.createBenefit,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createBenefitsAction = createAsyncThunk<BenefitType, BenefitsPayload>(
  'benefits/createBenefitsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BenefitType>({
      api: restClient.createBenefits,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateBenefitAction = createAsyncThunk<BenefitType, BenefitPayload | Params>(
  'benefits/updateBenefitAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<BenefitType>({
      api: restClient.updateBenefitDetails,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const deleteBenefitAction = createAsyncThunk<void, Params>(
  'benefits/deleteBenefitAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.deleteBenefitDetails,
    });

    try {
      await callApi(requestPayload);
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

import { Button } from '@mui/material';
import { FlexBox, SubmitButton, TextEditor } from 'components/base';
import { createApplicationAnswersAction } from 'domain/applications/actions';
import { useAppDispatch } from 'domain/store';
import { getNumberToCharacter } from 'helpers/string';
import { IntlKeys } from 'localization';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { QualificationQuestionsType, QualificationAnswer } from 'types/job';

interface OwnProps {
  questions: QualificationQuestionsType[];
  applicationId: number;
  answers?: QualificationAnswer[];
  refetchApplications?: () => void;
}

const QualificationAnswers: React.FC<OwnProps> = ({ questions, applicationId, answers, refetchApplications }) => {
  const { formatMessage } = useIntl();
  const jobForm = useForm<{ answer: string }>({ defaultValues: {} });
  const dispatch = useAppDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState<number[]>([]);

  const setThisAnswer = (question: QualificationQuestionsType, index: number) => {
    const answer = currentAnswer.length === 0 ? (answersMap?.[question.id]?.answer as number[]) || [] : currentAnswer;
    const newAnswer = answer.includes(index) ? answer.filter((e) => e !== index) : [...answer, index];
    setCurrentAnswer(newAnswer);
  };

  const setThisQuestion = (question: QualificationQuestionsType, index: number) => {
    if (question.type !== 'short_answer') {
      setCurrentAnswer((answersMap?.[question.id]?.answer as number[]) || []);
    } else {
      setCurrentAnswer([]);
    }
    setCurrentQuestion(index);
  };
  const updateAnswer = (question: QualificationQuestionsType, textAnswer?: string) => {
    const answer = textAnswer ? textAnswer : currentAnswer;
    dispatch(createApplicationAnswersAction({ params: { id: applicationId }, answer, questionId: question.id })).then(
      () => {
        if (refetchApplications) refetchApplications();
      },
    );
  };

  const answersMap =
    answers?.reduce(
      (acc, answer: QualificationAnswer) => ({ ...acc, [answer.questionId]: answer }),
      {} as Record<string | number, QualificationAnswer>,
    ) || [];

  const Answers = ({ question }: { question: QualificationQuestionsType }) => {
    const { type, choices } = question;
    const onJobFormSubmit = useCallback(
      async (data: { answer: string }) => {
        dispatch(
          createApplicationAnswersAction({ params: { id: applicationId }, ...data, questionId: question.id }),
        ).then(() => {
          if (refetchApplications) refetchApplications();
        });
      },
      [question.id],
    );
    switch (type) {
      case 'short_answer':
        return (
          <AnswersContainer>
            <FormProvider {...jobForm}>
              <form onSubmit={jobForm.handleSubmit(onJobFormSubmit)}>
                <EditorContainer>
                  <TextAnswer name="answer" initialValue={(answersMap?.[question.id]?.answer as string) || ''} />
                </EditorContainer>
                <ButtonContainer>
                  <SubmitButton>Submit</SubmitButton>
                </ButtonContainer>
              </form>
            </FormProvider>
          </AnswersContainer>
        );
      case 'choice':
        return (
          <AnswersContainer>
            <ChoiceOptions>
              {choices?.map((option, index) => (
                <OptionWrapper key={`option_${question.id}_${option}`} align="center">
                  <Option
                    selected={
                      currentAnswer.length > 0
                        ? currentAnswer.includes(index)
                        : (answersMap?.[question.id]?.answer as number[])?.includes(index)
                    }
                    onClick={() => setThisAnswer(question, index)}
                  >
                    <OptionLabel
                      selected={
                        currentAnswer.length > 0
                          ? currentAnswer.includes(index)
                          : (answersMap?.[question.id]?.answer as number[])?.includes(index)
                      }
                    >
                      {getNumberToCharacter(index + 1)}
                    </OptionLabel>
                    <OptionText>{option}</OptionText>
                  </Option>
                </OptionWrapper>
              ))}
            </ChoiceOptions>
            <ButtonContainer>
              <Button variant="contained" onClick={() => updateAnswer(question)}>
                Submit
              </Button>
            </ButtonContainer>
          </AnswersContainer>
        );
      case 'yes_no':
        return (
          <AnswersContainer>
            <YesOrNoWrapper>
              <Option
                selected={
                  currentAnswer.length > 0
                    ? currentAnswer.includes(0)
                    : (answersMap?.[question.id]?.answer as number[])?.includes(0)
                }
                onClick={() => setCurrentAnswer([0])}
              >
                <OptionLabel
                  selected={
                    currentAnswer.length > 0
                      ? currentAnswer.includes(0)
                      : (answersMap?.[question.id]?.answer as number[])?.includes(0)
                  }
                >
                  A
                </OptionLabel>
                {formatMessage({ id: IntlKeys.yes })}
              </Option>
              <Option
                selected={
                  currentAnswer.length > 0
                    ? currentAnswer.includes(1)
                    : (answersMap?.[question.id]?.answer as number[])?.includes(1)
                }
                onClick={() => setCurrentAnswer([1])}
              >
                <OptionLabel
                  selected={
                    currentAnswer.length > 0
                      ? currentAnswer.includes(1)
                      : (answersMap?.[question.id]?.answer as number[])?.includes(1)
                  }
                >
                  B
                </OptionLabel>
                {formatMessage({ id: IntlKeys.no })}
              </Option>
            </YesOrNoWrapper>
            <ButtonContainer>
              <Button variant="contained" onClick={() => updateAnswer(question)}>
                Submit
              </Button>
            </ButtonContainer>
          </AnswersContainer>
        );
    }
  };

  return (
    <Container>
      <>
        <QuestionsSection>
          {questions?.map((question, index) => (
            <div key={`question_${question.id}`}>
              {currentQuestion === index ? (
                <QuestionContainer>
                  <Question>{question.question}</Question>
                  <Answers question={question} />
                  {!answersMap?.[question.id]?.answer || !answersMap?.[question.id]?.answer.length ? (
                    <ErrorText>{formatMessage({ id: 'No Answers yet' })}</ErrorText>
                  ) : null}
                </QuestionContainer>
              ) : null}
            </div>
          ))}
          <Dots>
            {questions?.map((question, index) => (
              <Bullet
                key={`dots_${question.id}`}
                active={currentQuestion === index}
                onClick={() => setThisQuestion(question, index)}
              />
            ))}
          </Dots>
        </QuestionsSection>
      </>
    </Container>
  );
};

export default QualificationAnswers;

interface OptionProps {
  selected?: boolean;
}

const ButtonContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
`;
const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  overflow: auto;
`;

const QuestionContainer = styled.div`
  padding-bottom: 2rem;
  height: 435px;
  overflow: auto;
`;

const Question = styled.span`
  font-size: 1.25rem;
  font-weight: 530;
`;

const ChoiceOptions = styled.div``;

const OptionWrapper = styled(FlexBox)`
  margin: 1rem 0rem;
`;

const Option = styled(FlexBox)`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  align-items: center;
  flex: 1;
  cursor: pointer;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}
`;

const OptionLabel = styled.div`
  font-weight: 600;
  color: ${theme.colors.secondary};
  background: ${theme.colors.lightGray};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 4px;

  ${(props: OptionProps) =>
    props.selected &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}
`;

const OptionText = styled.span``;

const AnswersContainer = styled.div``;

const YesOrNoWrapper = styled(FlexBox)`
  margin: 2rem 0;
  gap: 1rem;
`;

const EditorContainer = styled.div`
  margin-top: 1rem;

  ul {
    padding-left: 0;
  }
`;

const QuestionsSection = styled.div``;

const Dots = styled(FlexBox)`
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props: { active?: boolean }) => (props.active ? theme.colors.primary : theme.colors.gray)};
  transform: ${(props: { active?: boolean }) => (props.active ? 'scale(1.1)' : 'none')}; ;
`;

const TextAnswer = styled(TextEditor)`
  background-color: ${theme.colors.white};
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  min-height: 100px;

  * {
    border: none !important;
    background-color: transparent !important;
  }
`;

const ErrorText = styled.p`
  color: ${theme.colors.red};
  font-weight: 530;
`;

import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Wrapper } from 'components/Layout';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import Button, { SubmitButton } from 'components/base/Button';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { TalentPayload, TalentType } from 'types/talent';
import { createTalentAction, getTalentDetailsAction, updateTalentAction } from 'domain/talents/actions';
import { MediaFileObj, uploadMedia } from '../helpers';
import ImageDropzone from 'components/Dropzone/ImageDropzone';

interface OwnProps {
  refetch?: () => void;
  closeModal: () => void;
  talentId?: number | null;
  jobId?: number | null;
}

const CreateOrEditTalent: React.FC<OwnProps> = ({ closeModal, refetch, talentId, jobId }) => {
  const dispatch = useAppDispatch();
  const form = useForm<TalentPayload & { avatarObj?: MediaFileObj }>({ defaultValues: {} });
  const avatarObj = useWatch({ name: 'avatarObj', control: form.control });
  const uploadedAvatar = useWatch({ name: 'avatar', control: form.control });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (talentId) {
      dispatch(getTalentDetailsAction({ params: { id: talentId } }))
        .unwrap()
        .then((response: TalentType) => {
          form.reset({ ...response });
        });
    }
  }, [dispatch, form, talentId]);

  const onTalentCreationFormSubmit = useCallback(
    async (data: TalentPayload & { avatarObj?: MediaFileObj }) => {
      setIsSubmitting(true);
      const createPayload = jobId ? { ...data, jobId } : data;
      const { avatarObj, ...formValues } = createPayload;
      const [avatar] = await Promise.all([uploadMedia(avatarObj, data.avatar)]);
      const action = talentId
        ? updateTalentAction({ params: { id: talentId }, ...formValues, avatar })
        : createTalentAction({ ...formValues, avatar });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success(talentId ? 'Talent details updated successfully' : 'Talent created successfully');
          closeModal?.();
          refetch?.();
          setIsSubmitting(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          toast.error(
            err?.data?.response?.data?.message ||
              `Failed to ${talentId ? 'update' : 'create'} talent! please try again later`,
          );
        });
    },
    [closeModal, dispatch, jobId, refetch, talentId],
  );

  const handleAvatarUpload = useCallback(
    (avatarObj: MediaFileObj) => {
      form.setValue('avatarObj', avatarObj);
    },
    [form],
  );

  const removeAvatar = () => {
    form.setValue('avatarObj', undefined);
    form.setValue('avatar', undefined);
  };

  return (
    <CreateEditTalentForm>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onTalentCreationFormSubmit)}>
          <EachInput label={formatMessage({ id: 'Avatar' })}>
            <ImageDropzone
              onDrop={(acceptedFiles) =>
                handleAvatarUpload({
                  fileObj: acceptedFiles[0],
                  src: URL.createObjectURL(acceptedFiles[0]),
                  name: acceptedFiles[0].name,
                })
              }
              maxFiles={1}
              height={200}
              src={uploadedAvatar?.url || avatarObj?.src}
              multiple={false}
              previewClassName={'avatar-preview'}
              onRemove={removeAvatar}
              cropConfig={{ aspectRatio: 1 }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'First Name' })}>
            <FormTextField
              name={'firstName'}
              placeholder={formatMessage({ id: 'Enter first name' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.name })}>
            <FormTextField
              name={'lastName'}
              placeholder={formatMessage({ id: IntlKeys.namePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.email })}>
            <FormTextField name={'email'} placeholder={formatMessage({ id: 'Enter Email' })} type="email" />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.phone })}>
            <FormTextField name={'phone'} placeholder={formatMessage({ id: 'Enter phone number' })} />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.position })}>
            <FormTextField name={'position'} placeholder={formatMessage({ id: 'Enter position' })} />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.linkedin })}>
            <FormTextField name={'socials.linkedin'} placeholder={formatMessage({ id: IntlKeys.linkedinPlaceholder })} />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.xing })}>
            <FormTextField name={'socials.xing'} placeholder={formatMessage({ id: IntlKeys.xingPlaceholder })} />
          </EachInput>
          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              Submit
            </SubmitButton>
            <Button variant="outline" onClick={closeModal}>
              Cancel
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </CreateEditTalentForm>
  );
};

export default CreateOrEditTalent;
const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;

const CreateEditTalentForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
  .avatar-preview {
    border-radius: 20px;
    max-width: 260px;
    width: auto;
    img {
      border-radius: 20px;
      max-width: 100%;
    }
  }
`;

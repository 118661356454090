import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from '@reduxjs/toolkit';
import { useInfiniteQuery } from 'react-query';
import { last } from 'lodash';
// utils
import { ResourcesResponse } from 'helpers/http';
import { omitNullable } from 'helpers/object';

type UseInfiniteDataConfig<T, S, F> = {
  queryKey: string;
  //
  fetchResources: (requestPayload: Record<string, unknown>) => Promise<ResourcesResponse<T>>;
  isStaleGlobal?: boolean;
  setStaleGlobalAction?: (newStale: boolean) => Action;
  //
  perPage?: number;
  initialSort: S;
  initialFilters?: { search?: string } & F;
  params?: Record<string, string | number>;
};

export interface Pagination {
  page: number;
  perPage: number;
  pageCount: number;
  total: number;
}

export const useInfiniteData = <T, S, F>(config: UseInfiniteDataConfig<T, S, F>) => {
  const {
    queryKey,
    //
    fetchResources,
    perPage = 10,
    initialSort,
    initialFilters = {} as NonNullable<typeof config['initialFilters']>,
    //
    isStaleGlobal,
    setStaleGlobalAction,
    params,
  } = config;
  const dispatch = useDispatch();
  //
  const [sort, setSort] = useState(initialSort);
  const [filters, _setFilters] = useState(initialFilters);

  const setFilters = useCallback<typeof _setFilters>((value) => {
    const ensureSearchLength = (search?: string) => (search && search.length > 2 ? search : undefined);

    _setFilters(
      value instanceof Function
        ? (prevVal) => {
            const newVal = value(prevVal);

            return {
              ...newVal,
              search: ensureSearchLength(newVal.search),
            };
          }
        : {
            ...value,
            search: ensureSearchLength(value.search),
          },
    );
  }, []);

  const nonEmptyFilters = omitNullable(filters);
  const requestPayload = {
    sort: sort,
    filters: nonEmptyFilters,
  };

  const { data, isFetching, isFetched, refetch, isRefetching, ...queryResult } = useInfiniteQuery(
    [queryKey, requestPayload],
    async ({ pageParam }) => {
      const { sort, filters } = requestPayload;

      return await fetchResources({
        filters,
        sort,
        pagination: {
          perPage,
          page: pageParam,
        },
        params: { ...params },
      });
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (isStaleGlobal && setStaleGlobalAction) {
          dispatch(setStaleGlobalAction(false));
        }
      },
      getNextPageParam: ({ page, pageCount }) => {
        return page < pageCount ? page + 1 : undefined;
      },
    },
  );

  const { resources, pagination } = useMemo(() => {
    if (!data) {
      return {
        resources: [],
        pagination: {
          page: 1,
          perPage,
          pageCount: 0,
          total: 0,
        },
      };
    }

    const { data: _, ...lastPagePagination } = last(data.pages)!;

    return {
      pagination: lastPagePagination,
      resources: data.pages.reduce<T[]>((acc, { data }) => {
        acc.push(...data);
        return acc;
      }, []),
    };
  }, [data, perPage]);

  useEffect(() => {
    if (isFetched && isStaleGlobal && !isFetching) {
      refetch();
    }
  }, [isFetching, isStaleGlobal, isFetched, refetch]);

  return {
    ...queryResult,
    isLoading: queryResult.isLoading || isFetching || isRefetching,
    isFetching,
    isFetched,
    refetch,
    data,
    resources,
    sort,
    setSort,
    filters,
    setFilters,
    pagination,
  };
};

import { uploadFile } from 'helpers/uploadFile';
import { JobMediaUploadedFileObj } from 'pages/createNewJob/helpers/customization';
import { FileType } from 'types/common';

export const uploadCustomVideo = async (
  jobCustomVideoObj: JobMediaUploadedFileObj,
  defaultVideo: FileType | undefined,
) => {
  const { fileObj, ...customVideoRest } = jobCustomVideoObj || {};

  console.log('fileObj', fileObj);

  //
  return fileObj
    ? await new Promise<FileType>(async (resolve) => {
        const formData = new FormData();
        formData.append('file', fileObj, jobCustomVideoObj.name);
        const response = (await uploadFile(formData)) as FileType;

        resolve(response);
      })
    : defaultVideo;
};

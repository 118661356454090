import React from 'react';
import { MdDeleteForever } from 'react-icons/md';
import styled from 'styled-components';
import { theme } from 'theme';
import Dropzone, { CustomDropzoneProps } from '.';
import { videoMimeTypesArray } from './entities';

interface OwnProps extends CustomDropzoneProps {
  src?: string;
  onRemove?: () => void;
  onDrop: (files: File[]) => void;
  previewClassName?: string;
}

const VideoDropzone: React.FC<OwnProps> = ({ src, onRemove, onDrop, previewClassName = '', ...dropZoneProps }) => {
  console.log('src coming', src);

  return (
    <VideoDropzoneContainer>
      {src ? (
        <PreviewContainer className={previewClassName}>
          <video src={src} controls className="dropzone-preview"></video>
          {onRemove && (
            <RemoveContainer onClick={onRemove} className={'remove-image'}>
              <MdDeleteForever />
            </RemoveContainer>
          )}
        </PreviewContainer>
      ) : (
        <Dropzone accept={videoMimeTypesArray} onDrop={onDrop} {...dropZoneProps} />
      )}
    </VideoDropzoneContainer>
  );
};

export default VideoDropzone;

const VideoDropzoneContainer = styled.div`
  width: 100%;
`;

const PreviewContainer = styled.div`
  max-width: 100%;
  width: auto;
  position: relative;
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: -1rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 48px;
  height: 48px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

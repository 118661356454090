/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useController, useFormContext } from 'react-hook-form';
//
import styled from 'styled-components';
import { theme } from 'theme';
import DropdownSkeleton from './DropdownSkeleton';

interface OwnProps {
  name: string;
  onChangeExternal?: (value: string) => void;
}

const CitySelection: React.FC<OwnProps> = ({ name, onChangeExternal }) => {
  const { control } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    setSelectedCity(value);
  }, [value]);

  const [selectedCity, setSelectedCity] = useState(value);

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions, loading }: any) => (
    <AutoCompleteRoot>
      <CityInput {...getInputProps()} />
      {loading ||
        (suggestions?.length ? (
          <AutoCompleteDropDown>
            {loading && <DropdownSkeleton />}
            {suggestions.map((suggestion: any) => (
              <EachDropDownItem key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                <span>{suggestion.description}</span>
              </EachDropDownItem>
            ))}
          </AutoCompleteDropDown>
        ) : (
          ''
        ))}
    </AutoCompleteRoot>
  );

  const handleChange = (value: string) => {
    setSelectedCity(value);
  };

  const handleSelect = (value: string) => {
    onChange(value);
    onChangeExternal?.(value);
  };

  // In render function
  return (
    <PlacesAutocomplete value={selectedCity || ''} onSelect={handleSelect} onChange={handleChange}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
        renderFunc({ getInputProps, suggestions, getSuggestionItemProps, loading })
      }
    </PlacesAutocomplete>
  );
};

export default CitySelection;

const AutoCompleteRoot = styled.div`
  width: 100%;
  position: relative;
`;

const CityInput = styled.input`
  box-sizing: border-box;
  background: none;
  /* height: 1.4375em; */
  padding: 1rem;
  border: 1px solid transparent;
  background: ${theme.colors.lightGray};
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${theme.colors.stroke};
    border-radius: 30px;
  }

  &:focus-visible {
    outline: none;
  }
`;

const AutoCompleteDropDown = styled.div`
  height: 216px;
  position: absolute;
  width: 100%;
  background-color: ${theme.colors.white};
  box-shadow: 0px 1px 4px #ccc;
  z-index: 2;
  overflow: auto;
`;

const EachDropDownItem = styled.div`
  padding: 0.75rem 1rem;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

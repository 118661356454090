import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from 'helpers/history';
import { authUserSelector } from 'domain/auth/selectors';
import { useAppDispatch } from 'domain/store';
import AccessControl from 'components/base/AccessControl';

export default function PrivateRoute({
  children,
  allowedPermissions,
}: {
  children: React.ReactElement;
  allowedPermissions?: Array<string>;
}) {
  const authUser = useSelector(authUserSelector);
  /**
   * This line is required to use dispatch functions in the child components for some reasons
   * The reason is still unknown
   * TODO: Fix this hack after finding the root cause to "uncaught ReferenceError: Cannot access 'action' before initialization react-redux"
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useAppDispatch();

  if (!authUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  // authorized so return child components
  return <AccessControl allowedPermissions={allowedPermissions || []}>{children}</AccessControl>;
}

import React, { useState, useEffect, useCallback, useRef, createContext, useMemo } from 'react';
import Webcam from 'react-webcam';
//
import { saveFile } from 'helpers/saveFile';
import { isIOS } from 'helpers/device';

enum MediaDevicesInputs {
  Video = 'videoinput',
  Audio = 'audioinput',
}

type OwnProps = {
  handleRecordedBlob?: (record: Blob) => void;
};

const useVideoRecording = ({ handleRecordedBlob }: OwnProps) => {
  const webcamRef = useRef<Webcam | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  //
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [currentVideoDevice, setCurrentVideoDevice] = useState<MediaDeviceInfo | null>(null);
  const [currentAudioDevice, setCurrentAudioDevice] = useState<MediaDeviceInfo | null>(null);

  const handleVideoDeviceChange = useCallback((device: MediaDeviceInfo | null) => {
    setCurrentVideoDevice(device);
  }, []);

  const handleAudioDeviceChange = useCallback((device: MediaDeviceInfo) => {
    setCurrentAudioDevice(device);
  }, []);
  //
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);

  const handleVideoEnable = useCallback(() => {
    setIsVideoEnabled(true);
  }, []);

  const handleVideoDisable = useCallback(() => {
    setIsVideoEnabled(false);
  }, []);

  const handleAudioEnable = useCallback(() => {
    setIsAudioEnabled(true);
  }, []);

  const handleAudioDisable = useCallback(() => {
    setIsAudioEnabled(false);
  }, []);
  //
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<BlobPart[]>([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoBlob, setVideoBlob] = useState(new Blob());

  const handleDataAvailable = useCallback(({ data }: BlobEvent) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat([data]));
    }
  }, []);

  const isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const handleStartRecording = useCallback(() => {
    setIsRecording(true);
    console.log('isIOS', isIOS());

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current!.stream!, {
      mimeType: isIOS() ? 'video/mp4' : 'video/webm',
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [handleDataAvailable, webcamRef]);

  const handlePauseRecording = React.useCallback(() => {
    mediaRecorderRef.current!.pause();
    setIsRecording(false);
    setIsPaused(true);
  }, []);

  const handleContinueRecording = React.useCallback(() => {
    mediaRecorderRef.current!.resume();
    setIsRecording(true);
    setIsPaused(false);
  }, []);

  const handleStopRecording = React.useCallback(() => {
    mediaRecorderRef.current?.state !== 'inactive' && mediaRecorderRef.current!.stop();
    setIsRecording(false);
    setIsRecorded(true);
  }, []);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const chunk = recordedChunks;
      const blob = new Blob(chunk, {
        type: isIOS() ? 'video/mp4' : 'video/webm',
      });

      saveFile(blob, 'pitch-video-recording.mp4');

      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const handleClearChunks = React.useCallback(() => {
    setRecordedChunks([]);
    setIsRecorded(false);
  }, []);

  useEffect(() => {
    if (recordedChunks.length && isRecorded) {
      const blob = new Blob(recordedChunks, {
        type: isIOS() ? 'video/mp4' : 'video/webm',
      });
      setVideoBlob(blob);
      const url = URL.createObjectURL(blob);
      setVideoUrl(url);
      handleRecordedBlob && handleRecordedBlob(blob);
    }
  }, [recordedChunks, handleRecordedBlob, isRecorded, isSafari]);

  useEffect(() => {
    webcamRef.current?.stream?.getAudioTracks().forEach((track) => {
      track.enabled = !!isAudioEnabled;
    });
  }, [isAudioEnabled]);

  const InitializeMediaDevices = useCallback(async () => {
    const initializedStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const videoDevices = mediaDevices.filter(({ kind }) => kind === MediaDevicesInputs.Video);
      const audioDevices = mediaDevices.filter(({ kind }) => kind === MediaDevicesInputs.Audio);

      setVideoDevices(videoDevices);
      setAudioDevices(audioDevices);
      handleVideoDeviceChange(videoDevices[0]);
      handleAudioDeviceChange(audioDevices[0]);
      // getUserMedia keep accessing the microphone and camera
      // It's only used for getting the navigator.mediaDevices.enumerateDevices()
      initializedStream?.getTracks().forEach((track) => {
        track.stop();
      });
    });
  }, [handleAudioDeviceChange, handleVideoDeviceChange]);

  return {
    webcamRef,
    //
    handleStartRecording,
    handleStopRecording,
    handlePauseRecording,
    handleContinueRecording,
    handleDownload,
    handleClearChunks,
    InitializeMediaDevices,
    //
    isRecording,
    isRecorded,
    isPaused,
    recordedChunks,
    videoUrl,
    videoBlob,
    constraints: {
      video: {
        width: 606,
        height: 516,
        aspectRatio: 606 / 515,
        ...(currentVideoDevice && { deviceId: currentVideoDevice.deviceId }),
      },
      audio: {
        ...(currentAudioDevice && { deviceId: currentAudioDevice.deviceId }),
      },
    },
    //
    videoDevices,
    audioDevices,
    currentVideoDevice,
    currentAudioDevice,
    handleVideoDeviceChange,
    handleAudioDeviceChange,
    //
    isVideoEnabled,
    isAudioEnabled,
    handleVideoEnable,
    handleAudioEnable,
    handleVideoDisable,
    handleAudioDisable,
  };
};

export const VideoRecordingContext = createContext<ReturnType<typeof useVideoRecording> | null>(null);

export const useVideoRecordingContext = () =>
  React.useContext(VideoRecordingContext) as ReturnType<typeof useVideoRecording>;

export const VideoRecordingProvider: React.FC<ReturnType<typeof useVideoRecording> & { children: React.ReactNode }> = ({
  children,
  ...props
}) => <VideoRecordingContext.Provider value={props}>{children}</VideoRecordingContext.Provider>;

export type RecordingConstraints = {
  video: {
    width: number;
    height: number;
    deviceId?: string;
  };
  audio: {
    deviceId?: string;
  };
};

export default useVideoRecording;

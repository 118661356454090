import React, { useState, useCallback, useEffect } from 'react';
import { IconButton } from '@mui/material';
// components
import { Button, FlexBox } from 'components/base';
import VideoRecording from 'components/video-recording';
// utils
import { useVideoRecordingContext } from 'components/video-recording/useVideoRecording';
// translation
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
// assets
import DevicesSettingsPanel from '../DevicesSettingsPanel';
import styled from 'styled-components';
import { theme } from 'theme';
import clsx from 'clsx';
import { BsPlayCircle } from 'react-icons/bs';
import { BiMicrophone, BiMicrophoneOff, BiPauseCircle, BiVideo, BiVideoOff } from 'react-icons/bi';
import { AiOutlineUndo } from 'react-icons/ai';

interface OwnProps {
  onRecordSubmit: (url: Blob) => void;
  handleClose: () => void;
}

const VideoRecorder = ({ onRecordSubmit, handleClose }: OwnProps) => {
  const { formatMessage } = useIntl();
  const {
    webcamRef,
    constraints,
    //
    isVideoEnabled,
    isAudioEnabled,
    isRecording,
    isRecorded,
    isPaused,
    videoBlob,
    //
    audioDevices,
    videoDevices,
    currentVideoDevice,
    currentAudioDevice,
    //
    handleVideoDeviceChange,
    handleAudioDeviceChange,
    handleStartRecording,
    handlePauseRecording,
    handleContinueRecording,
    handleStopRecording,
    handleClearChunks,
    handleVideoEnable,
    handleAudioEnable,
    handleVideoDisable,
    handleAudioDisable,
    InitializeMediaDevices,
  } = useVideoRecordingContext();

  useEffect(() => {
    InitializeMediaDevices();
  }, [InitializeMediaDevices]);

  const isRecordingVideo = (isRecording || isPaused) && !isRecorded;
  const [isRecordingFinished, setIsRecordingFinished] = useState(isRecorded || false);

  const onStartProcess = useCallback(() => {
    handleStartRecording();
  }, [handleStartRecording]);

  const onStopProcess = useCallback(() => {
    setIsRecordingFinished(true);
    handleStopRecording();
  }, [handleStopRecording]);

  const handleSaveVideo = useCallback(() => {
    onStopProcess();
    onRecordSubmit(videoBlob);
    handleClose();
  }, [handleClose, onStopProcess, onRecordSubmit, videoBlob]);

  const onRetakeProcess = useCallback(() => {
    handleClearChunks();
    setIsRecordingFinished(false);
  }, [handleClearChunks]);

  return (
    <>
      <RecordingContainer>
        <>
          {isVideoEnabled && !isRecordingFinished && (
            <VideoRecording
              ref={webcamRef}
              constraints={constraints}
              isAudioEnabled={isAudioEnabled}
              isVideoEnabled={isVideoEnabled}
            />
          )}

          {isRecordingFinished && videoBlob ? <video src={URL.createObjectURL(videoBlob)} controls></video> : null}
        </>
      </RecordingContainer>
      <RecordingActionContainer className={isRecordingVideo ? 'recordingActionsContainer' : ''}>
        {/* <FlexBox>
          {!isRecordingFinished && !isRecordingVideo && (
            <RoundedButton
              onClick={isVideoEnabled ? handleVideoDisable : handleVideoEnable}
              className={isVideoEnabled ? 'disabled' : ''}
            >
              {isVideoEnabled ? <BiVideo /> : <BiVideoOff className={'disabledIcon'} />}
            </RoundedButton>
          )}
          {!isRecordingFinished && (
            <RoundedButton
              onClick={isAudioEnabled ? handleAudioDisable : handleAudioEnable}
              className={isAudioEnabled ? 'disabled' : ''}
            >
              {isAudioEnabled ? <BiMicrophone /> : <BiMicrophoneOff className={'disabledIcon'} />}
            </RoundedButton>
          )}
        </FlexBox> */}
        {!isRecordingVideo && !isRecordingFinished && (
          <>
            <div>
              <DevicesSettingsPanel
                isVideoEnabled={isVideoEnabled}
                isAudioEnabled={isAudioEnabled}
                handleVideoEnable={handleVideoEnable}
                handleAudioEnable={handleAudioEnable}
                handleVideoDisable={handleVideoDisable}
                handleAudioDisable={handleAudioDisable}
                //
                audioDevices={audioDevices}
                videoDevices={videoDevices}
                currentVideoDevice={currentVideoDevice}
                currentAudioDevice={currentAudioDevice}
                handleVideoDeviceChange={handleVideoDeviceChange}
                handleAudioDeviceChange={handleAudioDeviceChange}
              />
            </div>
            <Button variant="fill" onClick={onStartProcess} disabled={!isVideoEnabled}>
              {formatMessage({ id: 'Start Recording' })}
            </Button>
          </>
        )}
        {isRecordingVideo && (
          <FlexBox gap={'1rem'}>
            <CircleControlButton onClick={isPaused ? handleContinueRecording : handlePauseRecording}>
              {isPaused ? <BsPlayCircle className={'controlIcon'} /> : <BiPauseCircle className={'controlIcon'} />}
            </CircleControlButton>
            <CircleControlButton onClick={onStopProcess}>
              <div className={clsx('recordingSquareFlag', { recordingSquareFlag: isPaused })} />
            </CircleControlButton>
          </FlexBox>
        )}
        {isRecordingFinished && (
          <SubmitAction gap={'1rem'} justify="center">
            <RetakeButton variant="fill" onClick={onRetakeProcess}>
              <AiOutlineUndo />
              {formatMessage({ id: 'Retake' })}
            </RetakeButton>
            <Button variant="fill" onClick={handleSaveVideo}>
              {formatMessage({ id: 'Save' })}
            </Button>
          </SubmitAction>
        )}
      </RecordingActionContainer>
    </>
  );
};

const RecordingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  video {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

const RecordingActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0;
  }

  &.recordingActionsContainer {
    height: 3.5rem;
    justify-content: center;
  }
`;

const RoundedButton = styled(IconButton)`
  background-color: ${theme.colors.stroke};
  border-radius: 2rem;
  width: 40px;
  height: 40px;
  margin-right: 0.25rem;
  margin-left: 0.25rem;

  .disabledIcon path {
    fill: ${theme.colors.white};
  }

  &.disabled {
    cursor: pointer;
    opacity: 0.8;
    background-color: ${theme.colors.white};
  }
`;

const DisabledCameraContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`;

const CircleControlButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.stroke};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background: white;
  border: none;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  .controlIcon {
    width: 24px;
    height: 24px;
    fill: ${theme.colors.primary};
  }

  .recordingSquareFlag {
    width: 18px;
    height: 18px;
    background-color: ${theme.colors.black};
    border-radius: 2px;
  }

  .pausedBackground {
    background: ${theme.colors.primary};
  }
`;

const RetakeButton = styled(Button)`
  color: ${theme.colors.primary};
  background-color: ${theme.colors.white};
  margin-right: 0.75rem;
  box-shadow: none;
  border: 1px solid ${theme.colors.stroke};

  &:hover {
    background-color: ${theme.colors.white};
  }

  svg {
    margin-right: 10;

    path {
      fill: ${theme.colors.primary};
    }
  }

  > span {
    display: flex;
    align-items: center;
  }
`;

export default VideoRecorder;

const SubmitAction = styled(FlexBox)`
  width: 100%;
`;

export interface CreditType {
  id: number;
  currentAmount: number;
  amount: number;
  spend: number;
  companyId: number;
}

export type Address = {
  country: string;
  city: string;
  postcode: string;
  street: string;
  housenumber: string;
};
export enum CreditTransactionReason {
  SUBSCRIPTION = 'subscription',
  PURCHASE_CREDITS = 'purchase_credits',
  CANDIDATE = 'candidate',
  INTERVIEW = 'interview',
  WITHDRAWL = 'withdrawl',
  REFERENCES = 'references',
  ASSESMENT = 'assesment',
  ATS = 'ats',
}

export interface ChargeCreditPayload {
  companyId: number;
  amount: number;
  reason: CreditTransactionReason;
  comment: string;
}

export interface PurchaseCreditPayload {
  amount: number;
  address: Address;
}

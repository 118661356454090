// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  adminNameAsc: {
    field: 'adminName',
    order: 'ASC',
  },
  adminNameDesc: {
    field: 'adminName',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const AdminsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type AdminFiltersFormValues = FilterFormValues<keyof typeof AdminsSortConfigs>;

export const AdminSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'adminNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'adminNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtDesc',
    },
  ];
};

import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Wrapper } from 'components/Layout';
import React, { useCallback, useState, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import Button, { SubmitButton } from 'components/base/Button';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import { AdminPayload, AdminRolesEnum } from 'types/admins';
import { createAdminAction, getAdminDetailsAction, updateAdminAction } from 'domain/admins/actions';
import { MediaFileObj, uploadMedia } from 'pages/Companies/helpers';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';

interface OwnProps {
  refetch?: () => void;
  closeModal: () => void;
  adminId?: number | null;
  type?: 'create' | 'edit';
}

const AdminForm: React.FC<OwnProps> = ({ closeModal, refetch, type = 'create', adminId }) => {
  const dispatch = useAppDispatch();
  const adminForm = useForm<
    AdminPayload & {
      avatarObj?: MediaFileObj;
    }
  >();
  const { formatMessage } = useIntl();
  const avatarObj = useWatch({ name: 'avatarObj', control: adminForm.control });
  const uploadedAvatar = useWatch({ name: 'avatar', control: adminForm.control });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (adminId && type === 'edit') {
      dispatch(getAdminDetailsAction({ params: { id: adminId } }))
        .unwrap()
        .then((response: AdminPayload) => {
          adminForm.reset({
            ...response,
          });
        });
    }
  }, [adminForm, adminId, dispatch, type]);

  const adminRoleOptions = [
    { label: 'Admin', value: AdminRolesEnum.Admin },
    { label: 'Recruiter', value: AdminRolesEnum.Recruiter },
    { label: 'Sales', value: AdminRolesEnum.Sales },
  ];

  const onAdminCreationFormSubmit = useCallback(
    async (
      data: AdminPayload & {
        avatarObj?: MediaFileObj;
      },
    ) => {
      setIsSubmitting(true);
      const { avatarObj, ...formValues } = data;
      const [avatar] = await Promise.all([uploadMedia(avatarObj, data.avatar)]);
      const action =
        type === 'edit'
          ? updateAdminAction({ params: { id: adminId! }, ...formValues, avatar })
          : createAdminAction({ ...formValues, avatar });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('Admin created successfully');
          closeModal?.();
          refetch?.();
          setIsSubmitting(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          closeModal?.();
          toast.error(err?.data?.response?.data?.message || 'Failed to create admin! please try again later');
        });
    },
    [closeModal, adminId, dispatch, refetch, type],
  );

  const handleAvatarUpload = useCallback(
    (avatarObj: MediaFileObj) => {
      adminForm.setValue('avatarObj', avatarObj);
    },
    [adminForm],
  );

  const removeLogo = () => {
    adminForm.setValue('avatarObj', undefined);
    adminForm.setValue('avatar', undefined);
  };

  return (
    <CreateAdminForm>
      <FormProvider {...adminForm}>
        <Form onSubmit={adminForm.handleSubmit(onAdminCreationFormSubmit)}>
          <EachInput label={formatMessage({ id: IntlKeys.avatar })}>
            <ImageDropzone
              onDrop={(acceptedFiles) =>
                handleAvatarUpload({
                  fileObj: acceptedFiles[0],
                  src: URL.createObjectURL(acceptedFiles[0]),
                  name: acceptedFiles[0].name,
                })
              }
              maxFiles={1}
              height={200}
              src={uploadedAvatar?.url || avatarObj?.src}
              multiple={false}
              previewClassName={'comapny-logo-preview'}
              onRemove={removeLogo}
              cropConfig={{ aspectRatio: 1 }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.firstName })}>
            <FormTextField
              name={'firstName'}
              placeholder={formatMessage({ id: IntlKeys.firstNamePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.name })}>
            <FormTextField
              name={'lastName'}
              placeholder={formatMessage({ id: IntlKeys.namePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>

          <EachInput label={formatMessage({ id: IntlKeys.email })}>
            <FormTextField
              name={'email'}
              type={'email'}
              placeholder={formatMessage({ id: IntlKeys.email })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          {type === 'create' && (
            <EachInput label={formatMessage({ id: IntlKeys.password })}>
              <FormTextField
                name={'password'}
                type={'password'}
                placeholder={formatMessage({ id: IntlKeys.passwordPlaceholder })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
          )}

          <EachInput label={formatMessage({ id: IntlKeys.usersRole })}>
            <FormAutoComplete
              name="role"
              placeholder={formatMessage({ id: IntlKeys.usersRolePlaceholder })}
              options={adminRoleOptions}
            />
          </EachInput>

          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              {formatMessage({ id: IntlKeys.submit })}
            </SubmitButton>
            <Button variant="outline" onClick={closeModal}>
              Cancel
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </CreateAdminForm>
  );
};

export default AdminForm;
const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;

const CreateAdminForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

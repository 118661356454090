/* eslint-disable react/jsx-key */
import React, { useCallback, useRef, CSSProperties } from 'react';
import { useTable, useFlexLayout, HeaderGroup, Column, ColumnWithLooseAccessor } from 'react-table';
import { useVirtual, VirtualItem } from '@tanstack/react-virtual';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { TableContainer, LinearProgress, Skeleton } from '@mui/material';
// components
import DynamicTooltip from 'components/dynamic-tooltip';
// styles
import styled from 'styled-components';
import { theme } from 'theme';

export type DataType = {
  style?: CSSProperties;
  withTooltip?: boolean;
};

export type InfiniteColumnsType<T extends Record<string, unknown> = Record<string, unknown>> = Array<
  ColumnWithLooseAccessor<T>
>;

interface OwnProps {
  columns: Column<Record<string, unknown>>[];
  data: Record<string, unknown>[];
  fetchNext: VoidFunction;
  //
  isLoading: boolean;
  isLoaded?: boolean;
  isStale?: boolean;
  //
  page: number;
  pageCount: number;
  perPage?: number;
  itemCount?: number;
  //
  sizeRow?: number;
  className?: string;
  scrollcontainer?: string;
}

const InfiniteTable: React.FC<OwnProps> = ({
  data,
  columns,
  // fetchMore,
  fetchNext,
  //
  isLoading,
  isLoaded = false,
  isStale = false,
  //
  page,
  perPage = 10,
  pageCount,
  itemCount = 0,
  //
  sizeRow = 56,
  className,
  scrollcontainer,
}) => {
  const parentRef = useRef<null | HTMLDivElement>(null);

  const resources = isStale ? [] : data;
  //
  const morePagesAvailable = isLoaded && pageCount > page;
  const shouldReload = !isLoaded && page === 0 && pageCount === 0;
  const itemsLeft = itemCount !== 0 ? itemCount - resources.length : perPage;
  const itemsLoading = Math.min(itemsLeft, perPage);
  const hasMore = !isLoading && (morePagesAvailable || shouldReload || isStale);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: fetchNext,
  });

  const rowVirtualizer = useVirtual({
    size: isLoading ? resources.length + itemsLoading : resources.length,
    parentRef,
    estimateSize: useCallback(() => sizeRow, [sizeRow]),
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: resources,
    },
    useFlexLayout,
  );

  const RenderRow = useCallback(
    ({ index, size, start }: VirtualItem) => {
      const row = rows[index];

      if (!row) {
        return (
          <Skeleton
            //
            key={index}
            variant="rectangular"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: sizeRow - 8,
              transform: `translateY(${start}px)`,
            }}
          />
        );
      }

      prepareRow(row);

      return (
        <TableRow
          {...row.getRowProps()}
          role="row"
          style={{
            height: size,
            transform: `translateY(${start}px)`,
          }}
        >
          {row.cells.map((cell) => (
            // @ts-expect-error
            <Cell role="td" {...cell.getCellProps({ style: cell.column.style })}>
              {/* @ts-expect-error */}
              {cell.column.withTooltip ? (
                <DynamicTooltip title={cell.value}>{cell.render('Cell')}</DynamicTooltip>
              ) : (
                cell.render('Cell')
              )}
            </Cell>
          ))}
        </TableRow>
      );
    },
    [prepareRow, rows, sizeRow],
  );

  const RenderHeader = useCallback(
    (headerGroup: HeaderGroup) => (
      <div role="tr" style={{ height: sizeRow }} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <HeaderCell
            role="td"
            // @ts-ignore
            {...column.getHeaderProps({ style: column?.style })}
          >
            {column.render('Header')}
          </HeaderCell>
        ))}
      </div>
    ),
    [sizeRow],
  );

  return (
    <TableContainer className={className} sx={{ flex: 1 }}>
      <Table ref={parentRef} role="table" {...getTableProps()}>
        <TableHeader>{headerGroups.map(RenderHeader)}</TableHeader>

        <div className={scrollcontainer}>
          <TableBody style={{ height: rowVirtualizer.totalSize }} {...getTableBodyProps()}>
            {rowVirtualizer.virtualItems.map(RenderRow)}
            {hasMore && <Sentry ref={sentryRef} />}
          </TableBody>
        </div>

        {/* ///////////////////////// SENTRY ///////////////////////// */}
        {isLoading && <LinearProgress />}
      </Table>
    </TableContainer>
  );
};

export default React.memo(InfiniteTable);

const Table = styled.div`
  height: 100%;
  overflow: auto;
`;

const TableHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const TableBody = styled.div`
  position: relative;
  width: 100%;
`;

const Sentry = styled.div`
  position: absolute;
  bottom: 0;
  height: 200;
  width: 100%;
  visibility: hidden;
`;

const Cell = styled.div`
  border-bottom: 1px solid ${theme.colors.stroke};
  font-family: ${theme.fonts.montreal};
  color: ${theme.colors.secondary};
  padding: 18px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const HeaderCell = styled(Cell)`
  border-top: 1px solid ${theme.colors.stroke};
  background-color: white;
`;

const TableRow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
`;

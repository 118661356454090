import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { MessageList, MessageStatusPayload, MessageType } from 'types/messages';

export const fetchMessagesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<MessageList>({
    api: restClient.getMessages,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getMessagesAction = createAsyncThunk<MessageList, Params | undefined>(
  'messages/getMessagesAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchMessagesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateMessageStatusAction = createAsyncThunk<MessageType, MessageStatusPayload | Params>(
  'messages/updateMessageStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<MessageType>({
      api: restClient.updateMessageStatus,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteMessageAction = createAsyncThunk<void, Params>(
  'messages/deleteMessageAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.deleteMessage,
    });
    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

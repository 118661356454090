import { Tab, Tabs } from '@mui/material';
import { IntlKeys } from 'localization';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyType } from 'types/company';
import CompanyCreditHistory from './tabs/CompanyCreditHistory';
import CompanyInvoices from './tabs/CompanyInvoices';
import CompanySubscription from './tabs/CompanySubscription';
import CompanyUsers from './tabs/CompanyUsers';
import JobsList from 'pages/jobs';

interface OwnProps {
  company: CompanyType;
  refetch?: () => void;
}

const CompanyDetailsTabs: React.FC<OwnProps> = ({ company, refetch }) => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = ['users', 'jobs', 'invoices', 'credit-history', 'subscription'];
  const tabFromQuery = searchParams.get('tab') as string;
  const [selectedTab, setselectedTab] = React.useState(tabs.includes(tabFromQuery) ? tabFromQuery : 'users');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setselectedTab(newValue);
    if (newValue === 'jobs') {
      setSearchParams({ tab: newValue, status: 'published' });
    } else {
      setSearchParams({ tab: newValue });
    }
  };

  return (
    <TabsContainer>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Job details tabs">
        <Tab value="users" label={formatMessage({ id: IntlKeys.users })} />
        <Tab value="jobs" label={formatMessage({ id: IntlKeys.jobs })} />
        <Tab value="invoices" label={formatMessage({ id: IntlKeys.invoices })} />
        <Tab value="credit-history" label={formatMessage({ id: IntlKeys.creditHistory })} />
        <Tab value="subscription" label={formatMessage({ id: IntlKeys.subscription })} />
      </Tabs>
      <TabPanel value={selectedTab} index={'users'}>
        <CompanyUsers company={company} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'jobs'}>
        <JobsList companyId={company.id} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'invoices'}>
        <CompanyInvoices company={company} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'credit-history'}>
        <CompanyCreditHistory company={company} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'subscription'}>
        <CompanySubscription company={company} refetch={refetch} />
      </TabPanel>
    </TabsContainer>
  );
};

export default CompanyDetailsTabs;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value == index && <>{children}</>}
    </div>
  );
};

const TabsContainer = styled.section``;

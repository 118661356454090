import { configureApiCalls } from 'helpers/http/utils/createApi';
import { api } from './api';
import { makeUrl } from 'helpers/http/utils/url';

export const restClient = configureApiCalls({
  // updateExample: api('post', makeUrl`/examples/${'exampleId'}`), // exampleId -> from action.payload.params
  // auth
  signIn: api('post', 'auth/login'),
  signUp: api('post', 'auth/register'),
  forgotPassword: api('post', 'auth/reset-password'),
  resetPassword: api('post', 'auth/set-new-password'),
  refreshToken: api('post', 'auth/refresh-token'),
  inviteMember: api('post', 'auth/invite'),
  generateInviteLink: api('post', 'auth/invite/link'),
  //
  getCurrentUser: api('get', 'me'),
  updateUserAvatar: api('patch', 'me/avatar'),
  uploadUserAvatar: api('post', 'me/avatar/upload'),
  // settings
  updateCurrentUser: api('put', 'users/me'),
  updateUserPassword: api('post', 'auth/change-password'),

  // files
  uploadImage: api('post', 'storage'),
  uploadFile: api('post', 'storage'),

  // jobs
  createJob: api('post', '/jobs'),
  getJobs: api('get', 'jobs'),
  getJobDetails: api('get', makeUrl`jobs/${'id'}`),
  deleteJob: api('delete', makeUrl`jobs/${'id'}`),
  updateJobWorkflow: api('patch', makeUrl`jobs/${'id'}/workflow`),
  updateJobDetails: api('put', makeUrl`jobs/${'id'}`),
  customizeJob: api('patch', makeUrl`jobs/${'id'}/customize`),
  createJobQualificationQuestions: api('post', makeUrl`/jobs/${'id'}/questions`),
  deleteQuestion: api('delete', makeUrl`jobs/${'jobId'}/questions/${'questionId'}`),
  updateQuestion: api('put', makeUrl`jobs/${'jobId'}/questions/${'questionId'}`),
  updateJobStatus: api('patch', makeUrl`jobs/${'id'}/status`),
  createComment: api('post', makeUrl`jobs/${'id'}/comments`),
  updateComment: api('put', makeUrl`jobs/${'id'}/comments/${'commentId'}`),
  deleteComment: api('delete', makeUrl`jobs/${'id'}/comments/${'commentId'}`),
  markAsContacted: api('patch', makeUrl`jobs/${'id'}/contacted`),
  customJobInvite: api('put', makeUrl`jobs/${'jobId'}/invite/${'candidateId'}`),

  // benefits
  getBenefits: api('get', 'benefits'),
  createBenefit: api('post', 'benefits'),
  createBenefits: api('post', 'benefits/multi'),
  getBenefitDetails: api('get', makeUrl`benefits/${'id'}`),
  updateBenefitDetails: api('put', makeUrl`benefits/${'id'}`),
  deleteBenefitDetails: api('delete', makeUrl`benefits/${'id'}`),

  // companies
  getCompanies: api('get', 'companies'),
  getCompanyDetails: api('get', makeUrl`companies/${'id'}`),
  updateCompanyDetails: api('put', makeUrl`companies/${'id'}`),
  deleteCompany: api('delete', makeUrl`companies/${'id'}`),
  createCompany: api('post', 'companies'),
  createDemoCompany: api('post', 'companies/demo'),

  // candidates | Talents
  getTalents: api('get', 'candidates'),
  getTalentDetails: api('get', makeUrl`candidates/${'id'}`),
  createTalent: api('post', 'candidates'),
  updateTalentDetails: api('put', makeUrl`candidates/${'id'}`),
  updateTalentStatus: api('patch', makeUrl`candidates/${'id'}`),
  deleteTalent: api('delete', makeUrl`candidates/${'id'}`),
  // candidate pools
  getTalentPools: api('get', 'candidate-pools'),
  getTalentPoolDetails: api('get', makeUrl`candidate-pools/${'id'}`),
  updateTalentPoolDetails: api('put', makeUrl`candidate-pools/${'id'}`),
  createTalentPools: api('post', 'candidate-pools'),
  deletePool: api('delete', makeUrl`candidate-pools/${'id'}`),
  connectTalentPoolToEntities: api('patch', 'candidate-pools'),
  connectTalentToEntities: api('patch', 'candidates'),
  detechTalentFromJob: api('patch', makeUrl`jobs/${'jobId'}/detach/${'candidateId'}`),
  // candidate comments
  createCandidateComments: api('post', makeUrl`candidates/${'id'}/comments`),

  // admin
  getAdmins: api('get', ''),
  getAdmin: api('get', makeUrl`${'id'}`),
  createAdmin: api('post', ''),
  updateAdmin: api('put', makeUrl`${'id'}`),
  deleteAdmin: api('delete', makeUrl`${'id'}`),

  // users
  getUsers: api('get', 'users'),
  getUser: api('get', makeUrl`users/${'id'}`),
  updateUserStatus: api('patch', makeUrl`users/${'id'}/status`),
  updateUserRole: api('patch', makeUrl`users/${'id'}/role`),
  createUser: api('post', 'users'),
  updateUser: api('put', makeUrl`users/${'id'}`),
  deleteUser: api('delete', makeUrl`users/${'id'}`),

  // applications
  getApplications: api('get', 'applications'),
  createApplication: api('post', 'applications'),
  createApplicationAnswers: api('post', makeUrl`applications/${'id'}/answers`),
  getApplicationDetails: api('get', makeUrl`applications/${'id'}`),
  updateApplicationDetails: api('put', makeUrl`applications/${'id'}`),
  changeApplicationStatus: api('patch', makeUrl`applications/${'id'}`),

  // Subsriptions
  getSubscriptions: api('get', '/subscriptions'),
  getSubscription: api('get', makeUrl`/subscriptions/${'id'}`),
  subscribeTo: api('patch', makeUrl`/subscriptions/${'id'}/company/${'companyId'}`),
  createSubscription: api('post', '/subscriptions'),
  createCustomSubscription: api('post', makeUrl`/subscriptions/company/${'companyId'}`),
  // Credits
  getCredits: api('get', '/credits'),
  chargeCredits: api('post', '/credits'),
  getCreditTransactions: api('get', '/credits/history'),
  purchaseCredits: api('put', '/credits/purchase'),
  // Invoices
  getInvoices: api('get', '/invoices'),
  updateInvoiceStatus: api('patch', makeUrl`/invoices/${'id'}/paid`),
  sendInvoiceReminder: api('patch', makeUrl`/invoices/${'id'}/remind`),
  cancelInvoice: api('patch', makeUrl`/invoices/${'id'}/cancel`),

  // Messages
  getMessages: api('get', '/messages'),
  updateMessageStatus: api('patch', makeUrl`/messages/${'id'}/status`),
  deleteMessage: api('delete', makeUrl`/messages/${'id'}`),
});

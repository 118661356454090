import { authUserSelector } from 'domain/auth/selectors';
import { checkPermissions } from 'helpers/adminPermissions';
import React from 'react';
import { useSelector } from 'react-redux';

interface AccessProps {
  allowedPermissions: Array<string>;
  children: React.ReactNode | string;
}

const AccessControlHide: React.FC<AccessProps> = ({ allowedPermissions, children }) => {
  const admin = useSelector(authUserSelector);

  const permitted = checkPermissions(allowedPermissions, admin?.role);

  if (permitted) return <>{children}</>;

  return <></>;
};

export default AccessControlHide;

import { getCompanyDetailsAction } from 'domain/companies/actions';
import { useAppDispatch } from 'domain/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { companiesResourceSelector, companiesMetaSelector } from 'domain/companies/selectors';
import styled from 'styled-components';
import { FlexBox } from 'components/base';
import { Loader } from 'components/loader';
import { theme } from 'theme';
import CompanyDetailsTabs from './CompanyDetailsTabs';

const CompanyDetails = () => {
  const { companyId } = useParams();
  const company = useSelector(companiesResourceSelector);
  const companyMeta = useSelector(companiesMetaSelector);
  const dispatch = useAppDispatch();

  const fetchCompanyDetails = () => {
    if (companyId) dispatch(getCompanyDetailsAction({ params: { id: companyId } }));
  };
  useEffect(() => {
    if (companyId) dispatch(getCompanyDetailsAction({ params: { id: companyId } }));
  }, [companyId, dispatch]);

  if (companyMeta.isLoading) {
    return <Loader />;
  }

  if (!company) {
    return <span>No Details available</span>;
  }

  return (
    <Container>
      <MetaDetails gap={'1rem'}>
        <FlexBox gap={'1rem'} direction="column">
          <Image src={company!.logo?.url || ''} />
          <div>
            <Name>{company.name}</Name>
            <p>{company.employeeCount}</p>
          </div>
        </FlexBox>
        <Address>
          <p>
            <span>{company?.address?.street} </span>
            <span>{company?.address?.housenumber}</span>
          </p>
          <p>
            <span>{company?.address?.postcode} </span>
            <span>{company?.address?.city}</span>
          </p>
          <p>
            <span>{company?.address?.country} </span>
          </p>
        </Address>
      </MetaDetails>
      <CompanyDetailsTabs company={company} refetch={fetchCompanyDetails} />
    </Container>
  );
};

export default CompanyDetails;

const Container = styled.div`
  min-height: 100%;
  background-color: ${theme.colors.lightGray};
  padding: 1rem;
`;

const MetaDetails = styled(FlexBox)`
  margin-bottom: 2rem;

  img {
    max-height: 64px;
  }
`;

const Image = styled.img``;

const Name = styled.span`
  font-size: 2rem;
  font-weight: 500;
`;

const Address = styled.div`
  padding-left: 1rem;
  border-left: 3px solid ${theme.colors.stroke};
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CompanyList, CompanyPayload, CompanyType } from 'types/company';
//
import { NetworkErrorType, restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
//

export const fetchCompaniesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<CompanyList>({
    api: restClient.getCompanies,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getCompaniesAction = createAsyncThunk<CompanyList, Params | undefined>(
  'companies/getCompaniesAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchCompaniesWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCompanyDetailsAction = createAsyncThunk<CompanyType, Params>(
  'companies/getCompanyDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: restClient.getCompanyDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCompanyAction = createAsyncThunk<CompanyType, CompanyPayload>(
  'companies/createCompanyAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: restClient.createCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createDemoCompanyAction = createAsyncThunk<void, Params>(
  'companies/createDemoCompanyAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.createDemoCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateCompanyAction = createAsyncThunk<CompanyType, CompanyPayload | Params>(
  'jobs/createCompanyAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CompanyType>({
      api: restClient.updateCompanyDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const deleteCompanyAction = createAsyncThunk<void, Params>(
  'companies/deleteCompanyAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.deleteCompany,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

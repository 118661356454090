import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import {
  CommentPayloadType,
  JobCustomInvitePayload,
  JobCustomizationPayload,
  JobList,
  JobPayloadType,
  JobPublishPayload,
  JobTokenType,
  JobType,
  JobWorkflowPayload,
  MarkAsContactedPayload,
  QualificationQuestionsPayload,
  QualificationQuestionsType,
} from 'types/job';
//
import { NetworkErrorType, restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
//

export const fetchJobsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<JobList>({
    api: restClient.getJobs,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getJobsAction = createAsyncThunk<JobList, Params | undefined>(
  'jobs/getJobsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchJobsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getJobDetailsAction = createAsyncThunk<JobType, Params>(
  'jobs/getJobDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.getJobDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteJobAction = createAsyncThunk<JobType, Params>(
  'jobs/deleteJobDetails',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.deleteJob,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateJobWorkflowAction = createAsyncThunk<JobType, JobWorkflowPayload | Params>(
  'jobs/updateJobWorkflowAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.updateJobWorkflow,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateJobDetailsAction = createAsyncThunk<JobType, JobPayloadType | Params>(
  'jobs/updateJobDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.updateJobDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createJobAction = createAsyncThunk<JobType, JobPayloadType>(
  'jobs/createJobAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.createJob,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const customizeJobAction = createAsyncThunk<JobType, JobCustomizationPayload & Params>(
  'jobs/customizeJobAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.customizeJob,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateJobStatusAction = createAsyncThunk<JobType, JobPublishPayload & Params>(
  'jobs/updateJobStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobType>({
      api: restClient.updateJobStatus,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateQuestionAction = createAsyncThunk<
  QualificationQuestionsType,
  QualificationQuestionsPayload & Params
>('jobs/updateQuestionAction', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<QualificationQuestionsType>({
    api: restClient.updateQuestion,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const deleteQuestionAction = createAsyncThunk<unknown, Params>(
  'jobs/deleteQuestionAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.deleteQuestion,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createCommentAction = createAsyncThunk<unknown, CommentPayloadType & Params>(
  'jobs/createCommentAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.createComment,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const markAsContactedAction = createAsyncThunk<unknown, MarkAsContactedPayload & Params>(
  'jobs/markAsContactedAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.markAsContacted,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const customJobInviteAction = createAsyncThunk<JobTokenType, JobCustomInvitePayload & Params>(
  'jobs/customJobInviteAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<JobTokenType>({
      api: restClient.customJobInvite,
    });
    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

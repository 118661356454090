import { PaginationType } from 'helpers/http';

export type SubscriptionType = {
  id: number;
  name: string;
  perCreditsPrice: number;
  includedCredits: number;
  maxJobs: number;
  period: SubscriptionPeriod;
  services: Record<string, boolean>;
};

export interface SubscriptionPayload {
  name: string;
  perCreditsPrice: number;
  includedCredits: number;
  maxJobs: number;
  period: SubscriptionPeriod;
  services: Record<string, boolean>;
}

export enum SubscriptionPeriod {
  MONTH = 'monthly',
  YEAR = 'yearly',
}

export interface SubscriptionList extends PaginationType {
  data: SubscriptionType[];
}

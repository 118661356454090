import { createSlice } from '@reduxjs/toolkit';

import {
  createJobAction,
  customizeJobAction,
  getJobDetailsAction,
  getJobsAction,
  updateJobDetailsAction,
  updateJobStatusAction,
  updateQuestionAction,
} from './actions';

import { JobType } from 'types/job';
import { JobsSortConfigs } from 'types/job/entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isSubmitting: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: JobsSortConfigs.jobTitleAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as JobType | null,
  resources: [] as JobType[],
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getJobsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getJobsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getJobsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getJobDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getJobDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getJobDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(createJobAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = true;
      })
      .addCase(createJobAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        draft.resource = payload;
        draft.meta.isSubmitting = false;
      })
      .addCase(createJobAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = false;
      })
      //////////////////////////////////////////////////////////////////////////
      .addCase(updateJobDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = true;
      })
      .addCase(updateJobDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        draft.meta.isSubmitting = false;

        draft.resource = payload;
      })
      .addCase(updateJobDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = false;
      })
      //////////////////////////////////////////////////////////////////////////
      .addCase(customizeJobAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = true;
      })
      .addCase(customizeJobAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        draft.meta.isSubmitting = false;

        draft.resource = payload;
      })
      .addCase(customizeJobAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.meta.isSubmitting = false;
      })

      //////////////////////////////////////////////////////////////////////////
      .addCase(updateQuestionAction.pending, (draft) => {
        draft.meta.isSubmitting = true;
      })
      .addCase(updateQuestionAction.fulfilled, (draft) => {
        draft.meta.isSubmitting = false;
      })
      .addCase(updateQuestionAction.rejected, (draft) => {
        draft.meta.isSubmitting = false;
      })

      //////////////////////////////////////////////////////////////////////////
      .addCase(updateJobStatusAction.pending, (draft) => {
        draft.meta.isSubmitting = true;
      })
      .addCase(updateJobStatusAction.fulfilled, (draft, {}) => {
        draft.meta.isSubmitting = false;
      })
      .addCase(updateJobStatusAction.rejected, (draft) => {
        draft.meta.isSubmitting = false;
      });
  },
});

export const jobsReducer = jobsSlice.reducer;

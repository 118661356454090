import * as React from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
//
import { BiLoaderAlt } from 'react-icons/bi';
import { ButtonBaseProps } from '@mui/material';

export interface ButtonProps {
  bgColor?: string;
  color?: string;
  variant?: 'fill' | 'outline';
  isLoading?: boolean;
  children?: React.ReactNode | string;
}

const Button = styled.button`
  ${(props: ButtonProps) =>
    (props.variant === 'fill' || !props.variant) &&
    css`
      background-color: ${(props: ButtonProps) => props.bgColor ?? theme.colors.primary};
      color: ${(props: ButtonProps) => props.color ?? theme.colors.white};
      border: 1px solid ${(props: ButtonProps) => props.bgColor ?? theme.colors.primary};
    `}

  ${(props: ButtonProps) =>
    props.variant === 'outline' &&
    css`
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.disabledGray};
      color: ${(props: ButtonProps) => props.color ?? theme.colors.secondary};

      &:hover {
        background-color: ${theme.colors.lightGray};
      }
    `}

  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.7rem 1.5rem;
  cursor: pointer;

  :disabled {
    opacity: 0.75;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export default Button;

export const SubmitButton: React.FC<ButtonProps & ButtonBaseProps> = ({ children, isLoading, ...props }) => (
  <CustomButton {...props}>{isLoading ? <BiLoaderAlt /> : children}</CustomButton>
);

const CustomButton = styled(Button)`
  > svg {
    animation: rotate 0.5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//
import { useIntl } from 'react-intl';
import { TalentPoolFiltersFormValues } from 'types/talent/entities';
import { Button } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import CreateOrEditTalent from './CreateOrEditTalent';

interface OwnProps {
  onChange: (config: TalentPoolFiltersFormValues) => void;
  config: TalentPoolFiltersFormValues;
  refetch?: () => void;
}

const TalentFilters: React.FC<OwnProps> = ({ onChange, config, refetch }) => {
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;
  const [createnewModalOpen, setCreateNewModalOpen] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange();
    }
  }, [searchValue]);

  const handleSearchChange = () => {
    config.filters.search = searchValue;

    onChange(config);
  };

  const openCreatePoolModal = useCallback(() => {
    setCreateNewModalOpen(true);
  }, []);

  const closeCreatePoolModal = useCallback(() => {
    setCreateNewModalOpen(false);
  }, []);

  return (
    <FilterContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <SearchBox>
            <FormTextField name="search" placeholder="Search for a talent" />
          </SearchBox>
        </Form>
      </FormProvider>
      <Button onClick={openCreatePoolModal}>Add new</Button>
      <ModalDialog isOpen={createnewModalOpen} handleClose={closeCreatePoolModal}>
        <CreateOrEditTalent refetch={refetch} closeModal={closeCreatePoolModal} />
      </ModalDialog>
    </FilterContainer>
  );
};

export default TalentFilters;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  button {
    min-width: 150px;
  }
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

export interface ButtonProps {
  active?: boolean;
}

const Form = styled.form`
  width: 100%;
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import {
  Application,
  ApplicationList,
  ApplicationPayloadType,
  ApplicationRemarksPayload,
  ApplicationUpdatePayload,
  ApplicationsStatusType,
} from 'types/applications';
//
import { NetworkErrorType, restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { QualificationAnswer, QualificationAnswerPayload } from 'types/job';
//

export const fetchApplicationsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<ApplicationList>({
    api: restClient.getApplications,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getApplicationsAction = createAsyncThunk<ApplicationList, Params | undefined>(
  'applications/getApplicationsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchApplicationsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getApplicationDetailsAction = createAsyncThunk<Application, Params>(
  'applications/getApplicationDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Application>({
      api: restClient.getApplicationDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createApplicationAction = createAsyncThunk<
  Application,
  (ApplicationPayloadType | ApplicationRemarksPayload) & Params
>('applications/createApplicationAction', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<Application>({
    api: restClient.createApplication,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const createApplicationAnswersAction = createAsyncThunk<
  QualificationAnswer,
  QualificationAnswerPayload & Params
>('applications/createApplicationAnswerAction', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<QualificationAnswer>({
    api: restClient.createApplicationAnswers,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const updateApplicationAction = createAsyncThunk<
  Application,
  (ApplicationPayloadType | ApplicationUpdatePayload) & Params
>('applications/updateApplicationAction', async (requestPayload, { rejectWithValue }) => {
  const callApi = createApiCall<Application>({
    api: restClient.updateApplicationDetails,
  });

  try {
    const { data } = await callApi(requestPayload);

    return data;
  } catch (err) {
    return rejectWithValue(err as NetworkErrorType);
  }
});

export const changeApplicationStatusAction = createAsyncThunk<Application, ApplicationsStatusType & Params>(
  'applications/changeApplicationStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<Application>({
      api: restClient.changeApplicationStatus,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

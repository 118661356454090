import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CompanyType } from 'types/company';
import { FiEdit } from 'react-icons/fi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DropdownMenu from 'components/base/DropdownMenu';
import { IntlKeys } from 'localization';
import { MdDelete } from 'react-icons/md';

interface OwnProps {
  company: CompanyType;
  setCurrentlyEdittingCompany: (id: number) => void;
  openModal: (type: 'edit' | 'delete') => void;
}

const PopoverMenu: React.FC<OwnProps> = ({ company, setCurrentlyEdittingCompany, openModal }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { formatMessage } = useIntl();
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuTrigger = (
    <MenuTrigger onClick={handleMenuClick} id={`${company.name}-${company.id}`}>
      <BsThreeDotsVertical size="small" aria-haspopup="true" />
    </MenuTrigger>
  );

  const menuActions = [
    {
      label: formatMessage({ id: IntlKeys.edit }),
      icon: <FiEdit />,
      onClick: () => {
        handleMenuClose();
        openModal('edit');
        setCurrentlyEdittingCompany(company.id);
      },
    },
    {
      label: formatMessage({ id: IntlKeys.delete }),
      icon: <MdDelete />,
      onClick: () => {
        handleMenuClose();
        openModal('delete');
        setCurrentlyEdittingCompany(company.id);
      },
    },
  ];
  return (
    <DropdownMenu
      trigger={menuTrigger}
      anchorEl={anchorEl}
      handleClose={handleMenuClose}
      actions={menuActions}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
};

export default PopoverMenu;

const MenuTrigger = styled.div`
  cursor: pointer;
  padding: 0.5rem 0rem 1rem;
  svg {
    font-size: 1rem;
    height: 1rem;
  }
`;

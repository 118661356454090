import VerticalInfiniteGrid, { InfiniteGridProps } from 'components/InfiniteList/VerticalInfiniteGrid';
import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobType, JobWorkflow } from 'types/job';
import { DraggableCardInfo } from '../helper';

interface OwnProps extends InfiniteGridProps {
  onChange: (cardInfo: DraggableCardInfo, status: JobWorkflow['status'], targetId: number | string) => void;
  status: JobWorkflow['status'];
  title: string;
  list: JobType[];
}

const JobBoardColumn: React.FC<OwnProps> = ({ list, title, status, onChange, ...gridProps }) => {
  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if ((e.target as HTMLElement).className === 'boardContentArea') {
      setTimeout(() => {
        (e.target as HTMLElement).className = 'boardContentArea hovered';
      }, 0);
    }
  };
  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if ((e.target as HTMLElement).className === 'boardContentArea hovered') {
      setTimeout(() => {
        (e.target as HTMLElement).className = 'boardContentArea';
      }, 0);
    }
  };
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    const cardInfo = JSON.parse(e.dataTransfer.getData('cardInfo'));
    const targetCardId = (e.target as HTMLElement).id;
    onChange(cardInfo, status, targetCardId);
    if ((e.target as HTMLElement).className === 'boardContentArea hovered') {
      setTimeout(() => {
        (e.target as HTMLElement).className = 'boardContentArea';
      }, 0);
    }
  };

  return (
    <div className="board-col">
      <Column className="list">
        <ColumnHead>{title}</ColumnHead>
        <Cards
          className="boardContentArea"
          onDragEnter={onDragEnterHandler}
          onDragOver={onDragOverHandler}
          onDragLeave={onDragLeaveHandler}
          onDrop={onDropHandler}
        >
          <VerticalInfiniteGrid list={list || []} {...gridProps} />
        </Cards>
      </Column>
    </div>
  );
};

export default JobBoardColumn;

const Column = styled.div`
  /* background-color: ${theme.colors.white}; */
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Cards = styled.div`
  flex: 1;
`;

const ColumnHead = styled.h3`
  margin: 0rem 0rem 1rem;
`;

import { Input, Avatar } from '@mui/material';
import InfiniteList from 'components/InfiniteList';
import { fetchAdminsWorker } from 'domain/admins/actions';
import { nameToInitials } from 'helpers/string';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import { UserType } from 'types/user';

interface OwnProps {
  onSelectAssignee: (e: React.MouseEvent<HTMLElement>, id: number) => void;
}

const UserList: React.FC<OwnProps> = ({ onSelectAssignee }) => {
  const [searchValue, setSearchValue] = useState('');
  const {
    isLoading,
    isFetched,
    resources: backofficeUsers,
    fetchNextPage,
    //
    pagination,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchAdminsWorker,
    initialSort: {},
    initialFilters: { search: '' },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((search: string) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...{ search },
      }));
    }, 200),
    [],
  );

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange(searchValue);
    }
  }, [handleSearchChange, searchValue]);

  const handleSearchFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const UserItem = ({ data }: { data: unknown }) => {
    const user = data as UserType;
    return (
      <ListItem onClick={(e) => onSelectAssignee(e, user.id)}>
        <Avatar sx={{ width: 32, height: 32, fontSize: '.875rem', bgcolor: theme.colors.maroon }}>
          {nameToInitials(user.fullName)}{' '}
        </Avatar>
        {user.fullName}
      </ListItem>
    );
  };

  return (
    <UserListContainer>
      <Input
        value={searchValue}
        onChange={handleSearchFieldChange}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        placeholder="Search for a user"
        fullWidth
      />
      <ListSection>
        <InfiniteList
          list={backofficeUsers}
          isLoading={isLoading}
          isLoaded={isFetched}
          fetchNext={fetchNextPage}
          {...pagination}
          ListItem={UserItem}
          emptyState={<EmptyState>Can not find the searched user</EmptyState>}
        />
      </ListSection>
    </UserListContainer>
  );
};

export default UserList;

const UserListContainer = styled.div`
  width: 250px;
  margin-top: 1rem;

  input {
    padding-left: 1rem;
  }
`;

const ListItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ListSection = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const EmptyState = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

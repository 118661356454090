import { Avatar } from '@mui/material';
import { FlexBox, SubmitButton } from 'components/base';
import FormCheckBox from 'components/hook-form/fields/FormCheckBox';
import { createCommentAction, getJobDetailsAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import { formatDate } from 'helpers/date';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { CommentPayloadType, JobType } from 'types/job';

interface OwnProps {
  job: JobType;
}

const JobComments = ({ job }: OwnProps) => {
  const dispatch = useAppDispatch();
  const { comments } = job;

  const form = useForm({ defaultValues: { message: '', isInternal: true } });

  const commentSubmit = (data: CommentPayloadType) => {
    console.log('data', data);
    dispatch(createCommentAction({ params: { id: job.id }, ...data }))
      .then(() => {
        dispatch(getJobDetailsAction({ params: { id: job.id } }));
      })
      .catch(() => {
        toast.error('Unable to post the comment');
      });
  };

  return (
    <Container>
      {comments.map((comment, index) => (
        <EachComment key={index}>
          <CommentMeta isInternal={comment.isInternal} gap={'.5rem'} align={'center'}>
            <Avatar />
            <MetaDetails direction="column" gap={'.2rem'}>
              <h4>{comment.user ? `${comment.user.firstName} ${comment.user.lastName || ''}` : 'Talents & Friends'}</h4>
              <div>
                <span>
                  {formatDate(comment.createdAt, 'DD MMM, HH:mm')} {comment.isInternal ? ' - intern' : ''}
                </span>
                {comment.edited && <span>Last Edited: {formatDate(comment.updatedAt, 'DD MMM, HH:mm')}</span>}
              </div>
            </MetaDetails>
          </CommentMeta>
          {comment.message}
        </EachComment>
      ))}
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(commentSubmit)}>
          <TextArea required placeholder="Enter your comment" {...form.register('message')}></TextArea>
          <span>
            <FormCheckBox name="isInternal" defaultChecked></FormCheckBox> Is internal comment
          </span>
          <ButtonContainer align="center" gap={'1rem'}>
            <SubmitButton>Save Comment</SubmitButton>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default JobComments;

const Container = styled.div`
  padding: 1rem;
`;

const EachComment = styled.div`
  padding: 1rem 0rem;
  border-bottom: 1px solid ${theme.colors.stroke};
  color: ${theme.colors.dark};
  font-size: 1.125rem;

  &.internal {
    background-color: ${theme.colors.lightYellow};
  }
`;

const Form = styled.form`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonContainer = styled(FlexBox)`
  margin-top: 0.5rem;
`;

const TextArea = styled.textarea`
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.colors.stroke};
  background-color: ${theme.colors.lightGray};
  min-height: 100px;

  &:focus,
  &:active {
    border-radius: 30px;
    outline: none;
  }
`;

const CommentMeta = styled(FlexBox)`
  color: ${(props: { isInternal: boolean }) => (props.isInternal ? theme.colors.primary : theme.colors.dark)};
  margin-bottom: 0.5rem;
`;

const MetaDetails = styled(FlexBox)`
  h4 {
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
  }
  > div {
    font-size: 0.75rem;
  }
`;

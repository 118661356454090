import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//
import { useIntl } from 'react-intl';
import { TalentPoolFiltersFormValues } from 'types/talent/entities';
import { Button } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import { Wrapper } from 'components/Layout';
import { SubmitButton } from 'components/base/Button';
import { useAppDispatch } from 'domain/store';
import { createTalentPoolAction } from 'domain/talents/actions';
import { TalentPoolPayload } from 'types/talent';
import { toast } from 'react-toastify';

interface OwnProps {
  onChange: (config: TalentPoolFiltersFormValues) => void;
  config: TalentPoolFiltersFormValues;
  refetch?: () => void;
}

const TalentPoolFilters: React.FC<OwnProps> = ({ onChange, config, refetch }) => {
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const poolForm = useForm<TalentPoolPayload>({ defaultValues: {} });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;
  const [createnewModalOpen, setCreateNewModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange();
    }
  }, [searchValue]);

  const handleSearchChange = () => {
    config.filters.search = searchValue;

    onChange(config);
  };

  const openCreatePoolModal = useCallback(() => {
    setCreateNewModalOpen(true);
  }, []);

  const closeCreatePoolModal = useCallback(() => {
    setCreateNewModalOpen(false);
  }, []);

  const onPoolCreationFormSubmit = useCallback((data: TalentPoolPayload) => {
    dispatch(createTalentPoolAction({ ...data }))
      .unwrap()
      .then(() => {
        toast.success('Talent pool created successfully');
        closeCreatePoolModal();
        refetch?.();
      })
      .catch((err) => {
        toast.error(err?.data?.response?.data?.message || 'Failed to create talent pool! please try again later');
      });
  }, []);

  return (
    <FilterContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <SearchBox>
            <FormTextField name="search" placeholder="Search for a pool" />
          </SearchBox>
        </Form>
      </FormProvider>
      <Button onClick={openCreatePoolModal}>Create New</Button>
      <ModalDialog isOpen={createnewModalOpen} handleClose={closeCreatePoolModal}>
        <CreatePoolForm>
          <FormProvider {...poolForm}>
            <Form onSubmit={poolForm.handleSubmit(onPoolCreationFormSubmit)}>
              <SearchBox>
                <FormTextField name="name" placeholder="Enter name of the pool" />
              </SearchBox>
              <FormFooter>
                <SubmitButton>Submit</SubmitButton>
              </FormFooter>
            </Form>
          </FormProvider>
        </CreatePoolForm>
      </ModalDialog>
    </FilterContainer>
  );
};

export default TalentPoolFilters;
const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  button {
    min-width: 150px;
  }
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

export interface ButtonProps {
  active?: boolean;
}

const Form = styled.form`
  width: 100%;
`;

const CreatePoolForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
`;

const FormFooter = styled.div`
  /* position: fixed; */
  /* bottom: 0px; */
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;

  button {
    min-width: 300px;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { InvoicesList, InvoiceType } from 'types/invoices';

export const fetchInvoicesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<InvoicesList>({
    api: restClient.getInvoices,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const updateInvoiceStatusAction = createAsyncThunk<InvoiceType, Params>(
  'invoices/updateInvoiceStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<InvoiceType>({
      api: restClient.updateInvoiceStatus,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const sendInvoiceReminderAction = createAsyncThunk<void, Params>(
  'invoices/sendInvoiceReminderAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.sendInvoiceReminder,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const cancelInvoiceStatusAction = createAsyncThunk<InvoiceType, Params>(
  'invoices/updateInvoiceStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<InvoiceType>({
      api: restClient.cancelInvoice,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

import InfiniteList from 'components/InfiniteList';
import { fetchApplicationsWorker } from 'domain/applications/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { ApplicationsSortConfigs } from 'types/applications/entities';
import { JobType } from 'types/job';
import { TalentFiltersFormValues } from 'types/talent/entities';
import ApplicationCard from '../applications/ApplicationCard';

interface OwnProps {
  job: JobType;
}

const ConnectedApplications: React.FC<OwnProps> = ({ job }) => {
  const { formatMessage } = useIntl();

  const filterConfig: TalentFiltersFormValues = {
    sort: 'nameAsc',
    filters: { jobId: job?.id || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: applications,
    fetchNextPage,
    refetch,
    //
    pagination,
  } = useInfiniteData({
    queryKey: 'applicationsList',
    //
    fetchResources: fetchApplicationsWorker,
    initialSort: ApplicationsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  return (
    <Container>
      <InfiniteList
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        list={applications}
        ListItem={ApplicationCard}
        componentProps={{ refetchApplications: refetch }}
      />
    </Container>
  );
};

export default ConnectedApplications;


const Container = styled.div`
  margin: 2rem 0rem;
`;


import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PageContainer } from 'components/base';
import { deleteCompanyAction, fetchCompaniesWorker } from 'domain/companies/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { CompanyFiltersFormValues, CompaniesSortConfigs } from 'types/company/entities';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import CompaniesFilters from 'pages/Companies/components/CompaniesFilter';
import { NavLink } from 'react-router-dom';
import { CompanyType } from 'types/company';
import { IconButton } from '@mui/material';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';
import { MdDelete, MdPlayCircle } from 'react-icons/md';
import styled from 'styled-components';

const Demo = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const filterConfig: CompanyFiltersFormValues = {
    sort: 'companyNameAsc',
    filters: { role: 'demo' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: companies,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchCompaniesWorker,
    initialSort: CompaniesSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: CompanyFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(CompaniesSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const startDemo = useCallback((token: string) => {
    window.open(`${process.env.REACT_APP_CLIENT_PUBLIC_URL}demo?token=${token}`, '_blank');
  }, []);

  const deleteDemo = useCallback(
    (id: number) => {
      dispatch(deleteCompanyAction({ params: { id } }))
        .unwrap()
        .then(() => {
          toast.success('Successfully deleted the company');
          refetch();
        })
        .catch(() => {
          toast.error('Failed to end the demo. Please check with the Engineering team');
        });
    },
    [dispatch, refetch],
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => <NavLink to={`/companies/${row.id}`}> {row.name}</NavLink>,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.email }),
          accessor: (row) => `${row.token?.additionalInfo.email || '-'}`,
          id: 'email',
        },
        {
          Header: formatMessage({ id: IntlKeys.password }),
          id: 'credits',
          accessor: (row) => `${row.token?.additionalInfo.password || '-'}`,
        },
        {
          Header: '',
          id: 'actions',
          accessor: (row) => (
            <Actions>
              <IconButton size="small" color="primary" onClick={() => startDemo(row.token?.token)}>
                <MdPlayCircle />
              </IconButton>
              <IconButton size="small" color="error" onClick={() => deleteDemo(row.id)}>
                <MdDelete />
              </IconButton>
            </Actions>
          ),
        },
      ] as InfiniteColumnsType<CompanyType>,
    [deleteDemo, formatMessage, startDemo],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <PageContainer>
      <CompaniesFilters isDemo config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={companies}
        columns={columns}
      />
    </PageContainer>
  );
};

export default Demo;

const Actions = styled.div`
  margin: 0 0 0 1rem;
`;

import { createSlice } from '@reduxjs/toolkit';
import { MessageType } from 'types/messages';
import { MessagesSortConfigs } from 'types/messages/entitties';
import { getMessagesAction, updateMessageStatusAction } from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isSubmitting: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: MessagesSortConfigs.messageSendAtAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as MessageType | null,
  resources: [] as MessageType[],
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getMessagesAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getMessagesAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getMessagesAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })
      //////////////////////////////////////////////////////////////////////////
      .addCase(updateMessageStatusAction.pending, (draft) => {
        draft.meta.isSubmitting = true;
      })
      .addCase(updateMessageStatusAction.fulfilled, (draft, {}) => {
        draft.meta.isSubmitting = false;
      })
      .addCase(updateMessageStatusAction.rejected, (draft) => {
        draft.meta.isSubmitting = false;
      });
  },
});

export const messagesReducer = messagesSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import {
  ConnectSinglePoolPayload,
  ConnectTalentPoolsJobsPayload,
  TalentCommentPayload,
  TalentList,
  TalentPayload,
  TalentPoolList,
  TalentPoolPayload,
  TalentPoolType,
  TalentStatusPayload,
  TalentType,
} from 'types/talent';
//
import { restClient } from 'helpers/http';
import { Params } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { AxiosError } from 'axios';
//

type NetworkErrorType = {
  data: AxiosError;
  requestPayload: Params;
};

export const fetchTalentsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<TalentList>({
    api: restClient.getTalents,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const fetchTalentPoolsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<TalentPoolList>({
    api: restClient.getTalentPools,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getTalentsAction = createAsyncThunk<TalentList, Params | undefined>(
  'companies/getTalentsAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchTalentsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getTalentDetailsAction = createAsyncThunk<TalentType, Params>(
  'companies/getCompanyDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TalentType>({
      api: restClient.getTalentDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createTalentAction = createAsyncThunk<TalentType, TalentPayload>(
  'jobs/createTalentAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TalentType>({
      api: restClient.createTalent,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateTalentAction = createAsyncThunk<TalentType, TalentPayload | Params>(
  'jobs/createTalentAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TalentType>({
      api: restClient.updateTalentDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateTalentStatusAction = createAsyncThunk<TalentType, TalentStatusPayload | Params>(
  'jobs/createTalentAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TalentType>({
      api: restClient.updateTalentStatus,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createTalentPoolAction = createAsyncThunk<TalentPoolType, TalentPoolPayload>(
  'jobs/createTalentPoolAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<TalentPoolType>({
      api: restClient.createTalentPools,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const deleteTalentPoolAction = createAsyncThunk<unknown, Params>(
  'jobs/deleteTalentPoolAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.deletePool,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const deleteTalentAction = createAsyncThunk<unknown, Params>(
  'jobs/deleteTalentPoolAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.deleteTalent,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const connectPoolToCandidateAndJobsAction = createAsyncThunk<unknown, ConnectTalentPoolsJobsPayload>(
  'jobs/connectPoolToCandidateAndJobsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.connectTalentPoolToEntities,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const connectSinglePoolToCandidateAndJobsAction = createAsyncThunk<unknown, ConnectSinglePoolPayload & Params>(
  'jobs/connectSinglePoolToCandidateAndJobsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.updateTalentPoolDetails,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const connectCandidateToPoolAndJobsAction = createAsyncThunk<unknown, ConnectTalentPoolsJobsPayload>(
  'jobs/connectPoolToCandidateAndJobsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<unknown>({
      api: restClient.connectTalentToEntities,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const createTalentCommentAction = createAsyncThunk<void, TalentCommentPayload | Params>(
  'candidate/createTalentCommentAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.createCandidateComments,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const detechTalentFromJob = createAsyncThunk<void, Params>(
  'candidate/detechTalentFromJob',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.detechTalentFromJob,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

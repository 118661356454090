import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { CreateUserPayload, UpdateUserStatusPayload, UserType, UsersList } from 'types/user';

export const fetchUsersWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<UsersList>({
    api: restClient.getUsers,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getUserAction = createAsyncThunk<UserType, Params>(
  'users/getUserAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<UserType>({
      api: restClient.getUser,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createUserAction = createAsyncThunk<void, CreateUserPayload>(
  'users/createUserAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.createUser,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserAction = createAsyncThunk<void, CreateUserPayload | Params>(
  'users/updateUserAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.updateUser,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserStatusAction = createAsyncThunk<void, UpdateUserStatusPayload | Params>(
  'users/updateStatusAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.updateUserStatus,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserRoleAction = createAsyncThunk<void, UpdateUserStatusPayload | Params>(
  'users/updateRoleAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.updateUserRole,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteUserAction = createAsyncThunk<void, Params>(
  'users/deleteUserAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.deleteUser,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'domain/store';
import { AdminFiltersFormValues, AdminsSortConfigs } from 'types/admins/entities';
import { deleteAdminAction, fetchAdminsWorker } from 'domain/admins/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { IntlKeys } from 'localization';
import { AdminType } from 'types/admins';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { ConfirmDialog, PageContainer } from 'components/base';
import AdminsFilter from './components/AdminsFilter';
import PopoverMenu from './components/PopoverMenu';
import ModalDialog from 'components/base/ModalDialog';
import AdminForm from './components/AdminForm';

const Admins = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<null | 'edit' | 'delete'>(null);
  const [currentAdmin, setCurrentAdmin] = useState<null | number>(null);

  const filterConfig: AdminFiltersFormValues = {
    sort: 'adminNameAsc',
    filters: { status: searchParams.get('role') || '' },
  };

  const {
    isLoading,
    resources: admins,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'adminList',
    //
    fetchResources: fetchAdminsWorker,
    initialSort: AdminsSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: AdminFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(AdminsSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const closeModal = useCallback(() => {
    setOpenModal(null);
    setCurrentAdmin(null);
  }, []);

  const handleAdminDelete = useCallback(() => {
    if (currentAdmin) {
      dispatch(deleteAdminAction({ params: { id: currentAdmin } }))
        .unwrap()
        .then(() => {
          toast.success('admin deleted successfully');
          refetch();
        })
        .catch(() => {
          toast.error('failed to delete admin');
        });
    }
    closeModal();
  }, [closeModal, currentAdmin, dispatch, refetch]);

  const columns = useMemo(
    () =>
      [
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => row.fullName,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.email }),
          accessor: (row) => row.email,
          id: 'email',
        },
        {
          Header: formatMessage({ id: IntlKeys.usersRole }),
          accessor: (row) => row.role,
          id: 'role',
        },
        {
          Header: '',
          accessor: (row) => <PopoverMenu admin={row} setCurrentAdmin={setCurrentAdmin} openModal={setOpenModal} />,
          id: 'menu',
          width: 32,
        },
      ] as InfiniteColumnsType<AdminType>,
    [formatMessage],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <PageContainer>
      <AdminsFilter onChange={handleFiltersChange} config={filterConfig} refetch={refetch} />
      <InfiniteTable
        columns={columns}
        data={admins}
        fetchNext={fetchNextPage}
        isLoading={isLoading}
        page={pagination.page}
        pageCount={pagination.pageCount}
      />
      <ModalDialog isOpen={openModal === 'edit'} handleClose={closeModal}>
        <AdminForm adminId={currentAdmin} type="edit" refetch={refetch} closeModal={closeModal} />
      </ModalDialog>
      <ConfirmDialog
        title={'Sure you want to delete this admin'}
        isOpen={openModal === 'delete'}
        onConfirm={handleAdminDelete}
        onCancel={closeModal}
      >
        This action cant be undone!
      </ConfirmDialog>
    </PageContainer>
  );
};

export default Admins;

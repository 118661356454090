import { Pagination } from 'hooks/useInfiniteData';
import { LangCode } from 'localization';
import { FileType } from 'types/common';

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  timezone: string;
  language: LangCode;
  jobTitle: string;
  companyName: string;
  role: UserRoles;
  status: UserStatusEnum;
  avatar?: FileType | null;
  createdAt: string;
  updatedAt: string;
  companyId: number;
};

export interface CreateUserPayload {
  companyId: number;
  avatar?: FileType | null;
  language: LangCode;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface UpdateUserStatusPayload {
  status: UserStatusEnum;
}

export interface UpdateUserStatusPayload {
  role: UserRoles;
}

export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
}

export enum UserStatusEnum {
  UnderReview = 'under_review',
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
}

export interface UsersList extends Pagination {
  data: UserType[];
}

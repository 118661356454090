import { createAsyncThunk } from '@reduxjs/toolkit';
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { SubscriptionPayload, SubscriptionType } from 'types/subscriptions';

export const fetchSubscriptionsWorker = async () => {
  const callApi = createApiCall<SubscriptionType[]>({
    api: restClient.getSubscriptions,
  });

  const { data } = await callApi();
  return data;
};

export const createSubscriptionAction = createAsyncThunk<SubscriptionType, SubscriptionPayload>(
  'subscriptions/createSubscriptionAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<SubscriptionType>({
      api: restClient.createSubscription,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCustomSubscriptionAction = createAsyncThunk<SubscriptionType, SubscriptionPayload | Params>(
  'subscriptions/createSubscriptionAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<SubscriptionType>({
      api: restClient.createCustomSubscription,
    });

    try {
      const { data } = await callApi(requestPayload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSubscriptionsAction = createAsyncThunk<SubscriptionType[], void>(
  'subscriptions/getSubscriptionsAction',
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSubscriptionsWorker();
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const subscribeToAction = createAsyncThunk<void, Params>(
  'subscriptions/subscribeToAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.subscribeTo,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

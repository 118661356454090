import ColorPicker from 'components/ColorPicker';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import VideoDropzone from 'components/Dropzone/VideoDropzone';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Wrapper } from 'components/Layout';
import { getJobDetailsAction, updateJobDetailsAction } from 'domain/jobs/actions';
import { jobsResourceSelector } from 'domain/jobs/selectors';
import { useAppDispatch } from 'domain/store';
import useJobCreation from 'hooks/useJobCreation';
import { IntlKeys } from 'localization';
import { isEqual } from 'lodash';
import { getCustomizationValuesFromCompanyDetails } from 'pages/OrganizationManagement/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useWatch } from 'react-hook-form';
import { MdDeleteForever } from 'react-icons/md';
import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme } from 'theme';
import { Gallery, JobCustomizationPayload } from 'types/job';
import { getCustomizationValuesFromJobDetails, uploadGalleryImages, uploadJobCover } from '../helpers/customization';
import FormFooter from './FormFooter';
import { Divider } from '@mui/material';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import mime from 'mime';
import { companiesResourceSelector } from 'domain/companies/selectors';
import { getCompanyDetailsAction } from 'domain/companies/actions';

interface OwnProps {
  updateUrlQueryParam: (params: Record<string, string>) => void;
  updateStep: (step: number) => void;
  searchParams: URLSearchParams;
}

const Step2: React.FC<OwnProps> = ({ updateUrlQueryParam, updateStep, searchParams }) => {
  const jobDetails = useSelector(jobsResourceSelector);
  const companyDetails = useSelector(companiesResourceSelector);
  const companyDefaults = getCustomizationValuesFromCompanyDetails(companyDetails);
  const [customizationDefaultValues, setCustomizationDefaultValues] = useState<JobCustomizationPayload>(
    getCustomizationValuesFromJobDetails(jobDetails, companyDefaults),
  );

  const { form } = useJobCreation({
    step: 'jobDetails',
    defaultValues: { ...customizationDefaultValues, jobCoverType: 'image' },
  });

  const jobCoverType = useWatch({ name: 'jobCoverType', control: form.control });
  const jobCoverValue = useWatch({ name: 'jobCover', control: form.control });
  const introVideo = useWatch({ name: 'video', control: form.control });
  const jobGalleryUrls = useWatch<Record<string, string[]>, string>({ name: 'jobGalleryUrls', control: form.control });
  const jobGalleryObjs = useWatch({ name: 'jobGalleryObj', control: form.control });
  const uploadedGallery: Gallery[] = useWatch({ name: 'gallery', control: form.control });

  const { formatMessage } = useIntl();
  const jobId = searchParams.get('jobId');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (jobDetails?.companyId) {
      dispatch(getCompanyDetailsAction({ params: { id: jobDetails.companyId } }));
    }
  }, [dispatch, jobDetails?.companyId]);

  useEffect(() => {
    if (jobDetails) {
      const companyDefaults = getCustomizationValuesFromCompanyDetails(companyDetails);
      const defaultValues = getCustomizationValuesFromJobDetails(jobDetails, companyDefaults);
      const mimeType = defaultValues.cover?.url ? mime.getType(defaultValues.cover.url) : 'image';
      const jobCoverType = mimeType?.startsWith('video') ? 'video' : 'image';
      setCustomizationDefaultValues(defaultValues);
      form.reset({ ...defaultValues, jobCoverType });
    }
  }, [jobDetails, companyDetails, form]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const [cover, gallery] = await Promise.all([
        uploadJobCover(data.jobCover, customizationDefaultValues?.cover || null),
        // uploadIntroVideo(data.introVideo, jobDetails?.video),
        uploadGalleryImages(data.jobGalleryObj),
      ]);

      const payload = {
        customColor: data.customColor,
        customBackgroundColor: data.customBackgroundColor,
        cover,
        video: data.video.url ? data.video : null,
        gallery: [...(data.gallery || []), ...gallery?.map((file) => ({ ...file, type: 'image' }))],
      } as JobCustomizationPayload;

      setTimeout(() => {
        // TODO: the header changes in the uploadFile actions are getting cached and making this call return CORS error.
        dispatch(
          updateJobDetailsAction({
            ...payload,
            params: {
              id: jobId as string,
            },
          }),
        )
          .unwrap()
          .then(() => {
            if (jobId) {
              updateUrlQueryParam({ jobId, step: '3' });
              updateStep(3);
            }
            dispatch(getJobDetailsAction({ params: { id: jobId! } }));
          });
      }, 500);
    },
    [customizationDefaultValues?.cover, dispatch, jobId, updateStep, updateUrlQueryParam],
  );

  const showPreview = useCallback(() => {
    window.open(`${process.env.REACT_APP_CLIENT_PUBLIC_URL}jobs/${jobId}/apply`, '_blank');
  }, [jobId]);

  const handleJobCoverUpload = useCallback(
    (jobCoverMedia: Record<string, unknown>) => {
      form.setValue('jobCover', jobCoverMedia);
    },
    [form],
  );

  const removeCover = useCallback(() => {
    form.setValue('jobCover', null);

    setCustomizationDefaultValues({ ...customizationDefaultValues, cover: null });
  }, [customizationDefaultValues, form]);

  const removeGalleryImage = useCallback(
    (image?: string) => {
      if (!image) return;
      const updatedGallery = uploadedGallery.filter((gallery) => gallery.url !== image) || [];
      const newUploadedGalleryObjs = jobGalleryObjs?.filter((img: { src: string }) => img.src !== image);

      if (!isEqual(newUploadedGalleryObjs, jobGalleryObjs)) {
        form.setValue('jobGalleryObj', newUploadedGalleryObjs);
        form.setValue('jobGalleryUrls', newUploadedGalleryObjs?.map((file: { src: string }) => file?.src) || []);
      }

      if (updatedGallery.length !== uploadedGallery?.length) {
        form.setValue('gallery', updatedGallery);
      }
    },
    [uploadedGallery, jobGalleryObjs, form],
  );

  const handleGalleryUpdate = useCallback(
    (galleryFiles: File[]) => {
      const galleryObjects = [
        ...(form.getValues('jobGalleryObj') || []),
        ...galleryFiles.map((file) => ({
          fileObj: file,
          src: URL.createObjectURL(file),
          name: file.name,
        })),
      ];
      form.setValue('jobGalleryObj', galleryObjects);
      form.setValue(
        'jobGalleryUrls',
        galleryObjects.map((file) => file.src),
      );
    },
    [form],
  );

  const jobCoverOptions = [
    {
      label: formatMessage({ id: IntlKeys.image }),
      id: 'image',
      value: 'image',
    },
    {
      label: formatMessage({ id: IntlKeys.video }),
      id: 'video',
      value: 'video',
    },
  ];

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <FormContainer>
          <EachInput label={formatMessage({ id: IntlKeys.jobCover })}>
            <FormRadioSwitch name="jobCoverType" options={jobCoverOptions} />
            <Divider />
            {jobCoverType === 'image' ? (
              <ImageDropzone
                onDrop={(acceptedFiles) =>
                  handleJobCoverUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                maxFiles={1}
                height={200}
                src={customizationDefaultValues?.cover?.url || jobCoverValue?.src}
                multiple={false}
                previewClassName={'job-cover-preview'}
                onRemove={removeCover}
                cropConfig={{ aspectRatio: 1.1 }}
              />
            ) : (
              <VideoDropzone
                onDrop={(acceptedFiles) =>
                  handleJobCoverUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                maxFiles={1}
                height={200}
                src={customizationDefaultValues?.cover?.url || jobCoverValue?.src}
                multiple={false}
                previewClassName={'job-cover-preview'}
              />
            )}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.introVideo })}>
            <FormTextField name={'video.url'} placeholder={formatMessage({ id: IntlKeys.addLinkToIntroVideo })} />

            {introVideo ? (
              <VideoWrapper>
                <ReactPlayer url={introVideo?.url} width={'100%'} wrapper={VideoPlayer} />
              </VideoWrapper>
            ) : null}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.gallery })}>
            {[...(uploadedGallery || []), ...(jobGalleryUrls || [])]?.[0] ? (
              <GalleryContainer>
                {[...(uploadedGallery?.map((gallary) => gallary.url) || []), ...(jobGalleryUrls || [])].map(
                  (image: string, index: number) => (
                    <GalleryEachItem key={`${image}-${index}`}>
                      <RemoveContainer onClick={() => removeGalleryImage(image)} className={'remove-image'}>
                        <MdDeleteForever />
                      </RemoveContainer>
                      <Image src={image} />
                    </GalleryEachItem>
                  ),
                )}
                <GalleryEachItem>
                  <ImageDropzone
                    onDrop={(acceptedFiles) => handleGalleryUpdate(acceptedFiles)}
                    maxFiles={5}
                    height={200}
                    multiple={true}
                  />
                </GalleryEachItem>
              </GalleryContainer>
            ) : (
              <ImageDropzone
                onDrop={(acceptedFiles) => handleGalleryUpdate(acceptedFiles)}
                maxFiles={5}
                height={200}
                multiple={true}
              />
            )}
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.customBackgroundColor })}>
            <ColorPicker name="customBackgroundColor" />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.customColor })}>
            <ColorPicker name="customColor" />
          </EachInput>
        </FormContainer>
        <FormFooter showPreview={showPreview} />
      </Form>
    </FormProvider>
  );
};

export default Step2;

const Form = styled.form`
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;

  .job-cover-preview {
    border-radius: 20px;

    img {
      border-radius: 20px;
      max-width: 100%;
    }
  }
`;

const FormContainer = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 100%;
  gap: 0.5rem;
`;

const Image = styled.img`
  max-width: 100%;
`;

const GalleryEachItem = styled.div`
  max-width: 100%;
  height: 240px;
  background-color: ${theme.colors.lightGray};
  border-radius: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .remove-image {
    display: none;
  }

  &:hover {
    .remove-image {
      display: flex;
    }
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 2rem 0rem;
  overflow: hidden;
`;

const VideoPlayer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 36px;
  height: 36px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

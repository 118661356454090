import ImageDropzone from 'components/Dropzone/ImageDropzone';
import { Wrapper } from 'components/Layout';
import { Button, SubmitButton } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { useAppDispatch } from 'domain/store';
import { createUserAction, getUserAction, updateUserAction } from 'domain/users/actions';
import { IntlKeys } from 'localization';
import { MediaFileObj, uploadMedia } from 'pages/Companies/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { CreateUserPayload, UserType } from 'types/user';

interface OwnProps {
  closeModal: () => void;
  refetch: () => void;
  companyId: number;
  userId: number | null;
}

const CreateUserModal: React.FC<OwnProps> = ({ closeModal, companyId, refetch, userId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userForm = useForm<CreateUserPayload & { avatarObj?: MediaFileObj }>({
    defaultValues: { companyId },
  });
  const avatarObj = useWatch({ name: 'avatarObj', control: userForm.control });
  const uploadedAvatar = useWatch({ name: 'avatar', control: userForm.control });

  useEffect(() => {
    if (userId) {
      dispatch(getUserAction({ params: { id: userId } }))
        .unwrap()
        .then((response: UserType) => {
          userForm.reset({ ...response });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const roleOptions = [
    {
      label: formatMessage({ id: IntlKeys.usersRoleUser }),
      value: 'user',
    },
    {
      label: formatMessage({ id: IntlKeys.usersRoleAdmin }),
      value: 'admin',
    },
  ];
  const onUserFormSubmit = useCallback(
    async (data: CreateUserPayload & { avatarObj?: MediaFileObj }) => {
      setIsSubmitting(true);
      const { avatarObj, ...formValues } = data;
      const [avatar] = await Promise.all([uploadMedia(avatarObj, data.avatar)]);
      const action = userId
        ? updateUserAction({ params: { id: userId }, ...formValues, avatar })
        : createUserAction({ ...formValues, avatar });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success(formatMessage({ id: IntlKeys.usersCreateSuccessfully }));
          setIsSubmitting(false);
          closeModal();
          refetch();
        })
        .catch((err) => {
          toast.error(`${formatMessage({ id: IntlKeys.usersCreateFailure })}: ${err.message}`);
          setIsSubmitting(false);
        });
    },
    [closeModal, dispatch, formatMessage, refetch, userId],
  );

  const handleAvatarUpload = useCallback(
    (avatarObj: MediaFileObj) => {
      userForm.setValue('avatarObj', avatarObj);
    },
    [userForm],
  );

  const removeLogo = () => {
    userForm.setValue('avatarObj', undefined);
    userForm.setValue('avatar', undefined);
  };
  return (
    <CreateUserForm>
      <h2>{formatMessage({ id: IntlKeys.usersCreate })}</h2>
      <FormProvider {...userForm}>
        <Form onSubmit={userForm.handleSubmit(onUserFormSubmit)}>
          <EachInput label={formatMessage({ id: IntlKeys.firstName })}>
            <FormTextField
              name={'firstName'}
              placeholder={formatMessage({ id: IntlKeys.firstNamePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.name })}>
            <FormTextField
              name={'lastName'}
              placeholder={formatMessage({ id: IntlKeys.namePH })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.email })}>
            <FormTextField
              name={'email'}
              type="email"
              placeholder={formatMessage({ id: IntlKeys.email })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          {!userId && (
            <EachInput label={formatMessage({ id: IntlKeys.password })}>
              <FormTextField
                name={'password'}
                type="password"
                placeholder={formatMessage({ id: IntlKeys.enterPassword })}
                rules={{
                  required: formatMessage({ id: IntlKeys.requiredItem }),
                }}
              />
            </EachInput>
          )}
          <EachInput label={formatMessage({ id: IntlKeys.usersRole })}>
            <FormAutoComplete
              options={roleOptions}
              placeholder={formatMessage({ id: IntlKeys.usersRolePlaceholder })}
              name={'role'}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: IntlKeys.avatar })}>
            <ImageDropzone
              onDrop={(acceptedFiles) =>
                handleAvatarUpload({
                  fileObj: acceptedFiles[0],
                  src: URL.createObjectURL(acceptedFiles[0]),
                  name: acceptedFiles[0].name,
                })
              }
              maxFiles={1}
              height={200}
              src={uploadedAvatar?.url || avatarObj?.src}
              multiple={false}
              previewClassName={'comapny-logo-preview'}
              onRemove={removeLogo}
              cropConfig={{ aspectRatio: 1 }}
            />
          </EachInput>
          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              {formatMessage({ id: IntlKeys.submit })}
            </SubmitButton>
            <Button variant="outline" onClick={closeModal}>
              {formatMessage({ id: IntlKeys.cancel })}
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </CreateUserForm>
  );
};

export default CreateUserModal;

const CreateUserForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;
const Form = styled.form`
  width: 100%;
`;

const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;
const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

import { Avatar, List, ListItem, ListItemButton, ListItemText, Popover, Tooltip } from '@mui/material';
import { Card, FlexBox, SecText, Title3 } from 'components/base';
import { updateJobDetailsAction, updateJobWorkflowAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import { formatCurrency } from 'helpers/number';
import { nameToInitials } from 'helpers/string';
import { IntlKeys } from 'localization';
import React, { useCallback, useState } from 'react';
import { MdDragIndicator } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobStatus, JobType, JobWorkflow } from 'types/job';
import UserList from './components/UserList';

interface OwnProps {
  data: unknown;
  workflowStatus?: JobWorkflow['status'];
  refetch?: () => void;
}

const colors = [theme.colors.maroon, theme.colors.success, theme.colors.warning];

const JobCard: React.FunctionComponent<OwnProps> = ({ data, workflowStatus, refetch }) => {
  const job = data as JobType;
  const hiddenApplicationCount = job.applicationCount - 3; // We are showing upto 3 users avatar
  const [onHold, setOnHold] = useState(false);
  const { workflow } = job;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('cardInfo', JSON.stringify({ id: job.id, status: workflowStatus }));
    (e.target as HTMLDivElement).className += ' ohhold';
    setTimeout(() => {
      setOnHold(true);
    }, 0);
  };
  const dragEndHandler = () => {
    setOnHold(false);
  };
  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if ((e.target as HTMLDivElement).className === 'card') {
      setTimeout(() => {
        (e.target as HTMLDivElement).className = 'card anotherCardOnTop';
      }, 0);
    }
  };
  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    resetClassName(e);
  };
  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    resetClassName(e);
    /**
     TODO: Remove all anotherCardOnTop classnames
     from DOM after drop complete.
    */
  };

  const resetClassName = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const isCard =
      (e.target as HTMLDivElement).className === 'card' ||
      (e.target as HTMLDivElement).className === 'card anotherCardOnTop';
    if (isCard) {
      setTimeout(() => {
        (e.target as HTMLDivElement).className = 'card';
      }, 0);
    }
  };

  const handleOpenAssineeModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setAnchorEl(e.currentTarget);
  };

  const handleOpenStatusModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setStatusAnchorEl(e.currentTarget);
  };

  const handleCloseStatusModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setStatusAnchorEl(null);
  };

  const onStatusChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, status: JobStatus) => {
      dispatch(updateJobDetailsAction({ params: { id: job.id }, status }))
        .unwrap()
        .then(() => {
          refetch?.();
        });
      handleClose(e as React.MouseEvent<HTMLDivElement>);
    },
    [dispatch, job.id, refetch],
  );

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setAnchorEl(null);
  };

  const navigateToJobDetails = () => {
    console.log('everythig comes here');
    navigate(`/jobs/${job.id}`);
    // navigate('/candidates');
  };

  const onSelectAssignee = useCallback(
    (e: React.MouseEvent<HTMLElement>, userId: number) => {
      dispatch(updateJobWorkflowAction({ params: { id: job.id }, assignedTo: userId }))
        .unwrap()
        .then(() => {
          refetch?.();
        });
      handleClose(e as React.MouseEvent<HTMLDivElement>);
    },
    [dispatch, job.id, refetch],
  );

  return (
    <Container
      width={'100%'}
      withBorder
      className={`card ${onHold ? 'hidden' : ''}`}
      draggable="true"
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onDragOver={onDragOverHandler}
      onDragLeave={onDragLeaveHandler}
      onDrop={onDropHandler}
      borderColor={job.priority ? theme.colors.primary : ''}
    >
      <CardWrapper gap={'1rem'} direction="column" onClick={navigateToJobDetails}>
        <CompanyName>
          <div>
            <MdDragIndicator />
            {job?.company?.name}
          </div>
          <Invoices unpaidInvoicesCount={job?.company?.unPaidInvoicesCount || 0}>{`unpaid invoices: ${
            job?.company?.unPaidInvoicesCount || 0
          }`}</Invoices>
        </CompanyName>
        <JobHead align="center" justify="space-between">
          <div>
            <Title3>{job.title}</Title3>
            <ApplicationCount>
              <strong>{job.applicationCount || 0}</strong>/{job.maxCandidates || 0} Candidates provided
            </ApplicationCount>
          </div>
          <Assignee onClick={handleOpenAssineeModal}>
            <Tooltip title={workflow?.assignee ? workflow.assignee?.fullName : 'Click to assign to user'}>
              {workflow?.assignedTo ? (
                <Avatar sx={{ bgcolor: theme.colors.primary, width: 32, height: 32, fontSize: '.85rem' }}>
                  {nameToInitials(`${workflow.assignee?.fullName}`)}
                </Avatar>
              ) : (
                <Avatar sx={{ width: 32, height: 32 }} />
              )}
            </Tooltip>
          </Assignee>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <UserList onSelectAssignee={onSelectAssignee} />
          </Popover>
        </JobHead>
        <JobDescription>
          <FormattedMessage
            id={IntlKeys.jobDescription}
            values={{
              minSalary: formatCurrency(job.minSalary),
              contractType: job.contractType,
              maxSalary: formatCurrency(job.maxSalary),
              maxCandidates: job.maxCandidates,
              location: job.city,
              endDate: '31.12.2022',
              b: (children) => <strong>{children}</strong>,
            }}
          />
        </JobDescription>
        <JobCardFooter>
          <Applicants gap={'3px'}>
            {job.applications?.length ? (
              <>
                {job.applications?.slice(0, 3).map((applicant, index) => (
                  <EachApplicant
                    bgColor={colors[index]}
                    key={applicant?.firstName + index}
                    align="center"
                    justify="center"
                  >
                    {nameToInitials(`${applicant.firstName} ${applicant.lastName}`)}
                  </EachApplicant>
                ))}
                {hiddenApplicationCount > 0 && (
                  <EachApplicant
                    bgColor={theme.colors.lightGray}
                    color={theme.colors.dark}
                    align="center"
                    justify="center"
                  >
                    {`+${hiddenApplicationCount}`}
                  </EachApplicant>
                )}
              </>
            ) : (
              <NoCandidates>No candiates yet</NoCandidates>
            )}
          </Applicants>
          <Tooltip title="Click to change status" onClick={handleOpenStatusModal}>
            <StatusLabel status={job.status}>{job.status}</StatusLabel>
          </Tooltip>
          <Popover
            open={Boolean(statusAnchorEl)}
            anchorEl={statusAnchorEl}
            onClose={handleCloseStatusModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <StatusList>
              <ListItem disablePadding>
                <ListItemButton onClick={(e) => onStatusChange(e, 'published')}>
                  <ListItemText primary="Published" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={(e) => onStatusChange(e, 'draft')}>
                  <ListItemText primary="Draft" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={(e) => onStatusChange(e, 'archived')}>
                  <ListItemText primary="Archived" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={(e) => onStatusChange(e, 'recruting-done')}>
                  <ListItemText primary="Done" />
                </ListItemButton>
              </ListItem>
            </StatusList>
          </Popover>
        </JobCardFooter>
      </CardWrapper>
    </Container>
  );
};

export default JobCard;

const Container = styled(Card)`
  min-height: 264px;
  display: flex;
  cursor: pointer;
`;
const StatusList = styled(List)`
  width: 200px;
  padding: 0;
`;

const CardWrapper = styled(FlexBox)`
  flex: 1;
`;

const JobHead = styled(FlexBox)`
  width: 100%;
  h3 {
    font-size: 1.25rem;
  }
`;

const JobDescription = styled.div`
  flex: 1;
  color: ${theme.colors.gray};

  & strong {
    color: ${theme.colors.secondary};
    font-weight: 400;
  }
`;

const ApplicationCount = styled(SecText)`
  line-height: 1rem;

  & strong {
    color: ${theme.colors.secondary};
  }
`;

const JobCardFooter = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Applicants = styled(FlexBox)``;

const EachApplicant = styled(FlexBox)`
  background-color: ${(props: { bgColor: string }) => props.bgColor};
  border-radius: 4px;
  color: ${(props: { color?: string }) => props.color ?? theme.colors.white};
  height: 36px;
  width: 36px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
`;

const NoCandidates = styled(SecText)`
  line-height: 36px;
`;

const Assignee = styled.div`
  cursor: pointer;
`;

const Invoices = styled.div`
  display: ${(props: { unpaidInvoicesCount: number }) => (props.unpaidInvoicesCount > 0 ? 'block' : 'none')};
  color: ${theme.colors.warning};
`;

const CompanyName = styled.div`
  background: ${theme.colors.maroon};
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
  margin-top: -1rem;
  padding: 0.5rem 1rem;
  color: ${theme.colors.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight: 500;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const StatusLabel = styled.div`
  background-color: ${(props: { status: string }) =>
    props.status === 'draft' ? theme.colors.warning : theme.colors.primary};
  padding: 0.3rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 5px;
  color: ${theme.colors.white};
`;

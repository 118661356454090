import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { InvoiceType } from 'types/invoices';
import { formatCurrency } from 'helpers/number';

const borderColor = '#F7F8FF';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  description: {
    width: '50%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  tax: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  rate: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

interface OwnProps {
  invoice: InvoiceType;
}

const InvoiceTableRow: React.FC<OwnProps> = ({ invoice }) => {
  return (
    <Fragment>
      {
        <View style={styles.row}>
          <Text style={styles.description}>{invoice.item}</Text>
          <Text style={styles.qty}>{invoice.quantity}</Text>
          <Text style={styles.rate}>{formatCurrency(invoice.perItemPrice)}</Text>
          <Text style={styles.tax}>{invoice.valueTax}</Text>
          <Text style={styles.amount}>{formatCurrency(invoice.quantity * invoice.perItemPrice)}</Text>
        </View>
      }
    </Fragment>
  );
};

export default InvoiceTableRow;

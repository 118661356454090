import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  benefitNameAsc: {
    field: 'name',
    order: 'ASC',
  },
  benefitNameDesc: {
    field: 'name',
    order: 'DESC',
  },
};

export const BenefitsSortConfics = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type BenefitFiltersFormValues = FilterFormValues<keyof typeof BenefitsSortConfics>;

export const BenefitSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'benefitNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'benefitNameDesc',
    },
  ];
};

import { useAppDispatch } from 'domain/store';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ChargeCreditPayload } from 'types/credits';
import { chargeCreditsAction } from 'domain/credits/actions';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { Wrapper } from 'components/Layout';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { IntlKeys } from 'localization';
import FormAutoComplete from 'components/hook-form/fields/FormAutoComplete';
import { Button } from '@mui/material';
import { SubmitButton } from 'components/base';

interface OwnProps {
  closeModal: () => void;
  refetch?: () => void;
  companyId: number;
}

const ChargeCreditModal: React.FC<OwnProps> = ({ closeModal, refetch, companyId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const creditForm = useForm<ChargeCreditPayload>({ defaultValues: { companyId: companyId } });

  const CreditTransactionReasonOptions = [
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonPurchaseCredits }), value: 'purchase_credits' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonGiftedCredits }), value: 'credits_gifted' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonCanditate }), value: 'candidate' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonInterview }), value: 'interview' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonWithdrawl }), value: 'withdrawl' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonReferences }), value: 'references' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonAssessment }), value: 'assesment' },
    { label: formatMessage({ id: IntlKeys.creditHistoryReasonAts }), value: 'ats' },
  ];

  const onCreditFormSubmit = useCallback(
    async (data: ChargeCreditPayload) => {
      setIsSubmitting(true);
      const action = chargeCreditsAction(data);
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('Credit charged successfully');
          closeModal?.();
          refetch?.();
          setIsSubmitting(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsSubmitting(false);
        });
    },
    [closeModal, dispatch, refetch],
  );
  return (
    <ChargeCreditForm>
      <h2>Charge Credit</h2>
      <FormProvider {...creditForm}>
        <Form onSubmit={creditForm.handleSubmit(onCreditFormSubmit)}>
          <EachInput label={formatMessage({ id: 'Credits' })}>
            <FormTextField
              name={'amount'}
              placeholder={formatMessage({ id: 'Enter credits' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Reason' })}>
            <FormAutoComplete
              freeSolo
              name={'reason'}
              placeholder={formatMessage({ id: 'Select Reason' })}
              options={CreditTransactionReasonOptions}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Comment' })}>
            <FormTextField name={'comment'} placeholder={formatMessage({ id: 'comment' })} />
          </EachInput>
          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              Submit
            </SubmitButton>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </ChargeCreditForm>
  );
};

export default ChargeCreditModal;

const ChargeCreditForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;
const Form = styled.form`
  width: 100%;
`;

const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;
const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

import { PaginationType } from 'helpers/http';
import { CompanyType } from 'types/company';
import { TalentType } from 'types/talent';

export type CreditTransactionType = {
  id: number;
  amount: number;
  reason: TransactionReason;
  comment: string;
  createdAt: string;
  company: CompanyType;
  candidate: TalentType;
};

export interface CreditTransactionsList extends PaginationType {
  data: CreditTransactionType[];
}

export enum TransactionReason {
  SUBSCRIPTION = 'subscription',
  PURCHASE_CREDITS = 'purchase_credits',
  CREDITS_GIFTED = 'credits_gifted',
  CANDIDATE = 'candidate',
  INTERVIEW = 'interview',
  WITHDRAWL = 'withdrawl',
  REFERENCES = 'references',
  ASSESMENT = 'assesment',
  ATS = 'ats',
}

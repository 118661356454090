import { Button, Typography } from '@mui/material';
import { FlexBox } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import { Loader } from 'components/loader';
import { useAppDispatch } from 'domain/store';
import { getSubscriptionsAction, subscribeToAction } from 'domain/subscriptions/actions';
import { subscriptionsMetaSelector, subscriptionsResourcesSelector } from 'domain/subscriptions/selectors';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { CompanyType } from 'types/company';
import { SubscriptionType } from 'types/subscriptions';
import CreateSubscriptionModal from '../components/CreateSubscriptionModal';

interface OwnProps {
  company: CompanyType;
  refetch?: () => void;
}

const CompanySubscription: React.FC<OwnProps> = ({ company, refetch }) => {
  const { formatMessage } = useIntl();
  const [createSubscriptionModal, setCreateSubscriptionModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [subscription, setSubscription] = React.useState<SubscriptionType | null>(company.subscription);
  const subscriptions = useSelector(subscriptionsResourcesSelector);
  const subscriptionsMeta = useSelector(subscriptionsMetaSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!subscriptions?.length) {
      dispatch(getSubscriptionsAction());
    }
  }, [dispatch, subscriptions?.length]);

  useEffect(() => {
    if (!subscription && subscriptions.length) {
      const sub = subscriptions.find(({ id }) => id === company.subscriptionId);
      setSubscription(sub || null);
    }
  }, [subscriptions, subscription, company.subscriptionId]);

  const makeSubscription = (subscriptionId: number) => {
    setIsSubmitting(true);
    dispatch(subscribeToAction({ params: { companyId: company.id, id: subscriptionId } }))
      .unwrap()
      .then(() => {
        toast.success('Subscription successful');
        setIsSubmitting(false);
        setSubscription(subscriptions?.find(({ id }) => id === subscriptionId) || null);
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error(err?.data?.response?.data?.message || 'Failed to create company! please try again later');
      });
  };

  if (subscriptionsMeta.isLoading || isSubmitting) {
    return <Loader />;
  }
  return (
    <Container>
      <h2>
        {subscription ? formatMessage({ id: 'Selected Subscription' }) : formatMessage({ id: 'Select a Subscription' })}
        <Typography> - Or - </Typography>
        <Button variant="contained" onClick={() => setCreateSubscriptionModal(true)}>
          Create Subscription
        </Button>
      </h2>
      <SubscriptionBoxes>
        {subscription && !subscriptions.find(({ id }) => id === company.subscriptionId) && (
          <SubscriptionBox
            key={subscription.id}
            isSelected={subscription.id === company.subscriptionId}
            onClick={() => makeSubscription(subscription.id)}
          >
            <h4>{subscription.name}</h4>
            <FeatureTable>
              <tr>
                <td>Credit Price</td>
                <td>{subscription.perCreditsPrice} €</td>
              </tr>
              <tr>
                <td>Min Credits</td>
                <td>{subscription.includedCredits}</td>
              </tr>
              <tr>
                <td>max Jobs</td>
                <td>{subscription.maxJobs}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{subscription.period}</td>
              </tr>
              {Object.entries(subscription.services).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value ? 'yes' : 'no'}</td>
                </tr>
              ))}
            </FeatureTable>
          </SubscriptionBox>
        )}
        {subscriptions?.map((sub) => (
          <SubscriptionBox
            key={sub.id}
            isSelected={sub.id === subscription?.id}
            onClick={() => makeSubscription(sub.id)}
          >
            <h4>{sub.name}</h4>
            <FeatureTable>
              <tr>
                <td>Credit Price</td>
                <td>{sub.perCreditsPrice} €</td>
              </tr>
              <tr>
                <td>Min Credits</td>
                <td>{sub.includedCredits}</td>
              </tr>
              <tr>
                <td>max Jobs</td>
                <td>{sub.maxJobs}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{sub.period}</td>
              </tr>
              {Object.entries(sub.services).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value ? 'yes' : 'no'}</td>
                </tr>
              ))}
            </FeatureTable>
          </SubscriptionBox>
        ))}
      </SubscriptionBoxes>
      <ModalDialog isOpen={createSubscriptionModal} handleClose={() => setCreateSubscriptionModal(false)}>
        <CreateSubscriptionModal
          closeModal={() => setCreateSubscriptionModal(false)}
          companyId={company.id}
          refetch={refetch}
        />
      </ModalDialog>
    </Container>
  );
};
export default CompanySubscription;

const Container = styled.div`
  text-align: center;
`;

const SubscriptionBoxes = styled(FlexBox)`
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`;
const SubscriptionBox = styled.div`
  padding: 0.5rem 1rem;
  border: ${(props: { isSelected: boolean }) =>
    props.isSelected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`};
  min-width: 300px;
  border-radius: 10px;
  font-size: 1.25rem;
  color: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.white : theme.colors.secondary)};
  background: ${(props: { isSelected: boolean }) => (props.isSelected ? theme.colors.primary : 'transparent')};
  cursor: pointer;
  flex: 1;
  width: 100%;
  :hover {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
`;

const FeatureTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  td {
    padding: 0.5rem 0;
  }
`;

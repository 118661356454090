import React, { useEffect, useState } from 'react';
import { MessageStatus } from 'types/messages';
import { MessageFiltersFormValues } from 'types/messages/entitties';
import styled, { css } from 'styled-components';
import { theme } from 'theme';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps {
  onChange: (config: MessageFiltersFormValues) => void;
  config: MessageFiltersFormValues;
}

const MessagesFilter: React.FC<OwnProps> = ({ onChange, config }) => {
  const { formatMessage } = useIntl();
  const [selectedStatus, setSelectedStatus] = useState(config.filters.status || '');

  const handleStatusChange = (status: MessageStatus) => {
    const newStatus = status == selectedStatus ? '' : status;
    config.filters.status = newStatus;
    onChange(config);
    setSelectedStatus(newStatus);
  };

  useEffect(() => {
    if (config.filters.status !== selectedStatus) {
      setSelectedStatus(config.filters.status);
    }
  }, [config, selectedStatus]);

  type MessageFilter = { label: string; value: MessageStatus };

  const messageFilters: MessageFilter[] = [
    {
      label: formatMessage({ id: IntlKeys.messagesStatusNew }),
      value: 'new',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesStatusRead }),
      value: 'reviewed',
    },
    {
      label: formatMessage({ id: IntlKeys.messagesStatusArchived }),
      value: 'archived',
    },
  ];

  return (
    <Container>
      <FilterContainer>
        {messageFilters.map((filter) => (
          <EachFilter
            key={filter.value}
            onClick={() => handleStatusChange(filter.value)}
            active={selectedStatus == filter.value}
          >
            {filter.label}
          </EachFilter>
        ))}
      </FilterContainer>
    </Container>
  );
};

export default MessagesFilter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  gap: 4px;
`;

export interface ButtonProps {
  active?: boolean;
}

const EachFilter = styled.button`
  background-color: ${theme.colors.white};
  border: none;
  color: ${theme.colors.dark};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors};
    `}
`;

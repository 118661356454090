import { PaginationType } from 'helpers/http';
import { LangCode } from 'localization';
import { FileType } from 'types/common';

export enum AdminRolesEnum {
  Admin = 'admin',
  Sales = 'sales',
  Recruiter = 'recruiter',
  User = 'user',
}

export type AdminType = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  language: LangCode;
  role: AdminRolesEnum;
  avatar?: FileType | null;
  createdAt: string;
  updatedAt: string;
};

export interface AdminList extends PaginationType {
  data: AdminType[];
}

export interface AdminPayload {
  firstName: string;
  lastName: string;
  email: string;
  language?: LangCode;
  role?: AdminRolesEnum;
  avatar?: FileType | null;
}

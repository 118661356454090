// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  nameAsc: {
    field: 'name',
    order: 'ASC',
  },
  nameDesc: {
    field: 'name',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const ApplicationsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type ApplicationsFiltersFormValues = FilterFormValues<keyof typeof ApplicationsSortConfigs>;

export const ApplicationsSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobTitleAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobTitleDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobCandidatesAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobCandidatesDesc',
    },
  ];
};

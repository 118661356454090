import { Checkbox } from '@mui/material';
import { ConfirmDialog, PageContainer } from 'components/base';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { deleteCompanyAction, fetchCompaniesWorker } from 'domain/companies/actions';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useSearchParams } from 'react-router-dom';
import { CompanyType } from 'types/company';
import { CompaniesSortConfigs, CompanyFiltersFormValues } from 'types/company/entities';
import CompaniesFilters from './components/CompaniesFilter';
import ModalDialog from 'components/base/ModalDialog';
import CreateEditCompanyForm from './components/CreateEditCompanyForm';
import PopoverMenu from './components/PopoverMenu';
import { useAppDispatch } from 'domain/store';
import { toast } from 'react-toastify';

const Companies = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedRows, setCheckedRows] = useState<Array<number>>([]);
  const [isCheckedAllTalents, setIsCheckedAllTalents] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState<null | 'edit' | 'delete'>(null);
  const [currentlyEdittingCompany, setCurrentlyEdittingCompany] = useState<null | number>(null);
  const dispatch = useAppDispatch();

  const filterConfig: CompanyFiltersFormValues = {
    sort: 'companyNameAsc',
    filters: { status: searchParams.get('status') || '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: companies,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'companyJobsList',
    //
    fetchResources: fetchCompaniesWorker,
    initialSort: CompaniesSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: CompanyFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(CompaniesSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const handleCheckCompany = useCallback(
    (value: number) => {
      if (checkedRows.includes(value)) {
        setCheckedRows(checkedRows.filter((i) => i !== value));
        setIsCheckedAllTalents(false);
      } else {
        setCheckedRows([...checkedRows, value]);
        if (checkedRows.length + 1 === companies.length) {
          setIsCheckedAllTalents(true);
        }
      }
    },
    [checkedRows, companies.length],
  );

  const closeEditCompanyModal = useCallback(() => {
    setOpenModal(null);
    setCurrentlyEdittingCompany(null);
  }, []);

  const handleCompanyDelete = useCallback(() => {
    if (currentlyEdittingCompany) {
      dispatch(deleteCompanyAction({ params: { id: currentlyEdittingCompany } }))
        .unwrap()
        .then(() => {
          toast.success('company deleted successfully');
          refetch();
        })
        .catch(() => {
          toast.error('failed to delete company');
        });
    }
    closeEditCompanyModal();
  }, [closeEditCompanyModal, currentlyEdittingCompany, dispatch, refetch]);

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <Checkbox
              style={{ marginTop: '-10px' }}
              checked={isCheckedAllTalents}
              onChange={() => {
                setIsCheckedAllTalents(!isCheckedAllTalents);
                isCheckedAllTalents ? setCheckedRows([]) : setCheckedRows(companies.map((company) => company.id));
              }}
            />
          ),
          accessor: (row) => row.id,
          Cell: ({ value }) => (
            <Checkbox checked={checkedRows.includes(value)} onChange={() => handleCheckCompany(value)} />
          ),
          style: {
            maxWidth: 42,
            display: 'flex',
            alignItems: 'center',
          },
          id: 'select',
        },
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => <NavLink to={`/companies/${row.id}`}> {row.name}</NavLink>,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.companyListJobs }),
          accessor: (row) =>
            `${row.draftJobCount || '-'} | ${row.publishedJobCount || '-'} | ${row.archievedJobCount || '-'}`,
          id: 'jobs',
        },
        {
          Header: formatMessage({ id: IntlKeys.companyListCredits }),
          id: 'credits',
          accessor: (row) =>
            `${row.reservedCreditAmount || '-'} | ${row.currentCreditAmount - row.reservedCreditAmount || '-'} | ${
              row.currentCreditAmount || '-'
            }`,
        },
        {
          Header: '',
          accessor: (row) => (
            <PopoverMenu
              company={row}
              setCurrentlyEdittingCompany={setCurrentlyEdittingCompany}
              openModal={setOpenModal}
            />
          ),
          id: 'menu',
          width: 32,
        },
      ] as InfiniteColumnsType<CompanyType>,
    [isCheckedAllTalents, formatMessage, companies, checkedRows, handleCheckCompany],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <PageContainer>
      <CompaniesFilters config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={companies}
        columns={columns}
      />
      <ModalDialog isOpen={openModal === 'edit'} handleClose={closeEditCompanyModal}>
        <CreateEditCompanyForm
          companyId={currentlyEdittingCompany}
          type="edit"
          refetch={refetch}
          closeModal={closeEditCompanyModal}
        />
      </ModalDialog>
      <ConfirmDialog
        title={'Sure you want to delete this company'}
        isOpen={openModal === 'delete'}
        onConfirm={handleCompanyDelete}
        onCancel={closeEditCompanyModal}
      >
        This action cant be undone!
      </ConfirmDialog>
    </PageContainer>
  );
};

export default Companies;

import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// components
import PrivateRoute from 'components/PrivateRoute';
import { Loader } from 'components/loader';
//
import WithSidebar from 'layout/WithSidebar';
import Login from 'pages/auth/login/LoginPage';
import Companies from 'pages/Companies';
import JobsList from 'pages/jobs';
import Talents from 'pages/Talents';
import TalentPools from 'pages/TalentPools';
import JobDetails from 'pages/JobDetails';
import CompanyDetails from 'pages/CompanyDetails';
import CreateNewJob from 'pages/createNewJob';
import Home from 'pages/home';
import Invoices from 'pages/Invoices';
import Benefits from 'pages/Benefits';
import Demo from 'pages/Demo';
import Admins from 'pages/Admins';

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<WithSidebar />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/admins">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:admins']}>
                  <Admins />
                </PrivateRoute>
              }
            ></Route>
          </Route>
          <Route path="/demo">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:demo']}>
                  <Demo />
                </PrivateRoute>
              }
            ></Route>
          </Route>
          <Route path="/invoices">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:invoices']}>
                  <Invoices />
                </PrivateRoute>
              }
            ></Route>
          </Route>
          <Route path="/companies">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:companies']}>
                  <Companies />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path=":companyId"
              element={
                <PrivateRoute allowedPermissions={['read:companies']}>
                  <CompanyDetails />
                </PrivateRoute>
              }
            ></Route>
          </Route>
          <Route path="/benefits">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:benefits']}>
                  <Benefits />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/jobs">
          <Route element={<WithSidebar />}>
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:jobs']}>
                  <JobsList />
                </PrivateRoute>
              }
            />
            <Route
              path=":jobId"
              element={
                <PrivateRoute allowedPermissions={['read:jobs']}>
                  <JobDetails />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="create"
            element={
              <PrivateRoute>
                <CreateNewJob />
              </PrivateRoute>
            }
          />
          <Route
            path="edit"
            element={
              <PrivateRoute>
                <CreateNewJob />
              </PrivateRoute>
            }
          />
        </Route>
        <Route element={<WithSidebar />}>
          <Route path="/talents">
            <Route
              path=""
              element={
                <PrivateRoute allowedPermissions={['read:talents']}>
                  <Talents />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path=":talentId"
              element={
                <PrivateRoute allowedPermissions={['read:talents']}>
                  <div>Each talent details</div>
                </PrivateRoute>
              }
            ></Route>
          </Route>
        </Route>
        <Route>
          <Route path="/talent-pools">
            <Route element={<WithSidebar />}>
              <Route
                path=""
                element={
                  <PrivateRoute allowedPermissions={['read:talents']}>
                    <TalentPools />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path=":id/"
                element={
                  <PrivateRoute allowedPermissions={['read:talents']}>
                    <div>Talent pool details</div>
                  </PrivateRoute>
                }
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

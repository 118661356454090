import { createSlice } from '@reduxjs/toolkit';
import { BenefitType } from 'types/benefit';
import { BenefitsSortConfics } from 'types/benefit/entities';
import { getBenefitDetailsAction, getBenefitsAction } from './actions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: BenefitsSortConfics.benefitNameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as BenefitType | null,
  resources: [] as BenefitType[],
};

const benefitsSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getBenefitsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getBenefitsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getBenefitsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getBenefitDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getBenefitDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getBenefitDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      });
  },
});

export const benefitsReducer = benefitsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { getApplicationDetailsAction, getApplicationsAction } from './actions';

import { JobsSortConfigs } from 'types/job/entities';
import { Application } from 'types/applications';
import { ApplicationsSortConfigs } from 'types/applications/entities';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    //
    isStale: false,
    //
  },
  filters: {} as Record<string, string>,
  sort: ApplicationsSortConfigs.nameAsc,
  pagination: {
    page: 0,
    perPage: 10,
    pageCount: 0,
    total: 0,
  },
  resource: null as Application | null,
  resources: [] as Application[],
};

const applicationsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getApplicationsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getApplicationsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;
        const { data, ...pagination } = payload;
        draft.resources = pagination.page === 1 ? data : [...draft.resources, ...data];

        draft.pagination = {
          ...draft.pagination,
          ...pagination,
        };
      })
      .addCase(getApplicationsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      })
      ////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(getApplicationDetailsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getApplicationDetailsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resource = payload;
      })
      .addCase(getApplicationDetailsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
        draft.resource = null;
      });
  },
});

export const applicationsReducer = applicationsSlice.reducer;

import { createReducer } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { merge } from 'lodash';
// types
import { TokenSetWithTime } from 'types/auth';
// utils
import { getAuthTokens } from 'helpers/localStorage';
// local
import {
  fetchCurrentUserAction,
  signInAction,
  updateUserAction,
  updateUserAvatarAction,
  updateUserPasswordAction,
  updateTokensAction,
  changeAuthLoadingStatusAction,
  signOutAction,
} from './actions';
import { AdminType } from 'types/admins';

export interface AuthStateType {
  meta: {
    isLoading: boolean;
    updateInProgress: boolean;
  };
  headers: TokenSetWithTime;
  user: AdminType | undefined;
}

const staticInitialState: AuthStateType = {
  meta: {
    isLoading: true,
    updateInProgress: false,
  },
  headers: {
    accessToken: '',
    refreshToken: '',
    //
    receivedAt: '',
  },
  user: undefined,
};

export const initialState = {
  get auth() {
    const { accessToken = '', refreshToken = '', receivedAt = '' } = getAuthTokens();

    return merge({}, staticInitialState, {
      headers: {
        accessToken: accessToken,
        refreshToken: refreshToken,
        receivedAt: receivedAt,
      },
      user: null,
    });
  },
};

const reducer = createReducer(staticInitialState, (builder) => {
  builder // signInAction
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(signInAction.fulfilled, (draft, { payload }) => {
      draft.headers = payload;
    })
    .addCase(signInAction.rejected, (draft) => {
      draft.user = staticInitialState.user;
      draft.headers = staticInitialState.headers;
    })
    // fetchCurrentUserAction
    .addCase(fetchCurrentUserAction.pending, (draft) => {
      draft.meta.isLoading = true;
    })
    .addCase(fetchCurrentUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.isLoading = false;

      draft.user = payload;
    })
    .addCase(fetchCurrentUserAction.rejected, (draft) => {
      draft.meta.isLoading = false;
      draft.user = staticInitialState.user;
      draft.headers = staticInitialState.headers;
    })
    .addCase(updateUserAction.pending, (draft) => {
      draft.meta.updateInProgress = true;
    })
    .addCase(updateUserAction.fulfilled, (draft, { payload }) => {
      draft.meta.updateInProgress = false;
      draft.user = payload;
    })
    .addCase(updateUserAction.rejected, (draft) => {
      draft.meta.updateInProgress = false;
    })
    .addCase(updateUserPasswordAction.pending, (draft) => {
      draft.meta.updateInProgress = true;
    })
    .addCase(updateUserPasswordAction.fulfilled, (draft) => {
      draft.meta.updateInProgress = false;
    })
    .addCase(updateUserPasswordAction.rejected, (draft) => {
      draft.meta.updateInProgress = false;
    })
    // updateUserAvatarAction
    .addCase(updateUserAvatarAction.fulfilled, (draft, { payload }) => {
      draft.user = merge(draft.user, { avatar: payload.avatar });
    })
    ////////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(updateTokensAction, (draft, { payload }) => {
      draft.headers = payload;
    })
    //////////////////////////////////////////////////////////////////////////////////////////////
    .addCase(changeAuthLoadingStatusAction, (draft, { payload }) => {
      draft.meta.isLoading = payload.status;
    })
    ///////////////////////////////////////////////////////////////////////
    .addCase(signOutAction.fulfilled, (draft) => {
      draft.meta.isLoading = false;
    });
});

export const authReducer: Reducer<typeof staticInitialState> = (draft = initialState.auth, action) => {
  return reducer(draft, action);
};

import React, { FC, ReactNode, memo, useRef, useEffect, useState } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

const DynamicTooltip: FC<Pick<TooltipProps, 'title'> & { children: ReactNode }> = ({ title, children }) => {
  const textEl = useRef<HTMLDivElement>(null);

  const [hoverStatus, setHover] = useState<boolean>(false);

  useEffect(() => {
    if (textEl.current) {
      setHover(textEl.current.scrollWidth > textEl.current.clientWidth);
    }
  }, []);

  return (
    <Tooltip title={title} disableHoverListener={!hoverStatus}>
      <div
        ref={textEl}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default memo(DynamicTooltip);

import { Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { JobType } from 'types/job';
import ConnectedApplications from './tabs/ConnectedApplications';
import ConnectedTalentPools from './tabs/ConnectedTalentPools';
import ConnectedTalentsList from './tabs/ConnectedTalentsList';
import JobComments from './tabs/JobComments';
import JobCustomization from './tabs/JobCustomization';

interface OwnProps {
  job?: JobType | null;
}

const JobDetailsTabs: React.FC<OwnProps> = ({ job }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = ['talents', 'applications', 'talentPools', 'customize'];
  const tabFromQuery = searchParams.get('tab') as string;
  const [selectedTab, setselectedTab] = React.useState(tabs.includes(tabFromQuery) ? tabFromQuery : 'talents');

  useEffect(() => {
    if (tabFromQuery !== selectedTab) {
      setselectedTab(tabFromQuery);
    }
    if (!tabFromQuery) {
      setSearchParams({ tab: 'talents' });
    }
  }, [selectedTab, setSearchParams, tabFromQuery]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setselectedTab(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <TabsContainer>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Job details tabs">
        <Tab value="talents" label="Talents" />
        <Tab value="applications" label="Applications" />
        <Tab value="talentPools" label="Talent Pools" />
        <Tab value="customize" label="Customize job" />
        <Tab value="comments" label={`Comments: ${job?.comments?.length}`} />
      </Tabs>
      <TabPanel value={selectedTab} index={'talents'}>
        <ConnectedTalentsList job={job!} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'applications'}>
        <ConnectedApplications job={job!} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'talentPools'}>
        <ConnectedTalentPools job={job!} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'customize'}>
        <JobCustomization job={job!} />
      </TabPanel>
      <TabPanel value={selectedTab} index={'comments'}>
        <JobComments job={job!} />
      </TabPanel>
    </TabsContainer>
  );
};

export default JobDetailsTabs;

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value == index && <>{children}</>}
    </div>
  );
};

const TabsContainer = styled.section``;

import { restClient } from "helpers/http";
import { createApiCall } from "helpers/http/utils/createApiCall";
import { CreditTransactionsList } from "types/credit-transactions";

export const fetchTransactionsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<CreditTransactionsList>({
    api: restClient.getCreditTransactions,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
}
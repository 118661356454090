import { Title3, Text, FlexBox } from 'components/base';
import { SubmitButton } from 'components/base/Button';
import TextEditor from 'components/base/TextEditor';
import VideoDropzone from 'components/Dropzone/VideoDropzone';
import VideoRecorder from 'components/Recorder/VideoRecorder';
import VideoRecording from 'components/video-recording';
import { getJobDetailsAction, updateJobDetailsAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import { uploadCustomVideo } from 'pages/JobDetails/helpers/utils';
import React, { useCallback, useEffect } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { JobType } from 'types/job';

interface OwnProps {
  job: JobType;
}

const JobCustomization: React.FC<OwnProps> = ({ job }) => {
  const defaultMessage =
    '<p>Hallo, {name}</p> <br/> <p>We found an amazing position that suits your skills and priorities</p><p>scroll down to find more details and to submit application in 2 clicks </p>';
  const form = useForm({
    defaultValues: {
      customMessage: job?.customMessage || defaultMessage,
      customVideo: job?.customVideo || null,
      customVideoObj: null as unknown as Record<string, any>,
    },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (job) {
      form.reset({ customMessage: job.customMessage || defaultMessage });
      form.reset({ customVideo: job.customVideo || null });
    }
  }, [job]);

  const customVideoObj = useWatch({ name: 'customVideoObj', control: form.control });

  const onCustomMessageSubmit = useCallback(
    async (data: FieldValues) => {
      const [customVideo] = await Promise.all([uploadCustomVideo(data.customVideoObj, data.customVideo)]);
      dispatch(
        updateJobDetailsAction({
          params: { id: job?.id || 1 },
          customMessage: data?.customMessage,
          customVideo: customVideo,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success('Successfully updated the generic custom message');
          dispatch(getJobDetailsAction({ params: { id: job?.id } }));
        })
        .catch((err) => {
          toast.error(err?.data?.response?.data?.message || 'Falied to update the custom message');
        });
    },
    [job],
  );

  const removeVideo = () => {
    console.log('calling here');
    form.setValue('customVideo', null);
    form.setValue('customVideoObj', {});
  };

  const handleCustomVideoUpload = useCallback((video: Record<string, any>) => {
    form.setValue('customVideoObj', video);
    console.log('video dropped', video);
  }, []);

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onCustomMessageSubmit)}>
          <FlexBox gap={'2rem'}>
            <div>
              <Title3>Custom Message</Title3>
              <Text>
                Custom message will appear in the job landing page as a greeting message for every applicant. This is an
                optional message and must be generic!
              </Text>
              <TextEditor name="customMessage" />
            </div>
            <div>
              <Title3>Custom Video</Title3>
              <Text>Custom video is a generic video message for the candidate to make it more personal</Text>
              <VideoDropzone
                onDrop={(acceptedFiles) =>
                  handleCustomVideoUpload({
                    fileObj: acceptedFiles[0],
                    src: URL.createObjectURL(acceptedFiles[0]),
                    name: acceptedFiles[0].name,
                  })
                }
                src={form.getValues('customVideo')?.url || customVideoObj?.src}
                onRemove={removeVideo}
                maxFiles={1}
                height={200}
                multiple={false}
                useRecord
              />
            </div>
          </FlexBox>
          <SubmitButton>Save</SubmitButton>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default JobCustomization;

const Container = styled.div`
  margin-top: 1rem;

  form h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`;

const Form = styled.form`
  button {
    margin-top: 1rem;
  }

  video.dropzone-preview {
    max-width: 500px;
    border-radius: 30px;
  }
`;

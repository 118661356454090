import React, { useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Cropper, { ReactCropperProps } from 'react-cropper';
import { MdDeleteForever } from 'react-icons/md';
import { BiLoaderAlt } from 'react-icons/bi';
import 'cropperjs/dist/cropper.css';
//
import Dropzone, { CustomDropzoneProps } from '.';
import { imageMimeTypesArray } from './entities';
import { theme } from 'theme';
import { FlexBox } from 'components/base';
import { canvasToBlob } from 'helpers/uploadFile';
import ConfirmDialog from 'components/base/ConfirmDialog';

export interface ImageCropConfig {
  height: number;
  width: number;
}
interface OwnProps extends CustomDropzoneProps {
  src?: string | null;
  onDelete?: () => void;
  onDrop: (files: File[]) => void;
  previewClassName?: string;
  onRemove?: (url?: string) => void;
  useAvatar?: boolean;
  cropConfig?: ReactCropperProps;
}

const ImageDropzone: React.FC<OwnProps> = ({
  src,
  onDelete,
  onDrop,
  previewClassName = '',
  onRemove,
  useAvatar = false,
  cropConfig,
  ...dropZoneProps
}) => {
  const [isCropperVisible, setIsCropperVisible] = useState(false);
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);

  const onImageDrop = (files: File[]) => {
    if (cropConfig) {
      setIsCropperVisible(true);
      setDroppedFile(files[0]);
    } else {
      onDrop(files);
    }
  };

  const confirmCrop = useCallback((e: React.MouseEvent<Element, MouseEvent> | undefined) => {
    e?.preventDefault();
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper as Cropper;

    setIsCropperVisible(false);
    setIsLoading(true);
    canvasToBlob(cropper.getCroppedCanvas()).then(
      (result) => {
        onDrop([result.blob as File]);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      },
    );
  }, []);

  const cancelCropping = useCallback((e: React.MouseEvent<Element, MouseEvent> | undefined) => {
    e?.preventDefault();
    setIsCropperVisible(false);
  }, []);

  if (isLoading) {
    return (
      <ImageDropzoneContainer isAvatar={useAvatar}>
        <Loader>
          <BiLoaderAlt />
        </Loader>
      </ImageDropzoneContainer>
    );
  }

  return (
    <>
      <ImageDropzoneContainer isAvatar={useAvatar}>
        {src ? (
          <PreviewContainer className={previewClassName} isAvatar={useAvatar}>
            {onRemove && (
              <RemoveContainer onClick={() => onRemove(src)} className={'remove-image'}>
                <MdDeleteForever />
              </RemoveContainer>
            )}
            <ImagePreview src={src} isAvatar={useAvatar} />
          </PreviewContainer>
        ) : (
          <Dropzone accept={imageMimeTypesArray} onDrop={onImageDrop} {...dropZoneProps} />
        )}
      </ImageDropzoneContainer>
      <ConfirmDialog
        isOpen={isCropperVisible}
        confirmBtnText="Confirm Selection"
        cancelButtonText="Cancel"
        title="Use the dragger to crop the image to get the best look!"
        onConfirm={confirmCrop}
        onCancel={cancelCropping}
      >
        <CropperContainer align="center" justify="center">
          <Cropper
            src={droppedFile ? URL.createObjectURL(droppedFile) : undefined}
            // Cropper.js options
            guides={true}
            ref={cropperRef}
            zoomTo={0.5}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            {...cropConfig}
          />
        </CropperContainer>
      </ConfirmDialog>
    </>
  );
};

export default ImageDropzone;

const ImageDropzoneContainer = styled.div`
  width: 100%;
  ${(props: { isAvatar?: boolean }) =>
    props.isAvatar &&
    css`
      width: 200px;
      height: 200px;
      border-radius: 100%;
    `}
`;

const PreviewContainer = styled.div`
  width: 100%;
  position: relative;

  ${(props: { isAvatar?: boolean }) =>
    props.isAvatar &&
    css`
      width: 200px;
      height: 200px;
      border-radius: 100%;
    `}

  .remove-image {
    display: none;
  }

  &:hover {
    .remove-image {
      display: flex;
    }
  }
`;

const ImagePreview = styled.img`
  max-width: 100%;

  ${(props: { isAvatar?: boolean }) =>
    props.isAvatar &&
    css`
      border-radius: 100%;
      overflow: hidden;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    `}
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: -1rem;
  /* padding: 1rem; */
  border-radius: 30px;
  background: ${theme.colors.maroon};
  width: 48px;
  height: 48px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.colors.white};
`;

const CropperContainer = styled(FlexBox)`
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
  max-height: 60vh;

  div {
    max-height: 100%;
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;

  > svg {
    animation: rotate 0.5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

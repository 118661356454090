import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//

import { Button } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import { AdminFiltersFormValues } from 'types/admins/entities';
import AdminForm from './AdminForm';

interface OwnProps {
  onChange: (config: AdminFiltersFormValues) => void;
  config: AdminFiltersFormValues;
  refetch?: () => void;
}

const AdminsFilters: React.FC<OwnProps> = ({ onChange, config, refetch }) => {
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;
  const [createnewModalOpen, setCreateNewModalOpen] = useState(false);

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      config.filters.search = searchValue;
      onChange(config);
    }
  }, [config, onChange, searchValue]);

  const openCreateAdminModal = useCallback(() => {
    setCreateNewModalOpen(true);
  }, []);

  const closeCreateAdminModal = useCallback(() => {
    setCreateNewModalOpen(false);
  }, []);

  return (
    <FilterContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <SearchBox>
            <FormTextField name="search" placeholder="Search for a admin" />
          </SearchBox>
        </Form>
      </FormProvider>
      <Button onClick={openCreateAdminModal}>Add admin</Button>
      <ModalDialog isOpen={createnewModalOpen} handleClose={closeCreateAdminModal}>
        <AdminForm refetch={refetch} closeModal={closeCreateAdminModal} />
      </ModalDialog>
    </FilterContainer>
  );
};

export default AdminsFilters;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

export interface ButtonProps {
  active?: boolean;
}

const Form = styled.form`
  width: 100%;
`;

// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  companyNameAsc: {
    field: 'companyName',
    order: 'ASC',
  },
  companyNameDesc: {
    field: 'companyName',
    order: 'DESC',
  },
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const CompaniesSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type CompanyFiltersFormValues = FilterFormValues<keyof typeof CompaniesSortConfigs>;

export const CompanySortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'companyNameAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'companyNameDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'createdAtDesc',
    },
  ];
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkErrorType, Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { AdminList, AdminPayload, AdminType } from 'types/admins';

export const fetchAdminsWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<AdminList>({
    api: restClient.getAdmins,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

export const getAdminsAction = createAsyncThunk<AdminList, Params | undefined>(
  'admins/getAdminAction',
  async (requestPayload, { rejectWithValue }) => {
    try {
      return await fetchAdminsWorker(requestPayload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);


export const getAdminDetailsAction = createAsyncThunk<AdminType, Params>(
  'admins/getAdminDetailsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<AdminType>({
      api: restClient.getAdmin,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createAdminAction = createAsyncThunk<AdminType, AdminPayload>(
  'admins/createAdminAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<AdminType>({
      api: restClient.createAdmin,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const updateAdminAction = createAsyncThunk<AdminType, AdminPayload | Params>(
  'admins/createAdminAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<AdminType>({
      api: restClient.updateAdmin,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err as NetworkErrorType);
    }
  },
);

export const deleteAdminAction = createAsyncThunk<void, Params>(
  'admins/deleteAdminAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.deleteAdmin,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
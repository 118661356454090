import { Checkbox } from '@mui/material';
import Button, { SubmitButton } from 'components/base/Button';
import FormTextField from 'components/hook-form/fields/FormTextField';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { Wrapper } from 'components/Layout';
import { useAppDispatch } from 'domain/store';
import { connectPoolToCandidateAndJobsAction, fetchTalentsWorker } from 'domain/talents/actions';
import { formatDate } from 'helpers/date';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { TalentFiltersFormValues, TalentSortConfigs } from 'types/talent/entities';
import { TalentType } from 'types/talent';

interface OwnProps {
  poolIds: number[];
  refetchPools: () => void;
  handleClose: () => void;
}

const AttachTalentsToPools: React.FC<OwnProps> = ({ handleClose, poolIds, refetchPools }) => {
  const [checkedRows, setCheckedRows] = useState<Array<number>>([]);
  const [isCheckedAllTalents, setIsCheckedAllTalents] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const filterConfig: TalentFiltersFormValues = {
    sort: 'nameAsc',
    filters: { search: '' },
  };

  const form = useForm({ defaultValues: { search: filterConfig.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talents,
    fetchNextPage,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'talentsList',
    //
    fetchResources: fetchTalentsWorker,
    initialSort: TalentSortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: TalentFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(TalentSortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearchChange = () => {
    filterConfig.filters.search = searchValue;

    handleFiltersChange(filterConfig);
  };

  const handleCheckTalent = useCallback(
    (value: number) => {
      if (checkedRows.includes(value)) {
        setCheckedRows(checkedRows.filter((i) => i !== value));
        setIsCheckedAllTalents(false);
      } else {
        setCheckedRows([...checkedRows, value]);
        if (checkedRows.length + 1 === talents.length) {
          setIsCheckedAllTalents(true);
        }
      }
    },
    [checkedRows, talents.length],
  );

  const confirmAttachTalentsToPools = useCallback(() => {
    const payLoad = {
      poolIds,
      candidateIds: checkedRows,
    };
    dispatch(connectPoolToCandidateAndJobsAction({ ...payLoad }))
      .unwrap()
      .then(() => {
        toast.success('Succeffully attached talents to the selected pools');
        refetchPools?.();
        handleClose();
      })
      .catch((err) => {
        toast.error(err?.data?.response?.data?.message || 'Something went wrong! Please let the engineer know');
      });
    // TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRows]);

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <Checkbox
              style={{ marginTop: '-10px' }}
              checked={isCheckedAllTalents}
              onChange={() => {
                setIsCheckedAllTalents(!isCheckedAllTalents);
                isCheckedAllTalents ? setCheckedRows([]) : setCheckedRows(talents.map((talent) => talent.id));
              }}
            />
          ),
          accessor: (row) => row.id,
          Cell: ({ value }) => (
            <Checkbox checked={checkedRows.includes(value)} onChange={() => handleCheckTalent(value)} />
          ),
          style: {
            maxWidth: 42,
            display: 'flex',
            alignItems: 'center',
          },
          id: 'select',
        },
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => row.fullName,
          id: 'name',
        },
        {
          Header: formatMessage({ id: IntlKeys.email }),
          accessor: (row) => row.email,
          id: 'email',
        },
        {
          Header: formatMessage({ id: IntlKeys.position }),
          id: 'position',
          accessor: (row) => row.position,
        },
        {
          Header: formatMessage({ id: IntlKeys.phone }),
          accessor: (row) => row.phone,
          id: 'phone',
        },
        {
          Header: formatMessage({ id: 'IntlKeys.updatedAt' }),
          accessor: (row) => formatDate(row.updatedAt),
          id: 'updatedAt',
        },
      ] as InfiniteColumnsType<TalentType>,
    [checkedRows, formatMessage, handleCheckTalent, talents, isCheckedAllTalents],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  return (
    <ListContainer>
      <FilterContainer>
        <FormProvider {...form}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <SearchBox>
              <FormTextField name="search" placeholder="Search for a talent" />
            </SearchBox>
          </Form>
        </FormProvider>
      </FilterContainer>
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={talents}
        columns={columns}
      />
      <ActionContainer>
        <SubmitButton disabled={!checkedRows.length} onClick={confirmAttachTalentsToPools}>
          Attach Selected Talents
        </SubmitButton>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
      </ActionContainer>
    </ListContainer>
  );
};

export default AttachTalentsToPools;

const ListContainer = styled(Wrapper)`
  max-width: 1000px;
  padding-bottom: 4.5rem;
`;

const ActionContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  background-color: ${theme.colors.white};
`;

const Form = styled.form`
  width: 100%;
`;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
// types
import { CreditType, PurchaseCreditPayload, ChargeCreditPayload } from 'types/credits';
//
import { Params, restClient } from 'helpers/http';
import { createApiCall } from 'helpers/http/utils/createApiCall';
import { InvoicesList } from 'types/invoices';

export const getCreditsAction = createAsyncThunk<CreditType, Params | undefined>(
  'credits/getCreditsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CreditType>({
      api: restClient.getCredits,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const chargeCreditsAction = createAsyncThunk<void, ChargeCreditPayload>(
  'company/chargeCreditsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<void>({
      api: restClient.chargeCredits,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const purchaseCreditsAction = createAsyncThunk<CreditType, PurchaseCreditPayload>(
  'company/purchaseCreditsAction',
  async (requestPayload, { rejectWithValue }) => {
    const callApi = createApiCall<CreditType>({
      api: restClient.purchaseCredits,
    });

    try {
      const { data } = await callApi(requestPayload);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchInvoicesWorker = async <T>(requestPayload: T) => {
  const callApi = createApiCall<InvoicesList>({
    api: restClient.getInvoices,
  });
  // @ts-expect-error TODO: fix this type
  const { data } = await callApi(requestPayload);

  return data;
};

import { PaginationType } from 'helpers/http';
import { JobType, QualificationAnswer } from 'types/job';
import { TalentType } from 'types/talent';

export type ApplicationStatus = 'applied' | 'accepted' | 'declined';

export enum ApplicationStatusEnum {
  Extra = 'extra',
  Applied = 'applied',
  Reviewed = 'reviewed',
  Presented = 'presented',
  Declined = 'declined',
  Accepted = 'accepted',
  Hired = 'hired',
}

export enum QualificationStatusEnum {
  Unanswered = 'unanswered',
  Pending = 'pending',
  Qualified = 'qualified',
  Disqualified = 'disqualified',
}

export interface Application {
  candidate: TalentType;
  id: number;
  remarks: string;
  resume: string;
  earliestJoining?: string;
  expectedSalary?: number;
  status: ApplicationStatusEnum;
  createdAt: string;
  updatedAt: string;
  job: JobType;
  answers?: QualificationAnswer[];
  qualificationStatus: QualificationStatusEnum;
  files?: ApplicationFile[];
}

export interface ApplicationFile {
  type: ApplicationFileTypeEnum;
  mediaType: MediaTypeEnum;
  url: string;
  thumbnail: string;
}

export enum ApplicationFileTypeEnum {
  Resume = 'resume',
  CoverLetter = 'cover_letter',
  Other = 'other',
}
export enum MediaTypeEnum {
  Document = 'document',
  Image = 'image',
  Video = 'video',
}

export interface ApplicationList extends PaginationType {
  data: Application[];
}

export interface ApplicationPayloadType {}

export type ApplicationActionType = 'accept' | 'decline';

export interface QualificationQuestionAnswerPayload {
  answer: number[] | string;
  questionId: number;
}

export interface ApplicationsStatusType {
  status: ApplicationStatusEnum;
}

export interface ApplicationRemarksPayload {
  remarks: string;
}

export type ApplicationUpdatePayload = {
  remarks?: string;
  files?: ApplicationFile[];
};

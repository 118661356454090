import { Card, FlexBox } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormCheckBox from 'components/hook-form/fields/FormCheckBox';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { IntlKeys } from 'localization';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme } from 'theme';
import { JobType } from 'types/job';
import { ImRocket } from 'react-icons/im';

interface OwnProps {
  maxCandidates: number;
  job: JobType | null;
  creditPerCandidate: {
    seniorityFactor: number;
    rareFactor: number;
    priorityFactor: number;
  };
  creditsNeeded: {
    total: number;
    toBePurchased: number;
  };
}

const CalculationSection: React.FC<OwnProps> = ({ maxCandidates }) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <EachInput label={'Maximum candidates required'}>
        <FormTextField
          name={'maxCandidates'}
          type="number"
          placeholder={'Enter max candidates required'}
          rules={{
            required: formatMessage({ id: IntlKeys.requiredItem }),
            min: 'Minimum 5 candidates must be selected',
          }}
          InputProps={{ inputProps: { min: 5, type: 'number' } }}
        />
      </EachInput>
      <CheckBoxContainer>
        <FormCheckBox
          name={'isPriority'}
          sx={{
            color: theme.colors.red,
            '&.Mui-checked': {
              color: theme.colors.red,
            },
          }}
        />
        Boost the recruiting process to deliver the candidates in half time (Normal: 30 days){' '}
        <ImRocket className="rocket" />
      </CheckBoxContainer>

      <Calculation>
        <EachRow>
          <Label>Candidate requested:</Label>
          <Value>{maxCandidates}</Value>
        </EachRow>
        <EachRow>
          <Label>Base credit per candidate:</Label>
          <Value>{1}</Value>
        </EachRow>
        <EachRow>
          <Label>Total credits needed:</Label>
          <Value className="totalValue">{maxCandidates} Credits</Value>
        </EachRow>
      </Calculation>
    </Container>
  );
};

export default CalculationSection;

const Container = styled(Card)`
  background-color: ${theme.colors.mintGreen};
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

const Calculation = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${theme.colors.gray};
`;

const EachRow = styled(FlexBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
`;

const Label = styled.span``;

const Value = styled.span`
  font-weight: 500;
  &.totalValue {
    border-top: 1px solid ${theme.colors.gray};
    width: 100px;
    display: flex;
    justify-content: end;
    padding-top: 1rem;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  svg.rocket {
    fill: ${theme.colors.red};
    margin-left: 4px;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0px 4px 0px 0px;
  }
`;

import { Avatar, Popover, Tooltip } from '@mui/material';
import ImageDropzone from 'components/Dropzone/ImageDropzone';
import VideoDropzone from 'components/Dropzone/VideoDropzone';
import { Button, Card, FlexBox, SubmitButton } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import { customJobInviteAction, getJobDetailsAction, markAsContactedAction } from 'domain/jobs/actions';
import { useAppDispatch } from 'domain/store';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { MediaFileObj } from 'pages/Companies/helpers';
import { uploadMedia } from 'pages/Talents/helpers';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { BsChevronDown, BsGlobe, BsLinkedin } from 'react-icons/bs';
import { ImXing } from 'react-icons/im';
import { MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { FileType } from 'types/common';
import { TalentStatusEnum, TalentType } from 'types/talent';
import TalentComments from './TalentComments';
import { detechTalentFromJob, updateTalentStatusAction } from 'domain/talents/actions';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';

interface OwnProps {
  data: unknown;
  additionalInfo?: {
    token: string;
    firstName: string;
    lastName: string;
    email: string;
    logo?: FileType;
    video?: FileType;
  }[];
  applyForJob?: (talent: TalentType) => void;
  openEditTalentModal?: (talentId: number) => void;
  jobId?: number;
  refetchTalents?: () => void;
}

const TalentCard: React.FC<OwnProps> = ({
  data,
  applyForJob,
  openEditTalentModal,
  jobId,
  refetchTalents,
  additionalInfo,
}) => {
  const { formatMessage } = useIntl();
  const talent = data as TalentType;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const thisInfo = additionalInfo?.find((info) => info.email === talent.email);

  const customizeForm = useForm<{ logo?: FileType; logoObj?: MediaFileObj; video?: FileType; videoObj?: MediaFileObj }>(
    { defaultValues: { logo: thisInfo?.logo, video: thisInfo?.video } },
  );
  const logoObj = useWatch({ name: 'logoObj', control: customizeForm.control });
  const uploadedLogo = useWatch({ name: 'logo', control: customizeForm.control });
  const videoObj = useWatch({ name: 'videoObj', control: customizeForm.control });
  const uploadedVideo = useWatch({ name: 'video', control: customizeForm.control });

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [, copy] = useCopyToClipboard();

  const refetchJob = useCallback(() => {
    if (jobId) dispatch(getJobDetailsAction({ params: { id: jobId } }));
  }, [dispatch, jobId]);

  const onFormSubmit = async (data: {
    logo?: FileType;
    logoObj?: MediaFileObj;
    video?: FileType;
    videoObj?: MediaFileObj;
  }) => {
    const { logoObj, videoObj } = data;
    const [logo, video] = await Promise.all([uploadMedia(logoObj, data.logo), uploadMedia(videoObj, data.video)]);
    const action = customJobInviteAction({ params: { jobId: jobId!, candidateId: talent.id }, logo, video });
    dispatch(action)
      .unwrap()
      .then(() => {
        toast.success('create custom card');
        refetchTalents?.();
        refetchJob();
      });
  };

  const handleLogoUpload = useCallback(
    (logoObj: MediaFileObj) => {
      customizeForm.setValue('logoObj', logoObj);
    },
    [customizeForm],
  );
  const removeLogo = () => {
    customizeForm.setValue('logoObj', undefined);
    customizeForm.setValue('logo', undefined);
  };
  const handleVideoUpload = useCallback(
    (videoObj: MediaFileObj) => {
      customizeForm.setValue('videoObj', videoObj);
    },
    [customizeForm],
  );
  const removeVideo = () => {
    customizeForm.setValue('videoObj', undefined);
    customizeForm.setValue('video', undefined);
  };

  const toggleCardState = () => {
    setIsCardOpen((isOpen) => !isOpen);
  };

  const copyJobLink = () => {
    const payload = {
      candidateId: talent.id,
      isContacted: true,
    };
    const action = markAsContactedAction({
      params: { id: jobId! },
      ...payload,
    });
    const params = thisInfo ? `?token=${thisInfo.token}` : `?name=${talent.firstName || ''} ${talent.lastName || ''}`;
    dispatch(action)
      .unwrap()
      .then(() => {
        const baseUrl = process.env.REACT_APP_CLIENT_PUBLIC_URL;
        const jobLink = `jobs/${jobId}/apply`;
        talent.isContacted = true;
        copy(`${baseUrl}${jobLink}${params}`);
      })
      .catch(() => {
        toast.error('failed to copy link');
      });
  };

  const statusOptions = [
    {
      label: 'Applicant',
      value: TalentStatusEnum.Applicant,
    },
    {
      label: 'Applied',
      value: TalentStatusEnum.Applied,
    },
    {
      label: 'Contacted',
      value: TalentStatusEnum.Contacted,
    },
    {
      label: 'Interested',
      value: TalentStatusEnum.Interested,
    },
    {
      label: 'Lead',
      value: TalentStatusEnum.Lead,
    },
    {
      label: 'Not interested',
      value: TalentStatusEnum.NotInterested,
    },
    {
      label: 'Hired',
      value: TalentStatusEnum.Working,
    },
  ];

  const handleOpenStatusModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setAnchorEl(e.currentTarget);
  };
  const handleCloseStatusModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e?.stopPropagation();
    e?.nativeEvent.stopImmediatePropagation();

    setAnchorEl(null);
  };
  const onStatusChange = useCallback(
    (status: TalentStatusEnum) => {
      const action = updateTalentStatusAction({ params: { id: talent.id }, status });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('status updated');
          setAnchorEl(null);
          talent.status = status;
        })
        .catch(() => toast.error('failed to update status'));
    },
    [dispatch, talent],
  );

  const detachTalent = useCallback(() => {
    if (jobId) {
      const action = detechTalentFromJob({ params: { jobId, candidateId: talent.id } });
      dispatch(action)
        .unwrap()
        .then(() => {
          refetchTalents?.();
          toast.success('talent disconnected from job');
        })
        .catch(() => {
          toast.error('failed to detach talent');
        });
    }
  }, [dispatch, jobId, refetchTalents, talent.id]);

  return (
    <CardWrapper withBorder>
      <CardHead onClick={toggleCardState}>
        <Avatar sx={{ marginRight: '1rem' }} src={talent.avatar?.url} />
        <EachDetails>
          <Value>{`${talent.firstName} ${talent.lastName}`}</Value>
          <Label>{formatMessage({ id: IntlKeys.name })}</Label>
        </EachDetails>
        <EachDetails>
          <Value>{talent.email}</Value>
          <Label>{formatMessage({ id: IntlKeys.email })}</Label>
        </EachDetails>
        <EachDetails>
          <Value>{talent.position || '-'}</Value>
          <Label>Position</Label>
        </EachDetails>
        <EachDetails>
          <Value>{talent.phone}</Value>
          <Label>{formatMessage({ id: IntlKeys.phone })}</Label>
        </EachDetails>
        <EachDetails>
          <Value>
            <Tooltip title="Click to change status" onClick={handleOpenStatusModal}>
              <Status status={talent.status}>{talent.status}</Status>
            </Tooltip>
          </Value>
          <Label>{formatMessage({ id: IntlKeys.talentStatus })}</Label>
        </EachDetails>
        <EachDetails>
          <Value>
            <Status status={talent.isContacted ? TalentStatusEnum.Contacted : TalentStatusEnum.NotInterested}>
              {talent.isContacted ? 'contacted' : 'not contacted'}
            </Status>
          </Value>
          <Tooltip title="Contacted for this job?">
            <Label>{formatMessage({ id: IntlKeys.contacted })}</Label>
          </Tooltip>
        </EachDetails>
        <EachDetails>
          <Value>
            {talent.socials
              ? Object.entries(talent.socials).map(([key, value]) =>
                  value ? (
                    <Anchor key={key} href={value} target="_blank">
                      {key === 'linkedin' ? <BsLinkedin /> : key === 'xing' ? <ImXing /> : <BsGlobe />}
                    </Anchor>
                  ) : null,
                )
              : null}
          </Value>
        </EachDetails>
        <Actions>
          <MdDelete
            onClick={(e) => {
              e.stopPropagation();
              detachTalent();
            }}
          />
          <MdEdit
            onClick={(e) => {
              e.stopPropagation();
              openEditTalentModal?.(talent.id);
            }}
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              applyForJob?.(talent);
            }}
          >
            Apply
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              copyJobLink();
            }}
          >
            Copy link
          </Button>
          <BsChevronDown className={isCardOpen ? 'open' : 'close'} />
        </Actions>
      </CardHead>
      {isCardOpen && (
        <CardBody>
          <TalentComments talent={talent} refetch={refetchTalents} />
          <FormProvider {...customizeForm}>
            <Form onSubmit={customizeForm.handleSubmit(onFormSubmit)}>
              <InputContainer>
                <EachInput label="Cover">
                  <ImageDropzone
                    onDrop={(acceptedFiles) =>
                      handleLogoUpload({
                        fileObj: acceptedFiles[0],
                        src: URL.createObjectURL(acceptedFiles[0]),
                        name: acceptedFiles[0].name,
                      })
                    }
                    maxFiles={1}
                    height={200}
                    src={uploadedLogo?.url || logoObj?.src}
                    multiple={false}
                    onRemove={removeLogo}
                    previewClassName={'job-cover-preview'}
                    cropConfig={{ aspectRatio: 1.1 }}
                  />
                </EachInput>
                <EachInput label="Video">
                  <VideoDropzone
                    onDrop={(acceptedFiles) =>
                      handleVideoUpload({
                        fileObj: acceptedFiles[0],
                        src: URL.createObjectURL(acceptedFiles[0]),
                        name: acceptedFiles[0].name,
                      })
                    }
                    maxFiles={1}
                    height={200}
                    width={360}
                    src={uploadedVideo?.url || videoObj?.src}
                    multiple={false}
                    onRemove={removeVideo}
                    previewClassName={'job-cover-preview'}
                  />
                </EachInput>
              </InputContainer>
              <ButtonContainer align="center" gap={'1rem'}>
                <SubmitButton>Save</SubmitButton>
              </ButtonContainer>
            </Form>
          </FormProvider>
        </CardBody>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseStatusModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {statusOptions.map((option) => (
          <ListItem key={option.value} onClick={() => onStatusChange(option.value)}>
            {option.label}
          </ListItem>
        ))}
      </Popover>
    </CardWrapper>
  );
};

const Actions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;

  > svg {
    transition: all linear 0.25s;
    &.open {
      transform: rotate(-90deg);
    }

    &.close {
      transform: rotate(0deg);
    }
  }
`;

const ListItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  gap 1rem;
  width: 100%;
`;

const EachInput = styled(InputWrapper)`
  font-weight: bold;
  margin: 1rem 0 2rem;

  .job-cover-preview {
    border-radius: 20px;
    height: 360px;
    max-width: 360px;
    width: auto;

    img,
    video {
      border-radius: 20px;
      max-width: 100%;
    }
  }
`;

const Anchor = styled.a`
  margin: 0.5rem;
`;

const ButtonContainer = styled(FlexBox)`
  margin: 1rem;

  button.present-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    svg {
      fill: ${theme.colors.green};
    }
  }
`;

const CardWrapper = styled(Card)`
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0;
`;

const CardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  background-color: ${theme.colors.lightGray};
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid ${theme.colors.stroke};
`;

const EachDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Value = styled.p`
  margin: 0rem 0rem 0.5rem;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`;

const Label = styled.span`
  color: ${theme.colors.gray};
`;

const Status = styled.div`
  background-color: ${(props: { status: TalentStatusEnum }) =>
    props.status === TalentStatusEnum.NotInterested ? theme.colors.red : theme.colors.primary};
  color: ${theme.colors.white};
  border: ${(props: { status: TalentStatusEnum }) =>
    props.status === TalentStatusEnum.NotInterested ? '1px solid ' + theme.colors.red : 'none'};
  border-radius: 4px;
  padding: 0.25rem 1rem;
  width: fit-content;
  font-weight: 500;
  font-size: 0.875rem;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 0.7rem;
    padding: 0.25rem;
  }
`;

export default TalentCard;

import { Checkbox } from '@mui/material';
import { PageContainer } from 'components/base';
import { SubmitButton } from 'components/base/Button';
import ConfirmDialog from 'components/base/ConfirmDialog';
import ModalDialog from 'components/base/ModalDialog';
import InfiniteTable, { InfiniteColumnsType } from 'components/InfiniteTable';
import { useAppDispatch } from 'domain/store';
import { deleteTalentPoolAction, fetchTalentPoolsWorker } from 'domain/talents/actions';
import { formatDate } from 'helpers/date';
import { useInfiniteData } from 'hooks/useInfiniteData';
import { IntlKeys } from 'localization';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { TalentPoolFiltersFormValues, TalentPoolsortConfigs } from 'types/talent/entities';
import { TalentPoolType } from 'types/talent';
import AttachJobsToPools from './components/AttachJobsToPools';
import AttachTalentsToPools from './components/AttachTalentsToPools';
import TalentPoolFilters from './components/TalentPoolFilters';

const TalentPools = () => {
  const [checkedRows, setCheckedRows] = useState<Array<number>>([]);
  const [isCheckedAllTalents, setIsCheckedAllTalents] = useState<boolean>(false);
  const [isAttachTalentModalOpen, setIsAttachTalentModalOpen] = useState(false);
  const [isAttachJobModalOpen, setIsAttachJobModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const filterConfig: TalentPoolFiltersFormValues = {
    sort: 'nameAsc',
    filters: { search: '' },
  };

  const {
    isLoading,
    isFetched,
    isFetchingNextPage,
    resources: talentPools,
    fetchNextPage,
    refetch,
    //
    pagination,
    setSort,
    setFilters,
  } = useInfiniteData({
    queryKey: 'talentPoolsList',
    //
    fetchResources: fetchTalentPoolsWorker,
    initialSort: TalentPoolsortConfigs[filterConfig.sort],
    initialFilters: filterConfig.filters,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFiltersChange = useCallback(
    debounce((config: TalentPoolFiltersFormValues) => {
      const { sort, filters } = config;
      setSort(TalentPoolsortConfigs[sort!]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
      }));
    }, 200),
    [],
  );

  const handleCheckTalent = useCallback(
    (value: number) => {
      if (checkedRows.includes(value)) {
        setCheckedRows(checkedRows.filter((i) => i !== value));
        setIsCheckedAllTalents(false);
      } else {
        setCheckedRows([...checkedRows, value]);
        if (checkedRows.length + 1 === talentPools.length) {
          setIsCheckedAllTalents(true);
        }
      }
    },
    [checkedRows, talentPools.length],
  );

  const handelAttachTalentsModelOpen = useCallback(() => {
    setIsAttachTalentModalOpen(true);
  }, []);

  const handelAttachTalentsModelClose = useCallback(() => {
    setIsAttachTalentModalOpen(false);
  }, []);

  const handelAttachJobsModelOpen = useCallback(() => {
    setIsAttachJobModalOpen(true);
  }, []);

  const handelAttachJobsModelClose = useCallback(() => {
    setIsAttachJobModalOpen(false);
  }, []);

  const handleDeleteConfirmModalOpen = useCallback(() => {
    setIsDeleteConfirmModalOpen(true);
  }, []);

  const handleDeleteConfirmModalClose = useCallback(() => {
    setIsDeleteConfirmModalOpen(false);
  }, []);

  const confirmDeleteSelectedPools = useCallback(async () => {
    setIsDeleteInProgress(true);
    await Promise.all(checkedRows.map((poolId) => dispatch(deleteTalentPoolAction({ params: { id: poolId } }))))
      .finally(() => {
        setIsDeleteInProgress(false);
      })
      .then(() => {
        setCheckedRows([]);
        handleDeleteConfirmModalClose();
        refetch?.();
        toast.success('Successfully deleted the selected pools!');
      });
  }, [checkedRows, dispatch, handleDeleteConfirmModalClose, refetch]);

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <Checkbox
              style={{ marginTop: '-10px' }}
              checked={isCheckedAllTalents}
              onChange={() => {
                setIsCheckedAllTalents(!isCheckedAllTalents);
                isCheckedAllTalents ? setCheckedRows([]) : setCheckedRows(talentPools.map((pool) => pool.id));
              }}
            />
          ),
          accessor: (row) => row.id,
          Cell: ({ value }) => (
            <Checkbox checked={checkedRows.includes(value)} onChange={() => handleCheckTalent(value)} />
          ),
          style: {
            maxWidth: 42,
            display: 'flex',
            alignItems: 'center',
          },
          id: 'select',
        },
        {
          Header: formatMessage({ id: IntlKeys.name }),
          accessor: (row) => row.name,
          id: 'name',
        },
        {
          Header: formatMessage({ id: 'IntlKeys.candidateCount' }),
          accessor: (row) => row.candidateCount,
          id: 'email',
        },
        {
          Header: formatMessage({ id: 'IntlKeys.createdAt' }),
          id: 'position',
          accessor: (row) => (row.createdAt ? formatDate(row.createdAt) : ''),
        },
        {
          Header: formatMessage({ id: 'IntlKeys.createdBy' }),
          accessor: (row) => row.author?.fullName,
          id: 'createdBy',
        },
      ] as InfiniteColumnsType<TalentPoolType>,
    [checkedRows, formatMessage, handleCheckTalent, talentPools, isCheckedAllTalents],
  ) as InfiniteColumnsType<Record<string, unknown>>;

  console.log('data', talentPools);

  return (
    <PageContainer>
      <TalentPoolFilters config={filterConfig} onChange={handleFiltersChange} refetch={refetch} />
      <InfiniteTable
        isLoading={isLoading || isFetchingNextPage}
        isLoaded={isFetched}
        page={pagination.page}
        perPage={pagination.perPage}
        pageCount={pagination.pageCount}
        itemCount={pagination.total}
        fetchNext={fetchNextPage}
        data={talentPools}
        columns={columns}
      />
      {!!checkedRows?.length && (
        <FloatingActions>
          <AttachTalentsButton onClick={handelAttachTalentsModelOpen}>Attach Talents</AttachTalentsButton>
          <AttachJobButton onClick={handelAttachJobsModelOpen}>Attach to Job</AttachJobButton>
          <DeletePoolsButton onClick={handleDeleteConfirmModalOpen}>Delete Selected</DeletePoolsButton>
        </FloatingActions>
      )}

      <ConfirmDialog
        isOpen={isDeleteConfirmModalOpen}
        onConfirm={confirmDeleteSelectedPools}
        onCancel={handleDeleteConfirmModalClose}
        isLoading={isDeleteInProgress}
        title={`Are you sure wanted to delete the selected pools?`}
        description="Deleting pools is a permanant action. This can not be undone. Deleting pools will not delete the talents and jobs associated with it."
      ></ConfirmDialog>
      <ModalDialog isOpen={isAttachTalentModalOpen} handleClose={handelAttachTalentsModelClose}>
        <AttachTalentsToPools
          poolIds={checkedRows}
          refetchPools={refetch}
          handleClose={handelAttachTalentsModelClose}
        />
      </ModalDialog>

      <ModalDialog isOpen={isAttachJobModalOpen} handleClose={handelAttachJobsModelClose}>
        <AttachJobsToPools poolIds={checkedRows} refetchPools={refetch} handleClose={handelAttachJobsModelClose} />
      </ModalDialog>
    </PageContainer>
  );
};

export default TalentPools;

const FloatingActions = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-top: 1px solid ${theme.colors.stroke};
  background: ${theme.colors.mintGreen};
  gap: 1rem;
  z-index: 2;
`;

const ListAction = styled(SubmitButton)`
  border-radius: 5px;
  transition: all linear 0.25s;

  &:hover {
    border-radius: 50px;
  }
`;

const AttachTalentsButton = styled(ListAction)``;

const DeletePoolsButton = styled(ListAction)`
  background-color: ${theme.colors.red};
  border-color: ${theme.colors.red};
`;

const AttachJobButton = styled(ListAction)`
  background-color: ${theme.colors.warning};
  border-color: ${theme.colors.warning};
`;

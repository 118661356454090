import { Card, FlexBox, SecText, Text } from 'components/base';
import { Wrapper } from 'components/Layout';
import React, { useState, useCallback } from 'react';
import { GrAdd } from 'react-icons/gr';
import styled from 'styled-components';
import { theme } from 'theme';
import { QualificationQuestion } from 'types/job';
import { questionTypes } from './Questions';

interface OwnProps {
  onAdd: (type: QualificationQuestion) => void;
}

const AddNewQuestion: React.FC<OwnProps> = ({ onAdd }) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const handleSelect = useCallback(
    (type: QualificationQuestion) => {
      onAdd(type);
      setIsSelectorOpen(false);
    },
    [onAdd],
  );

  return (
    <Container>
      <AddButton align="center" justify="center" onClick={() => setIsSelectorOpen(true)}>
        <GrAdd />
      </AddButton>
      {isSelectorOpen && (
        <>
          <Overlay onClick={() => setIsSelectorOpen(false)} />
          <SelectorContainer>
            <SelectorWrapper>
              {questionTypes.map((eachType) => (
                <EachType withBorder key={eachType.value} onClick={() => handleSelect(eachType.value)}>
                  <Header align="center" gap={'.5rem'}>
                    <Image src={eachType.icon} />
                    <Text>{eachType.label}</Text>
                  </Header>
                  <SecText>{eachType.descrition}</SecText>
                </EachType>
              ))}
            </SelectorWrapper>
          </SelectorContainer>
        </>
      )}
    </Container>
  );
};

export default AddNewQuestion;

const Container = styled.div``;

const AddButton = styled(FlexBox)`
  width: 100%;
  margin: 1rem auto;
  height: 48px;
  background-color: ${theme.colors.lightGray};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
    border-radius: 48px;

    svg {
      transform: rotate(90deg);
      transition: all linear 0.2s;
    }
  }
`;

const SelectorContainer = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 0rem;
  z-index: 4;
  background-color: ${theme.colors.white};
`;

const Overlay = styled.div`
  z-index: 3;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.15);
`;

const EachType = styled(Card)`
  cursor: pointer;
  flex: 1;
`;

const SelectorWrapper = styled(Wrapper)`
  padding: 1rem;
  max-width: 800px;
  display: flex;
  gap: 1rem;
`;

const Header = styled(FlexBox)`
  margin-bottom: 1rem;
`;

const Image = styled.img``;

import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//

import { useIntl } from 'react-intl';
import { Button } from 'components/base';
import ModalDialog from 'components/base/ModalDialog';
import { CompanyFiltersFormValues } from 'types/company/entities';
import CreateEditCompanyForm from './CreateEditCompanyForm';
import { useAppDispatch } from 'domain/store';
import { createDemoCompanyAction } from 'domain/companies/actions';
import { toast } from 'react-toastify';

interface OwnProps {
  onChange: (config: CompanyFiltersFormValues) => void;
  config: CompanyFiltersFormValues;
  isDemo?: boolean;
  refetch?: () => void;
}

const CompaniesFilters: React.FC<OwnProps> = ({ onChange, config, refetch, isDemo }) => {
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;
  const [createnewModalOpen, setCreateNewModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      config.filters.search = searchValue;
      onChange(config);
    }
  }, [config, onChange, searchValue]);

  const openCreateCompanyModal = useCallback(() => {
    setCreateNewModalOpen(true);
  }, []);

  const closeCreateCompanyModal = useCallback(() => {
    setCreateNewModalOpen(false);
  }, []);

  const createDemo = useCallback(() => {
    dispatch(createDemoCompanyAction({}))
      .unwrap()
      .then(() => {
        toast.success('Successfully created Demo');
        refetch?.();
      })
      .catch(() => {
        toast.error('Failed to create demo. Please check with the Engineering team');
      });
  }, [dispatch, refetch]);

  return (
    <FilterContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <SearchBox>
            <FormTextField name="search" placeholder="Search for a company" />
          </SearchBox>
        </Form>
      </FormProvider>
      <Button onClick={isDemo ? createDemo : openCreateCompanyModal}>{isDemo ? 'create demo' : 'Add company'}</Button>
      <ModalDialog isOpen={createnewModalOpen} handleClose={closeCreateCompanyModal}>
        <CreateEditCompanyForm refetch={refetch} closeModal={closeCreateCompanyModal} />
      </ModalDialog>
    </FilterContainer>
  );
};

export default CompaniesFilters;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

export interface ButtonProps {
  active?: boolean;
}

const Form = styled.form`
  width: 100%;
`;

import { Button, SubmitButton } from 'components/base';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormCheckBox from 'components/hook-form/fields/FormCheckBox';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import FormTextField from 'components/hook-form/fields/FormTextField';
import { Wrapper } from 'components/Layout';
import { useAppDispatch } from 'domain/store';
import { createCustomSubscriptionAction } from 'domain/subscriptions/actions';
import { IntlKeys } from 'localization';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'theme';
import { SubscriptionPayload, SubscriptionPeriod } from 'types/subscriptions';

interface OwnProps {
  closeModal: () => void;
  refetch?: () => void;
  companyId: number;
}

const CreateSubscriptionModal: React.FC<OwnProps> = ({ closeModal, refetch, companyId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const subscriptionForm = useForm<SubscriptionPayload>({ defaultValues: {} });

  const onSubscriptionCreationFormSubmit = useCallback(
    async (data: SubscriptionPayload) => {
      setIsSubmitting(true);
      const action = createCustomSubscriptionAction({ params: { companyId: companyId! }, ...data });
      dispatch(action)
        .unwrap()
        .then(() => {
          toast.success('Subscription created successfully');
          closeModal?.();
          refetch?.();
          setIsSubmitting(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsSubmitting(false);
        });
    },
    [closeModal, companyId, dispatch, refetch],
  );
  return (
    <CreateSubscriptionForm>
      <h2>Create Subscrition</h2>
      <FormProvider {...subscriptionForm}>
        <Form onSubmit={subscriptionForm.handleSubmit(onSubscriptionCreationFormSubmit)}>
          <EachInput label={formatMessage({ id: 'Name' })}>
            <FormTextField
              name={'name'}
              placeholder={formatMessage({ id: 'Enter name' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'perCreditsPrice' })}>
            <FormTextField
              name={'perCreditsPrice'}
              placeholder={formatMessage({ id: 'Enter perCreditsPrice' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Min Credits' })}>
            <FormTextField
              name={'includedCredits'}
              placeholder={formatMessage({ id: 'Enter Credits' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Max Jobs' })}>
            <FormTextField
              name={'maxJobs'}
              placeholder={formatMessage({ id: 'Enter jobs' })}
              rules={{
                required: formatMessage({ id: IntlKeys.requiredItem }),
              }}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Period' })}>
            <FormRadioSwitch
              name={'period'}
              options={[
                { label: 'monthly', value: SubscriptionPeriod.MONTH },
                { label: 'yearly', value: SubscriptionPeriod.YEAR },
              ]}
            />
          </EachInput>
          <EachInput label={formatMessage({ id: 'Services' })}>
            <CheckBoxContainer>
              <FormCheckBox name={'services[personalized]'} />
              Personalized
            </CheckBoxContainer>
            <CheckBoxContainer>
              <FormCheckBox name={'services[documents]'} />
              Documents
            </CheckBoxContainer>
            <CheckBoxContainer>
              <FormCheckBox name={'services[assesments]'} />
              Assessments
            </CheckBoxContainer>
            <CheckBoxContainer>
              <FormCheckBox name={'services[brand-check]'} />
              Brand Check
            </CheckBoxContainer>
            <CheckBoxContainer>
              <FormCheckBox name={'services[insights]'} />
              Insights
            </CheckBoxContainer>
            <CheckBoxContainer>
              <FormCheckBox name={'services[multiple-brands]'} />
              Multiple Brands
            </CheckBoxContainer>
          </EachInput>
          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              {formatMessage({ id: IntlKeys.submit })}
            </SubmitButton>
            <Button onClick={closeModal}>{formatMessage({ id: IntlKeys.cancel })}</Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </CreateSubscriptionForm>
  );
};

export default CreateSubscriptionModal;

const CreateSubscriptionForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const Form = styled.form`
  width: 100%;
`;

const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  svg.rocket {
    fill: ${theme.colors.red};
    margin-left: 4px;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0px 4px 0px 0px;
  }
`;

const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;

// types
import { IntlKeys } from 'localization';
import { useIntl } from 'react-intl';
import { SortConfigType, FilterFormValues } from 'types/common';

const uSortConfigs = {
  jobTitleAsc: {
    field: 'title',
    order: 'ASC',
  },
  jobTitleDesc: {
    field: 'title',
    order: 'DESC',
  },
  jobCandidatesAsc: {
    field: 'candidates',
    order: 'ASC',
  },
  jobCandidatesDesc: {
    field: 'candidates',
    order: 'DESC',
  },
  jobCreatedAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  jobCreatedAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const JobsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type JobFiltersFormValues = FilterFormValues<keyof typeof JobsSortConfigs>;

export const JobSortWithLabels = () => {
  const { formatMessage } = useIntl();
  return [
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobTitleAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobTitleDesc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobCandidatesAsc',
    },
    {
      label: formatMessage({ id: IntlKeys.helloWorld }),
      value: 'jobCandidatesDesc',
    },
  ];
};

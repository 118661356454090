import { FlexBox } from 'components/base';
import React, { useState, useEffect } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
//
import { theme } from 'theme';
import { JobStatus } from 'types/job';
import { JobFiltersFormValues } from 'types/job/entities';
import { useSelector } from 'react-redux';
import { companiesResourcesSelector } from 'domain/companies/selectors';
import { getCompaniesAction } from 'domain/companies/actions';
import { useAppDispatch } from 'domain/store';
import { useIntl } from 'react-intl';
import { IntlKeys } from 'localization';
import { FormProvider, useForm } from 'react-hook-form';
import FormAutoComplete, { OptionType } from 'components/hook-form/fields/FormAutoComplete';

interface OwnProps {
  onChange: (config: JobFiltersFormValues) => void;
  config: JobFiltersFormValues;
  selectedCompany?: number | null;
  withCompanySelect?: boolean;
}

type JobFilter = {
  label: string;
  value: JobStatus;
};

const JobsFilter: React.FC<OwnProps> = ({ withCompanySelect, onChange, config, selectedCompany }) => {
  const [selectedStatus, setSelectedStatus] = useState(config.filters.status || '');
  const companies = useSelector(companiesResourcesSelector);
  const [companyOptions, setCompanyOptions] = useState<OptionType[]>([]);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const form = useForm({ defaultValues: { companyId: selectedCompany } });

  useEffect(() => {
    if (withCompanySelect) {
      dispatch(getCompaniesAction());
    }
  }, [dispatch, withCompanySelect]);

  useEffect(() => {
    if (companies) {
      const options: OptionType[] = companies.map((company) => ({
        label: company.name,
        value: company.id,
      }));
      setCompanyOptions(options);
    }
  }, [companies, formatMessage, setCompanyOptions]);

  const jobFilters: JobFilter[] = [
    {
      label: formatMessage({ id: IntlKeys.activeJobs }),
      value: 'published',
    },
    {
      label: formatMessage({ id: IntlKeys.draftJobs }),
      value: 'draft',
    },
    {
      label: formatMessage({ id: IntlKeys.archivedJobs }),
      value: 'archived',
    },
    {
      label: formatMessage({ id: IntlKeys.pastJobs }),
      value: 'recruting-done',
    },
  ];

  const handleStatusChange = (status: JobStatus) => {
    const newStatus = status == selectedStatus ? '' : status;
    config.filters.status = newStatus;
    onChange(config);
    setSelectedStatus(newStatus);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCompanySelect = (event: React.SyntheticEvent, value: any, reason: string) => {
    if (reason === 'clear') {
      onChange({
        ...config,
        filters: {
          ...config.filters,
          companyId: '',
        },
      });
    } else {
      const companyId = value.value as number;
      onChange({
        ...config,
        filters: {
          ...config.filters,
          companyId,
        },
      });
    }
  };

  useEffect(() => {
    if (config.filters.status !== selectedStatus) {
      setSelectedStatus(config.filters.status);
    }
  }, [config, selectedStatus]);

  return (
    <div>
      <Container>
        <FilterContainer>
          {jobFilters.map((filter) => (
            <EachFilter
              key={filter.value}
              onClick={() => handleStatusChange(filter.value)}
              active={selectedStatus == filter.value}
            >
              {filter.label}
            </EachFilter>
          ))}
        </FilterContainer>
        <ButtonContainer>
          <Link to={`/jobs/create`}>
            <AiFillEdit /> Create
          </Link>
        </ButtonContainer>
      </Container>
      {withCompanySelect && (
        <Container>
          <FormProvider {...form}>
            <FormAutoComplete
              name="companyId"
              title={'Select company'}
              defaultOption={companyOptions.find(({ value }) => value === selectedCompany)}
              onChange={handleCompanySelect}
              options={companyOptions}
              textFieldProps={{ label: formatMessage({ id: IntlKeys.companySelect }) }}
            />
          </FormProvider>
        </Container>
      )}
    </div>
  );
};

export default JobsFilter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  gap: 4px;
`;

export interface ButtonProps {
  active?: boolean;
}

const ButtonContainer = styled(FlexBox)`
  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }

  a {
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    border: 1px solid ${theme.colors.stroke};
    text-decoration: none;
    font-weight: 400;

    &:hover {
      opacity: 0.9;
    }
  }
`;

const EachFilter = styled.button`
  background-color: ${theme.colors.white};
  border: none;
  color: ${theme.colors.dark};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;

  ${(props: ButtonProps) =>
    props.active &&
    css`
      border-radius: 20px;
      background: ${theme.colors.mintGreen};
      color: ${theme.colors};
    `}
`;

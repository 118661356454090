//
export const IntlKeys = {
  //COMMON
  add: 'common.add',
  image: 'common.image',
  video: 'common.video',
  addLinkToIntroVideo: 'common.addLinkToIntroVideo',
  amount: 'common.amount',
  avatar: 'common.avatar',
  avatarPlaceholder: 'common.avatar.placeholder',
  comment: 'common.comment',
  helloWorld: 'common.helloWorld',
  email: 'common.email',
  password: 'common.password',
  enterPassword: 'common.enterPassword',
  passwordPlaceholder: 'common.passwordPlaceholder',
  retypePassword: 'common.retypePassword',
  newPassword: 'common.newPassword',
  retypeNewPassword: 'common.retypeNewPassword',
  confirm: 'common.confirm',
  cancel: 'common.cancel',
  name: 'common.name',
  namePH: 'common.namePH',
  firstName: 'common.firstName',
  firstNamePH: 'common.firstNamePH',
  jobTitle: 'common.jobTitle',
  jobTitlePlaceholder: 'common.jobTitlePlaceholder',
  yes: 'common.yes',
  no: 'common.no',
  subscription: 'common.subscription',
  submit: 'common.submit',
  company: 'common.company',
  delete: 'common.delete',
  language: 'common.language',
  dragNDrop: 'common.drag.n.drop',
  edit: 'common.edit',
  emoji: 'common.emoji',
  emojiSelect: 'common.emojiSelect',
  linkedin: 'common.linkedin',
  linkedinPlaceholder: 'common.linkedinPlaceholder',
  xing: 'common.xing',
  xingPlaceholder: 'common.xingPlaceholder',
  phone: 'common.phone',
  position: 'common.position',
  socials: 'common.socials',
  talentStatus: 'talent.status',
  contacted: 'common.contacted',
  useAdressForBilling: 'common.useAdressForBilling',
  hideSalary: 'common.hideSalary',
  hideSalaryTrue: 'common.hideSalaryTrue',
  hideSalaryFalse: 'common.hideSalaryFalse',

  //LOGIN PAGE
  loginTitle: 'login.title',
  loginSubtitle: 'login.subtitle',
  loginNoAccount: 'login.noAccount',
  signIn: 'login.signIn',
  signInError401: 'login.signIn.error.401',

  //FORGOT PASSWORD PAGE
  forgotPassword: 'forgotPassword.common',
  forgotPasswordQuestion: 'forgotPassword.question',
  forgotPasswordEnterEmail: 'forgotPassword.enterEmail',
  forgotPasswordSendLink: 'forgotPassword.sendLink',
  forgotPasswordBackToLogin: 'forgotPassword.backToLogin',
  forgotPasswordFulfilled: 'forgotPassword.fulfilled',
  forgotPasswordError404: 'forgotPassword.error.404',

  //RESET PASSWORD PAGE
  resetPassword: 'resetPassword.common',
  resetPasswordSubtitle: 'resetPassword.subTitle',
  resetPasswordChangePassword: 'resetPassword.changePassword',
  resetPasswordHaveAccount: 'resetPassword.haveAccount',
  resetPasswordError404: 'resetPassword.fulfilled',
  resetPasswordFulfilled: 'resetPassword.error.404',
  resetPasswordError422: 'resetPassword.error.422',

  //SIGN UP PAGE
  signUp: 'signUp.common',
  signUpTitle: 'signUp.title',
  signUpSubTitle: 'signUp.subTitle',
  signUpHaveAccount: 'signUp.haveAccount',
  signUpFulfilled: 'signUp.fulfilled',
  signUpError404: 'signUp.error.404',
  signUpError422: 'signUp.error.422',
  signUpError500: 'signUp.error.500',

  // Form validations
  formFieldFequired: 'form.field.required',
  formTimeExceedsTimer: 'form.field.timeExceedsTimer',

  // Auth validations
  incorrectEmail: 'incorrect.email',
  requiredItem: 'required.item',
  eightCharacterAtLeast: 'character.count.eight',
  settingsPasswordMaxCharacter: 'setting.password.max.character',
  enterValidString: 'enter.valid.string',
  passwordsMustMatch: 'passwords.mismatch',

  // Address
  street: 'address.street',
  streetPlaceholder: 'address.streetPlaceholder',
  houseno: 'address.houseno',
  housenoPlaceholder: 'address.housenoPlaceholder',
  postcode: 'address.postcode',
  postcodePlaceholder: 'address.postcodePlaceholder',
  city: 'address.city',
  cityPlaceholder: 'address.cityPlaceholder',
  country: 'address.country',
  countryPlaceholder: 'address.countryPlaceholder',

  // jobs
  jobs: 'common.jobs',
  jobPreview: 'job.preview',
  jobProceed: 'job.proceed',
  jobDescription: 'job.description',
  jobName: 'job.title',
  enterJobTitle: 'job.enter.title',
  workLocation: 'job.workLocation',
  contractType: 'job.contractType',
  minSalary: 'job.minSalary',
  maxSalary: 'job.maxSalary',
  selectCity: 'job.selectCity',
  tasks: 'job.tasks',
  seniority: 'job.seniority',
  selectSeniority: 'job.selectSeniority',
  selectBenefits: 'job.selectBenefits',
  benefits: 'job.benefits',
  addBenefits: 'job.addBenefits',
  createBenefits: 'job.createBenefits',
  requirements: 'job.requirements',
  jobCover: 'job.cover',
  introVideo: 'job.introVideo',
  gallery: 'job.gallery',
  customColor: 'job.customColor',
  customBackgroundColor: 'job.customBackgroundColor',
  jobDetails: 'job.details',
  customization: 'job.customization',
  qualifyingQuestion: 'job.qualifyingQuestion',
  summary: 'job.summary',
  disqualifyTooltip: 'job.disqualifyTooltip',
  disqualify: 'job.disqualify',
  typeYourQuestion: 'job.typeYourQuestion',
  addNewOption: 'job.addNewOption',
  activeJobs: 'jobs.active',
  archivedJobs: 'jobs.archived',
  draftJobs: 'jobs.draft',
  pastJobs: 'jobs.past',

  // Companies
  companyAll: 'company.all',
  companySelect: 'company.select',
  companyListJobs: 'company.listJobs',
  companyListCredits: 'company.listCredits',
  companyLogo: 'company.logo',
  companyName: 'company.name',
  companyNamePH: 'company.namePH',
  companyDescription: 'company.description',
  companyDescriptionPH: 'company.descriptionPH',
  companyDescriptionLangugage: 'company.descriptionLangugage',
  companyIndustry: 'company.industry',
  companyIndustryPH: 'company.industryPH',
  companyEmployeeCount: 'company.employeeCount',
  // Applications
  userAcceptedMessage: 'user.acceptes.message',
  userDeclinedMessage: 'user.rejected.message',
  // Candidates
  candidate: 'candidate',
  // Credits
  creditsAmount: 'credit.amount',
  // Credit History
  creditHistory: 'credit.history',
  creditHistoryReason: 'credit.history.reason',
  creditHistoryReasonSubscription: 'credit.history.reason.subscription',
  creditHistoryReasonPurchaseCredits: 'credit.history.reason.purchaseCredits',
  creditHistoryReasonGiftedCredits: 'credit.history.reason.giftedCredits',
  creditHistoryReasonCanditate: 'credit.history.reason.canditate',
  creditHistoryReasonInterview: 'credit.history.reason.interview',
  creditHistoryReasonWithdrawl: 'credit.history.reason.withdrawl',
  creditHistoryReasonReferences: 'credit.history.reason.references',
  creditHistoryReasonAssessment: 'credit.history.reason.assessment',
  creditHistoryReasonAts: 'credit.history.reason.ats',
  // Invoices
  invoices: 'invoices',
  invoicesNotAvailable: 'invoices.notAvailable',
  invoicesDate: 'invoices.date',
  invoicesItem: 'invoices.item',
  invoicesTotal: 'invoices.total',
  invoicesStatus: 'invoices.status',
  invoicePaid: 'invoice.paid',
  invoiceCanceled: 'invoice.canceled',
  invoiceCancel: 'invoice.cancel',
  invoiceUnpaid: 'invoice.unpaid',
  invoiceMarkAsPaid: 'invoice.markAsPaid',
  invoiceMarkAsUnpaid: 'invoice.markAsUnpaid',
  invoiceMarkAsStorno: 'invoice.markasstorno',
  invoiceMarkedAsPaidSuccessfully: 'invoice.makedAsPaidSuccessfully',
  invoiceReminderSend: 'invoice.reminderSend',
  // Users
  users: 'users.users',
  usersCreate: 'users.create',
  usersCreateSuccessfully: 'users.create.success',
  usersCreateFailure: 'users.create.failure',
  usersSearch: 'users.search',
  usersRole: 'users.role',
  usersRolePlaceholder: 'users.role.placeholder',
  usersRoleAdmin: 'users.role.admin',
  usersRoleUser: 'users.role.user',

  // Messages
  messagesSendAtAsc: 'messages.sendAt.asc',
  messagesSendAtDesc: 'messages.sendAt.desc',
  messagesNameAsc: 'messages.name.asc',
  messagesNameDesc: 'messages.name.desc',
  messagesEmailAsc: 'messages.email.asc',
  messagesEmailDesc: 'messages.email.desc',
  messagesStatusAsc: 'messages.status.asc',
  messagesStatusDesc: 'messages.status.desc',
  messagesReviewedAtAsc: 'messages.reviewedAt.asc',
  messagesReviewedAtDesc: 'messages.reviewedAt.desc',
  messagesStatusNew: 'messages.status.new',
  messagesStatusRead: 'messages.status.read',
  messagesStatusArchived: 'messages.status.archived',
  messagesMarkReviewed: 'messages.mark.reviewed',
  messagesUnmarkReviewed: 'messages.unmark.reviewed',
};

export const extractIntlKey = (id: string) => {
  const isPropertyExist = IntlKeys.hasOwnProperty(id);

  return isPropertyExist ? IntlKeys[id as unknown as keyof typeof IntlKeys] : id;
};

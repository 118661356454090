import { createSlice } from '@reduxjs/toolkit';
import { getSubscriptionsAction } from 'domain/subscriptions/actions';
import { SubscriptionType } from 'types/subscriptions';

const initialState = {
  meta: {
    isLoading: false,
    isLoaded: false,
    isStale: false,
  },
  resource: null as SubscriptionType | null,
  resources: [] as SubscriptionType[],
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionsAction.pending, (draft) => {
        draft.meta.isLoading = true;
        draft.meta.isLoaded = false;
      })
      .addCase(getSubscriptionsAction.fulfilled, (draft, { payload }) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = true;

        draft.resources = payload;
      })
      .addCase(getSubscriptionsAction.rejected, (draft) => {
        draft.meta.isLoading = false;
        draft.meta.isLoaded = false;
      });
  },
});

export const subscriptionsReducer = subscriptionsSlice.reducer;

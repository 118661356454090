import { IntlKeys } from 'localization/keys';

const translations = {
  //COMMON
  [IntlKeys.add]: 'Add',
  [IntlKeys.image]: 'Image',
  [IntlKeys.video]: 'Video',
  [IntlKeys.addLinkToIntroVideo]: 'Add link to Video',
  [IntlKeys.amount]: 'Amount',
  [IntlKeys.avatar]: 'Avatar',
  [IntlKeys.avatarPlaceholder]: 'choose an avatar',
  [IntlKeys.comment]: 'Comment',
  [IntlKeys.helloWorld]: 'Hallo World',
  [IntlKeys.email]: 'Email',
  [IntlKeys.password]: 'Password',
  [IntlKeys.enterPassword]: 'Enter password',
  [IntlKeys.passwordPlaceholder]: 'Type your password',
  [IntlKeys.retypePassword]: 'Re-type password',
  [IntlKeys.newPassword]: 'New password',
  [IntlKeys.retypeNewPassword]: 'Re-type new password',
  [IntlKeys.confirm]: 'Confirm',
  [IntlKeys.cancel]: 'Cancel',
  [IntlKeys.name]: 'Name',
  [IntlKeys.name]: 'Name',
  [IntlKeys.namePH]: 'Enter your name',
  [IntlKeys.firstName]: 'Surname',
  [IntlKeys.firstNamePH]: 'Enter your firstName',
  [IntlKeys.jobTitle]: 'Job title',
  [IntlKeys.jobTitlePlaceholder]: 'Enter your job title',
  [IntlKeys.yes]: 'Yes',
  [IntlKeys.no]: 'No',
  [IntlKeys.subscription]: 'Subscription',
  [IntlKeys.submit]: 'Submit',
  [IntlKeys.company]: 'Company',
  [IntlKeys.delete]: 'Delete',
  [IntlKeys.language]: 'Language',
  [IntlKeys.dragNDrop]: 'Drag and drop or click to upload from computer',
  [IntlKeys.edit]: 'Edit',
  [IntlKeys.emoji]: 'Emoji',
  [IntlKeys.emojiSelect]: 'Select Emoji',
  [IntlKeys.linkedin]: 'LinkedIn',
  [IntlKeys.linkedinPlaceholder]: 'Enter LinkedIn',
  [IntlKeys.xing]: 'Xing',
  [IntlKeys.xingPlaceholder]: 'Enter Xing',
  [IntlKeys.phone]: 'Phone number',
  [IntlKeys.position]: 'Position',
  [IntlKeys.socials]: 'Sozial Networks',
  [IntlKeys.talentStatus]: 'Talent state',
  [IntlKeys.contacted]: 'Contacted',
  [IntlKeys.useAdressForBilling]: 'use address',
  [IntlKeys.hideSalary]: 'show salary to talent',
  [IntlKeys.hideSalaryTrue]: 'do not show',
  [IntlKeys.hideSalaryFalse]: 'do show',

  //LOGIN PAGE
  [IntlKeys.loginTitle]: 'Welcome back to Talents&Friends!',
  [IntlKeys.loginSubtitle]: 'Please login to your account to continue',
  [IntlKeys.signIn]: 'Login',
  [IntlKeys.loginNoAccount]: 'Don’t have an account?',
  [IntlKeys.signInError401]: 'Wrong email or password',

  //FORGOT PASSWORD PAGE
  [IntlKeys.forgotPassword]: 'Forgot password',
  [IntlKeys.forgotPasswordQuestion]: 'Forgot password?',
  [IntlKeys.forgotPasswordEnterEmail]: 'Enter the email address associated with your account',
  [IntlKeys.forgotPasswordSendLink]: 'Send reset link',
  [IntlKeys.forgotPasswordBackToLogin]: 'Back to Login',
  [IntlKeys.forgotPasswordFulfilled]: 'Link sent to your email',
  [IntlKeys.forgotPasswordError404]: "This email address don't exist",

  //RESET PASSWORD PAGE
  [IntlKeys.resetPassword]: 'Reset password',
  [IntlKeys.resetPasswordSubtitle]: 'Please enter your new password',
  [IntlKeys.resetPasswordChangePassword]: 'Change password',
  [IntlKeys.resetPasswordHaveAccount]: 'Already have an account?',
  [IntlKeys.resetPasswordFulfilled]: 'Password successfully changed',
  [IntlKeys.resetPasswordError404]: 'This link is invalid',
  [IntlKeys.resetPasswordError422]: 'This link is invalid',

  //SIGNUP PASSWORD PAGE
  [IntlKeys.signUp]: 'Sign up',
  [IntlKeys.signUpTitle]: 'Join Talents&Friends',
  [IntlKeys.signUpSubTitle]: 'Create an account and start your training!',
  [IntlKeys.signUpHaveAccount]: 'Have an account?',
  [IntlKeys.signUpFulfilled]: 'User successfully created',
  [IntlKeys.signUpError404]: 'This link is invalid',
  [IntlKeys.signUpError422]: 'This link is invalid',
  [IntlKeys.signUpError500]: 'This email already exists',

  // Form Validation
  [IntlKeys.formFieldFequired]: 'This field is required',
  [IntlKeys.formTimeExceedsTimer]: 'Summary tasks time exceeds total timer',

  // Auth validations
  [IntlKeys.passwordsMustMatch]: 'Passwords must match',
  [IntlKeys.eightCharacterAtLeast]: 'Must be 8 characters at least',
  [IntlKeys.incorrectEmail]: 'Incorrect email',
  [IntlKeys.requiredItem]: 'This is required',
  [IntlKeys.settingsPasswordMaxCharacter]: 'Password must be no longer than 20 characters',
  [IntlKeys.enterValidString]: 'Please enter valid string',

  // Address
  [IntlKeys.street]: 'Street',
  [IntlKeys.streetPlaceholder]: 'Enter street',
  [IntlKeys.houseno]: 'Housenumber',
  [IntlKeys.housenoPlaceholder]: 'Enter Housenumber',
  [IntlKeys.postcode]: 'Postcode',
  [IntlKeys.postcodePlaceholder]: 'Enter Postcode',
  [IntlKeys.city]: 'City',
  [IntlKeys.cityPlaceholder]: 'Enter City',
  [IntlKeys.country]: 'Country',
  [IntlKeys.countryPlaceholder]: 'Enter Country',

  // Jobs
  [IntlKeys.jobs]: 'Jobs',
  [IntlKeys.jobPreview]: 'Preview',
  [IntlKeys.jobProceed]: 'Save & Next',
  [IntlKeys.jobDescription]:
    'A <b>{contractType, select, fullTime {full time} partTime {part time} other {other}}</b> position with a salary of <b>{minSalary}</b> to <b>{maxSalary}</b> per year is available in <b>{location}</b>. There are <b>{maxCandidates}</b> talents wanted for this position by <b>{endDate}</b>',
  [IntlKeys.jobName]: 'Job Title',
  [IntlKeys.enterJobTitle]: 'Enter Job Title',
  [IntlKeys.workLocation]: 'Work Location',
  [IntlKeys.contractType]: 'Contract Type',
  [IntlKeys.minSalary]: 'Minimum annual salary',
  [IntlKeys.maxSalary]: 'Maximum annual salary',
  [IntlKeys.selectCity]: 'City',
  [IntlKeys.tasks]: 'Tasks',
  [IntlKeys.seniority]: 'Seniority',
  [IntlKeys.selectSeniority]: 'Select Seniority',
  [IntlKeys.selectBenefits]: 'Select Benefits',
  [IntlKeys.benefits]: 'Benefits',
  [IntlKeys.addBenefits]: 'Add Benefits',
  [IntlKeys.createBenefits]: 'Create Benefit',
  [IntlKeys.requirements]: 'Requirements',
  [IntlKeys.jobCover]: 'Cover image',
  [IntlKeys.introVideo]: 'Introduction video',
  [IntlKeys.gallery]: 'Image Gallery',
  [IntlKeys.customColor]: 'Custom font color',
  [IntlKeys.customBackgroundColor]: 'Custom background color',
  [IntlKeys.jobDetails]: 'Details',
  [IntlKeys.customization]: 'Customization',
  [IntlKeys.qualifyingQuestion]: 'Qualification Questions',
  [IntlKeys.summary]: 'Summary',
  [IntlKeys.disqualifyTooltip]:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
  [IntlKeys.disqualify]: 'Disqualify',
  [IntlKeys.typeYourQuestion]: 'Type your question',
  [IntlKeys.addNewOption]: 'Add new option',
  [IntlKeys.activeJobs]: 'Active Jobs',
  [IntlKeys.archivedJobs]: 'Archived Jobs',
  [IntlKeys.draftJobs]: 'Drafts',
  [IntlKeys.pastJobs]: 'Past Jobs',

  // Companies
  [IntlKeys.companyAll]: 'all companies',
  [IntlKeys.companySelect]: 'Select company',
  [IntlKeys.companyListJobs]: 'Jobs (Draft | Public | Done)',
  [IntlKeys.companyListCredits]: 'Credits ( Reserved | Available | Total)',
  [IntlKeys.companyLogo]: 'Logo',
  [IntlKeys.companyName]: 'Company name',
  [IntlKeys.companyNamePH]: 'Enter name',
  [IntlKeys.companyDescription]: 'About us',
  [IntlKeys.companyDescriptionPH]: 'Enter a description of your company',
  [IntlKeys.companyDescriptionLangugage]: 'Language of description',
  [IntlKeys.companyIndustry]: 'Industry',
  [IntlKeys.companyIndustryPH]: 'Select Industry',
  [IntlKeys.companyEmployeeCount]: 'Employee count',

  // Applications
  [IntlKeys.userAcceptedMessage]: '<b>{name}</b> is a match for the <b>{job}</b> position.',
  [IntlKeys.userDeclinedMessage]: '<b>{name}</b> is not a match for the <b>{job}</b> position.',
  // Candidates
  [IntlKeys.candidate]: 'Candidate',
  // Credits
  [IntlKeys.creditsAmount]: '{count, plural, =0 {keine Credits} one {# Credit} other {# Credits}}',
  // Credit History
  [IntlKeys.creditHistory]: 'Credit History',
  [IntlKeys.creditHistoryReason]: 'spend for',
  [IntlKeys.creditHistoryReasonSubscription]: 'Subscription Plan',
  [IntlKeys.creditHistoryReasonPurchaseCredits]: 'Credit Purchase',
  [IntlKeys.creditHistoryReasonGiftedCredits]: 'Credit Gifted',
  [IntlKeys.creditHistoryReasonCanditate]: 'Candidate received',
  [IntlKeys.creditHistoryReasonInterview]: 'Interview-Management',
  [IntlKeys.creditHistoryReasonWithdrawl]: 'Withdrawl-Management',
  [IntlKeys.creditHistoryReasonReferences]: 'References-Check',
  [IntlKeys.creditHistoryReasonAssessment]: 'Assessment-Center',
  [IntlKeys.creditHistoryReasonAts]: 'ATS-Management',
  // Invoices
  [IntlKeys.invoices]: 'Invoices',
  [IntlKeys.invoicesNotAvailable]: 'no invoices available',
  [IntlKeys.invoicesDate]: 'Issue date',
  [IntlKeys.invoicesItem]: 'Item',
  [IntlKeys.invoicesTotal]: 'Total',
  [IntlKeys.invoicesStatus]: 'Status',
  [IntlKeys.invoicePaid]: 'Paid',
  [IntlKeys.invoiceUnpaid]: 'not paid',
  [IntlKeys.invoiceCanceled]: 'Canceled',
  [IntlKeys.invoiceCancel]: 'Cancel Invoice',
  [IntlKeys.invoiceMarkAsPaid]: 'Mark as paid',
  [IntlKeys.invoiceMarkAsUnpaid]: 'Mark as unpaid',
  [IntlKeys.invoiceMarkAsStorno]: 'cancel invoice',
  [IntlKeys.invoiceMarkedAsPaidSuccessfully]: 'successfully marked as paid',
  [IntlKeys.invoiceReminderSend]: 'invoice reminder send',
  // Users
  [IntlKeys.users]: 'Users',
  [IntlKeys.usersCreate]: 'Create User',
  [IntlKeys.usersCreateSuccessfully]: 'User created',
  [IntlKeys.usersCreateFailure]: 'Failed to create user',
  [IntlKeys.usersSearch]: 'Search user',
  [IntlKeys.usersRole]: 'User Role',
  [IntlKeys.usersRolePlaceholder]: 'Choose Role',
  [IntlKeys.usersRoleAdmin]: 'Admin',
  [IntlKeys.usersRoleUser]: 'User',
  // Messages
  [IntlKeys.messagesSendAtAsc]: 'Send at ⬆️',
  [IntlKeys.messagesSendAtDesc]: 'Send at ⬇️',
  [IntlKeys.messagesNameAsc]: 'Name ⬆️',
  [IntlKeys.messagesNameDesc]: 'Name ⬇️',
  [IntlKeys.messagesEmailAsc]: 'Email ⬆️',
  [IntlKeys.messagesEmailDesc]: 'Email ⬇️',
  [IntlKeys.messagesStatusAsc]: 'Status ⬆️',
  [IntlKeys.messagesStatusDesc]: 'Status ⬇️',
  [IntlKeys.messagesReviewedAtAsc]: 'Reviewed at ⬆️',
  [IntlKeys.messagesReviewedAtDesc]: 'Reviewed at ⬇️',
  [IntlKeys.messagesStatusNew]: 'New messages',
  [IntlKeys.messagesStatusRead]: 'Read messages',
  [IntlKeys.messagesStatusArchived]: 'Archived massages',
  [IntlKeys.messagesMarkReviewed]: 'mark as read',
  [IntlKeys.messagesUnmarkReviewed]: 'mark as unread',
};
export default translations;

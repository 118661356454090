import React, { useEffect } from 'react';
// context
import useVideoRecording, { VideoRecordingProvider } from 'components/video-recording/useVideoRecording';
//
import styled from 'styled-components';
import VideoRecorder from 'components/Recorder/VideoRecorder';

interface OwnProps {
  onRecordSubmit: (url: Blob) => void;
  handleClose: () => void;
  isOpen: boolean;
}

const RecordVideoModal = ({ onRecordSubmit, handleClose, isOpen }: OwnProps) => {
  const videoRecordingContext = useVideoRecording({});

  useEffect(() => {
    if (videoRecordingContext.isRecording && !isOpen) {
      videoRecordingContext.handleStopRecording();
    }
  }, [isOpen, videoRecordingContext]);

  return (
    <VideoRecordingProvider {...videoRecordingContext}>
      <Container>
        <VideoRecorder onRecordSubmit={onRecordSubmit} handleClose={handleClose} />
      </Container>
    </VideoRecordingProvider>
  );
};

const Container = styled.div``;

export default RecordVideoModal;

import { FilterFormValues, SortConfigType } from 'types/common';

const uSortConfigs = {
  createdAtAsc: {
    field: 'createdAt',
    order: 'ASC',
  },
  createdAtDesc: {
    field: 'createdAt',
    order: 'DESC',
  },
};

export const CreditTransactionsSortConfigs = uSortConfigs as Record<keyof typeof uSortConfigs, SortConfigType>;
export type CreditTransactionsFiltersFormValues = FilterFormValues<keyof typeof uSortConfigs>;

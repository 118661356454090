import { uploadFile } from 'helpers/uploadFile';
import { FileType } from 'types/common';

export interface MediaUploadedFileObj {
  fileObj: File;
  src: string;
  name: string;
}

export const uploadFiles = async (files: MediaUploadedFileObj[]) => {
  //
  return await Promise.all(
    (files || []).map(async ({ fileObj }) => {
      console.log(`Uploading File: ${fileObj.name}`);
      return fileObj
        ? new Promise<FileType>(async (resolve) => {
            const formData = new FormData();
            formData.append('file', fileObj, fileObj.name);
            const response = (await uploadFile(formData)) as FileType;

            resolve(response);
          })
        : undefined;
    }),
  );
};

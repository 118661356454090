import { JobType, JobWorkflow } from 'types/job';

export const convertJobBasedOnStatus = (jobs: JobType[]) => {
  if (!jobs.length) {
    console.log('coming here', jobs);

    return {
      todo: [] as JobType[],
      inprogress: [] as JobType[],
      done: [] as JobType[],
      blocked: [] as JobType[],
      cancelled: [] as JobType[],
    };
  }

  return jobs.reduce(
    (acc, current) => {
      const { status } = current.workflow || {};
      acc[status || 'todo']?.push(current);
      return acc;
    },
    {
      todo: [] as JobType[],
      inprogress: [] as JobType[],
      done: [] as JobType[],
      blocked: [] as JobType[],
      cancelled: [] as JobType[],
    },
  );
};

export interface DraggableCardInfo {
  id: string | number;
  status: JobWorkflow['status'];
}

import { Wrapper } from 'components/Layout';
import InputWrapper from 'components/hook-form/components/InputWrapper';
import FormRadioSwitch from 'components/hook-form/fields/FormRadioSwitch';
import Picker from '@emoji-mart/react';
import data, { Skin } from '@emoji-mart/data';
import { createBenefitsAction } from 'domain/benefits/actions';
import { useAppDispatch } from 'domain/store';
import { IntlKeys } from 'localization';
import React, { useCallback, useState, MouseEvent } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BenefitPayload, BenefitsPayload } from 'types/benefit';
import { Chip, Popover, TextField } from '@mui/material';
import { GrDown } from 'react-icons/gr';
import { Button, SubmitButton } from 'components/base';
import { MdAdd } from 'react-icons/md';

interface OwnProps {
  refetch?: () => void;
  closeModal: () => void;
}

const CreateBenefitForm: React.FC<OwnProps> = ({ refetch, closeModal }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [currentIcon, setCurrentIcon] = useState<string>();
  const [currentBenefit, setCurrentBenefit] = useState<string>();
  const [benefitJson, setBenefitJson] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLOrSVGElement | null>(null);

  const benefitsForm = useForm<BenefitsPayload>({ defaultValues: { language: 'de-DE', benefits: [] } });
  const benefits = useWatch({ name: 'benefits', control: benefitsForm.control });

  const languagesOptions = [
    {
      value: 'de-DE',
      label: '🇩🇪 Deutsch',
    },
    {
      value: 'en-US',
      label: '🇬🇧 English',
    },
  ];

  const addBenefit = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      benefits.push({ icon: currentIcon || '', name: currentBenefit || '' });
      benefitsForm.setValue('benefits', benefits);
      setCurrentBenefit('');
      setCurrentIcon('');
    },
    [benefits, benefitsForm, currentBenefit, currentIcon],
  );

  const handleBenefitDelete = useCallback(
    (name: string) => {
      benefitsForm.setValue(
        'benefits',
        benefits.filter((bene) => bene.name !== name),
      );
    },
    [benefits, benefitsForm],
  );

  const addBenefitFromJSON = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (benefitJson) {
        console.log(benefitJson);
        const benefitArray = JSON.parse(benefitJson) as BenefitPayload[];
        console.log(benefitArray);
        benefitArray.map(({ name, icon }) => {
          console.log(`New Benefit: {name: ${name}, icon: ${icon}}`);
          benefits.push({ name, icon });
        });
        benefitsForm.setValue('benefits', benefits);
      }
      setBenefitJson('');
    },
    [benefitJson, benefits, benefitsForm],
  );

  const handleEmojiClick = useCallback(
    (thisEmoji: Skin) => {
      setCurrentIcon(currentIcon ? currentIcon + thisEmoji.native : thisEmoji.native);
      setAnchorEl(null);
    },
    [currentIcon],
  );

  const openPopover = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => setAnchorEl(e.currentTarget),
    [],
  );
  const closePopover = useCallback(() => setAnchorEl(null), []);

  const onBenefitFormSubmit = useCallback(
    async (data: BenefitsPayload) => {
      setIsSubmitting(true);
      const action = createBenefitsAction(data);
      dispatch(action)
        .unwrap()
        .then(() => {
          setIsSubmitting(false);
          toast.success('Benefits created successfully');
          closeModal?.();
          refetch?.();
        })
        .catch((err) => {
          setIsSubmitting(false);
          toast.error(err?.data?.response?.data?.message || 'Failed to create benefit! please try again later');
        });
    },
    [closeModal, dispatch, refetch],
  );

  return (
    <BenefitForm>
      <FormProvider {...benefitsForm}>
        <Form onSubmit={benefitsForm.handleSubmit(onBenefitFormSubmit)}>
          <EachInput label={formatMessage({ id: IntlKeys.language })}>
            <FormRadioSwitch noBullet name="language" options={languagesOptions} />
          </EachInput>
          <NewBenefit>
            <BenefitField
              focused={!!anchorEl}
              value={currentIcon || ''}
              placeholder={formatMessage({ id: IntlKeys.emojiSelect })}
              onChange={(e) => setCurrentIcon(e.target.value)}
              InputProps={{
                endAdornment: <GrDown onClick={openPopover} />,
              }}
            />
            <TextField
              placeholder={formatMessage({ id: IntlKeys.benefits })}
              value={currentBenefit || ''}
              onChange={(e) => setCurrentBenefit(e.target.value)}
            />
            <Button variant="outline" onClick={addBenefit}>
              <MdAdd />
            </Button>

            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl as HTMLElement}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={closePopover}
            >
              <Picker data={data} onEmojiSelect={handleEmojiClick} />
            </Popover>
          </NewBenefit>
          <JSONInput>
            <MultilineTextField
              placeholder={
                'Add Benefits in JSON style:\n[\n  {"name": "BENEFIT_NAME", "icon": "BENEFIT_ICON"},\n  {"name": "BENEFIT_NAME", "icon": "BENEFIT_ICON"}\n]'
              }
              value={benefitJson || ''}
              multiline
              rows={6}
              onChange={(e) => setBenefitJson(e.target.value)}
            />
            <Button variant="outline" onClick={addBenefitFromJSON}>
              <MdAdd /> {formatMessage({ id: IntlKeys.addBenefits })}
            </Button>
          </JSONInput>
          <EachInput>
            <BenefitRow>
              {benefits.map((newBenefit) => (
                <Chip
                  label={`${newBenefit.icon} ${newBenefit.name}`}
                  key={`newBenefit_${newBenefit.name}`}
                  onDelete={() => handleBenefitDelete(newBenefit.name)}
                />
              ))}
            </BenefitRow>
          </EachInput>
          <FormFooter>
            <SubmitButton isLoading={isSubmitting} disabled={isSubmitting}>
              {formatMessage({ id: IntlKeys.submit })}
            </SubmitButton>
            <Button variant="outline" onClick={closeModal}>
              Cancel
            </Button>
          </FormFooter>
        </Form>
      </FormProvider>
    </BenefitForm>
  );
};

const BenefitForm = styled(Wrapper)`
  max-width: 800px;
  margin-top: 2rem;
  padding-bottom: 4.5rem;
`;

const BenefitRow = styled.div`
  display: flex-box;
  width: 100%;
  margin: 1rem 0 2rem;
`;

const Form = styled.form`
  width: 100%;
`;

const NewBenefit = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const JSONInput = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const BenefitField = styled(TextField)`
  min-width: 100px;
`;
const EachInput = styled(InputWrapper)`
  margin: 1rem 0 2rem;
`;
const MultilineTextField = styled(TextField)`
  width: 100%;
`;
const FormFooter = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 1rem 0rem;
  margin-top: 2rem;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.5);

  button {
    min-width: 300px;
  }
`;

export default CreateBenefitForm;

import FormTextField from 'components/hook-form/fields/FormTextField';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
//
import { TalentPoolFiltersFormValues } from 'types/talent/entities';
import { Button } from 'components/base';

interface OwnProps {
  onChange: (config: TalentPoolFiltersFormValues) => void;
  config: TalentPoolFiltersFormValues;
  refetch?: () => void;
  openAttachTalentsModal: () => void;
  openCreateTalentsModal: () => void;
}

const TalentsFilters: React.FC<OwnProps> = ({ onChange, config, openAttachTalentsModal, openCreateTalentsModal }) => {
  const form = useForm({ defaultValues: { search: config.filters.search } });
  const searchValue = useWatch({ name: 'search', control: form.control }) as string;

  useEffect(() => {
    if (searchValue?.length >= 3 || searchValue?.length == 0) {
      handleSearchChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSearchChange = () => {
    config.filters.search = searchValue;

    onChange(config);
  };

  return (
    <FilterContainer>
      <FormProvider {...form}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <SearchBox>
            <FormTextField name="search" placeholder="Search for a talent" />
          </SearchBox>
        </Form>
      </FormProvider>
      <Button variant="outline" onClick={openAttachTalentsModal}>
        Attach Talents
      </Button>
      <Button onClick={openCreateTalentsModal}>Create Talent</Button>
    </FilterContainer>
  );
};

export default TalentsFilters;

const FilterContainer = styled.div`
  padding: 40px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  button {
    min-width: 170px;
    padding: 0.5rem 1rem;
  }
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 400px;

  input {
    height: 42px;
    box-sizing: border-box;
  }
`;

export interface ButtonProps {
  active?: boolean;
}

const Form = styled.form`
  width: 100%;
`;
